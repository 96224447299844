import {Dispatch} from "../../constants/globalTypes";
import {History} from "history";
import {authFetch} from "../../helpers/createAuthProvider";


export const ADD_SUPPLIER = "add_supplier";
export const ADD_SUPPLIER_SUCCESS = "add_supplier_success";
export const ADD_SUPPLIER_FAIL = "add_supplier_fail";

const addSupplier =
    ({
         values,
         dispatch,
         history
     }: {
        values: any;
        dispatch: Dispatch;
        history: History;
    }, id: string) => {
        dispatch({
            type: ADD_SUPPLIER,
            payload: {}
        });
        let responseStats: number;
        authFetch(`/costs/${id}/suppliers`, {
            method: "POST",
            body: JSON.stringify(values),
        }).then((response) => {
            responseStats = response.status
            return response.json()
        }).then((data) => {
            if (responseStats == 200) {
                dispatch({
                    type: ADD_SUPPLIER_SUCCESS,
                    payload: data,
                });
                getSupplier({dispatch}, id);
                history.goBack();
            } else {
                dispatch({
                    type: ADD_SUPPLIER_FAIL,
                    payload: data.message,
                });
            }
        }).catch((error) => {
            dispatch({
                type: ADD_SUPPLIER_FAIL,
                payload: error,
            });
        });
    }

export const EXPENSES_TYPE = "expenses_type";
export const EXPENSES_TYPE_SUCCESS = "expenses_type_success";
export const EXPENSES_TYPE_FAIL = "expenses_type_fail";

const getExpenseTypes = ({dispatch,}: { dispatch: Dispatch; }) => {
    dispatch({
        type: EXPENSES_TYPE,
        payload: {}
    });
    authFetch(`/costs//expenseTypes`, {
        method: "GET",
    }).then((response) => response.json()).then((data) => {
        dispatch({
            type: EXPENSES_TYPE_SUCCESS,
            payload: data,
        });
    }).catch((error) => {
        dispatch({
            type: EXPENSES_TYPE_FAIL,
            payload: error,
        });
    });
}

export const GET_SUPPLIERS = "get_suppliers";
export const GET_SUPPLIERS_SUCCESS = "get_suppliers_success";
export const GET_SUPPLIERS_FAIL = "get_suppliers_fail";

const getSupplier = ({dispatch,}: { dispatch: Dispatch; }, id: string) => {
    dispatch({
        type: GET_SUPPLIERS,
        payload: {}
    });
    authFetch(`/costs/${id}/suppliers`, {
        method: "GET",
    }).then((response) => response.json()).then((data) => {
        dispatch({
            type: GET_SUPPLIERS_SUCCESS,
            payload: data,
        });
    }).catch((error) => {
        dispatch({
            type: GET_SUPPLIERS_FAIL,
            payload: error,
        });
    });
}

export const DELETE_SUPPLIER = "delete_supplier";
export const DELETE_SUPPLIER_SUCCESS = "delete_supplier_success";
export const DELETE_SUPPLIER_FAIL = "delete_supplier_fail";

const deleteSupplier = ({dispatch}: { dispatch: Dispatch; }, idSupplier: string, idAssociation: string) => {
    dispatch({
        type: DELETE_SUPPLIER,
        payload: {}
    });
    authFetch(`/costs/${idAssociation}/suppliers/${idSupplier}`, {
        method: "DELETE",
    }).then((response) => response.json()).then((data) => {
        dispatch({
            type: DELETE_SUPPLIER_SUCCESS,
            payload: data,
        });
        getSupplier({dispatch}, idAssociation);
    }).catch((error) => {
        dispatch({
            type: DELETE_SUPPLIER_FAIL,
            payload: error,
        });
        getSupplier({dispatch}, idAssociation);
    });
}

export const UPDATE_SUPPLIER = "update_supplier";
export const UPDATE_SUPPLIER_SUCCESS = "update_supplier_success";
export const UPDATE_SUPPLIER_FAIL = "update_supplier_fail";

const updateSupplier = ({
                            dispatch,
                            values,
                            history
                        }: { dispatch: Dispatch; values: object, history: History }, idSupplier: string, idAssociation: string) => {
    dispatch({
        type: UPDATE_SUPPLIER,
        payload: {}
    });
    let responseStats: number;
    authFetch(`/costs/${idAssociation}/suppliers/${idSupplier}`, {
        method: "PUT",
        body: JSON.stringify(values)
    }).then((response) => {
        responseStats = response.status
        return response.json()
    }).then((data) => {
        if (responseStats == 200) {
            dispatch({
                type: UPDATE_SUPPLIER_SUCCESS,
                payload: data,
            });
            history.goBack();
        } else {
            dispatch({
                type: UPDATE_SUPPLIER_FAIL,
                payload: data.message,
            });
        }
    }).catch((error) => {
        dispatch({
            type: UPDATE_SUPPLIER_FAIL,
            payload: error,
        });
        getSupplier({dispatch}, idAssociation);
    });
}


export const GET_SUPPLIER = "get_supplier";
const getSupplierById = (
    {dispatch}: { dispatch: Dispatch }, associationId: string, supplierId: string) => {
    dispatch({
        type: GET_SUPPLIER,
        payload: {}
    })
    authFetch(`/costs/${associationId}/suppliers/${supplierId}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_SUPPLIER,
                payload: data
            });
        })
}


export {addSupplier, getExpenseTypes, getSupplier, deleteSupplier, updateSupplier, getSupplierById}