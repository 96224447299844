import React, {createContext, useContext, useReducer} from "react"
import {Action, Dispatch, ReactChildrenType} from "../constants/globalTypes";
import {
    ADD_GROUP_TO_BREADCRUMBS,
    CHANGE_SELECTED_OPTION,
    GET_GROUPS_BREADCRUMBS_FROM_LOCAL_STORAGE,
    RESET_BREAD_CRUMBS_ARRAY,
    SEARCH_TERM,
    SEARCH_TERM_FAIL,
    SEARCH_TERM_SUCCESS,
    RESET_SEARCH_OPTIONS
} from "./GlobalActions";
import _ from "lodash";

type State = {
    groupsBreadCrumbs: { id: string, name: string, customOnClick?: boolean, crumbType?: string }[];
    currentCrumb: { id: string, name: string, customOnClick?: boolean, crumbType?: string };
    globalSearchOptions: { id: string, name: string }[];
    selectedGlobalSearchOption: string;
    searchOptions: [];
}

const GlobalStateContext = createContext<State | undefined>(undefined);
const GlobalDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    groupsBreadCrumbs: [],
    currentCrumb: {id: "", name: ""},
    globalSearchOptions: [
        {
            id: "association",
            name: "asociație"
        }, {
            id: "cupProperty",
            name: "cod unic proprietate"
        }, {
            id: "ownerName",
            name: "nume proprietar"
        }, {
            id: "numberProperty",
            name: "numar proprietate"
        }, {
            id: "block",
            name: "bloc"
        }, {
            id: "houses",
            name: "zonă de case"
        }, {
            id: "commercialSpace",
            name: "spațiu comercial"
        },
    ],
    selectedGlobalSearchOption: "association",
    searchOptions: []
};

const globalReducer = (state: State, action: Action) => {
    let lsBreadCrumbs: { name: string, id: string, customOnClick?: boolean, crumbType?: string }[] = JSON.parse(localStorage.getItem("groupsBreadCrumbs") as string);
    let currentCrumb: { name: string, id: string, customOnClick?: boolean, crumbType?: string } = JSON.parse(localStorage.getItem("currentCrumb") as string);

    if (!lsBreadCrumbs) {
        lsBreadCrumbs = []
    }
    if (!currentCrumb) {
        currentCrumb = {name: "", id: ""}
    }
    switch (action.type) {
        case ADD_GROUP_TO_BREADCRUMBS: {
            const foundInLsBreadCrumbs = _.findIndex(lsBreadCrumbs, breadCrumb => breadCrumb.id === action.payload.id);
            if (foundInLsBreadCrumbs === -1) {
                lsBreadCrumbs = [...lsBreadCrumbs, action.payload]
            } else {
                const numberOfElementsToDelete = lsBreadCrumbs.length - foundInLsBreadCrumbs;
                if (currentCrumb.id !== action.payload.id) {
                    lsBreadCrumbs.splice(foundInLsBreadCrumbs, numberOfElementsToDelete)
                }
            }

            localStorage.setItem("groupsBreadCrumbs", JSON.stringify(lsBreadCrumbs));
            localStorage.setItem("currentCrumb", JSON.stringify(action.payload));

            return {
                ...state,
                groupsBreadCrumbs: lsBreadCrumbs,
                currentCrumb: action.payload
            }
        }
        case GET_GROUPS_BREADCRUMBS_FROM_LOCAL_STORAGE: {
            return {
                ...state,
                groupsBreadCrumbs: lsBreadCrumbs
            }
        }
        case RESET_BREAD_CRUMBS_ARRAY: {
            localStorage.setItem("groupsBreadCrumbs", JSON.stringify([]));
            return {
                ...state,
                groupsBreadCrumbs: [],
            }
        }
        case RESET_SEARCH_OPTIONS:{
            return {
                ...state,
                searchOptions:[]
            }
        }
        case CHANGE_SELECTED_OPTION: {
            return {
                ...state,
                selectedGlobalSearchOption: action.payload
            }
        }
        case SEARCH_TERM: {
            return {
                ...state,
                loading: true,
            }
        }
        case SEARCH_TERM_SUCCESS: {
            return {
                ...state,
                loading: false,
                searchOptions: action.payload
            }
        }
        case SEARCH_TERM_FAIL: {
            return {
                ...state,
                loading: false,
            }
        }
        default: {
            return state
        }
    }
};

const GlobalProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(globalReducer, initialState);
    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
};

const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (context === undefined) {
        throw new Error("useGlobalState must be used within a GlobalProvider")
    }
    return context;
};

const useGlobalDispatch = () => {
    const context = useContext(GlobalDispatchContext);
    if (context === undefined) {
        throw new Error("useGlobalDispatch must be used within a GlobalProvider")
    }
    return context;
};

export {GlobalProvider, useGlobalDispatch, useGlobalState}