import React, {createContext, useContext, useReducer} from "react";
import {Action, Dispatch, ReactChildrenType,} from "../../constants/globalTypes";
import {
    ADD_ASSOCIATION,
    ADD_ASSOCIATION_FAIL,
    ADD_ASSOCIATION_SUCCESS,
    DESELECT_ASSOCIATION,
    GET_ASSOCIATION,
    GET_ASSOCIATION_FAIL,
    GET_ASSOCIATION_SUCCESS,
    GET_ASSOCIATIONS,
    GET_ASSOCIATIONS_FAIL,
    GET_ASSOCIATIONS_SUCCESS, GET_OPENED_MONTH, GET_OPENED_MONTH_FAIL, GET_OPENED_MONTH_SUCCESS,
    GET_SELECTED_ASSOCIATION,
    IS_NOT_ASSOCIATION_SELECTED,
    SET_SELECTED_ASSOCIATION,
    UPDATE_ASSOCIATION,
    UPDATE_ASSOCIATION_FAIL,
    UPDATE_ASSOCIATION_SUCCESS,
} from "./AssociationActions";

type State = {
    associations: [];
    loading: boolean;
    error: object;
    selectedAssociation: {
        id: string;
        name: string;
    };
    isAssociationSelected: boolean;
    fetchedAssociation: object;
    openedMonth: {
        code: number;
        id: string;
    }
};

const AssociationStateContext = createContext<State | undefined>(undefined);
const AssociationDispatchContext = createContext<Dispatch | undefined>(
    undefined
);

const initialState: State = {
    selectedAssociation: {id: "", name: ""},
    isAssociationSelected: false,
    associations: [],
    fetchedAssociation: {},
    loading: false,
    error: {},
    openedMonth: {
        code: 0,
        id: ""
    }
};

const associationReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_ASSOCIATIONS: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_ASSOCIATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                associations: action.payload,
            };
        }
        case GET_ASSOCIATIONS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                associations: [],
            };
        }
        case SET_SELECTED_ASSOCIATION: {
            return {
                ...state,
                loading: false,
                selectedAssociation: action.payload,
                isAssociationSelected: true,
            };
        }
        case GET_SELECTED_ASSOCIATION: {
            return {
                ...state,
                selectedAssociation: action.payload,
            };
        }
        case IS_NOT_ASSOCIATION_SELECTED: {
            return {
                ...state,
                isAssociationSelected: false,
                loading: false,
            };
        }
        case DESELECT_ASSOCIATION: {
            return {
                ...state,
                loading: false,
                isAssociationSelected: false,
                selectedAssociation: {
                    id: "",
                    name: "",
                },
            };
        }
        case ADD_ASSOCIATION: {
            return {
                ...state,
                loading: true,
            };
        }
        case ADD_ASSOCIATION_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ADD_ASSOCIATION_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }
        case GET_ASSOCIATION: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_ASSOCIATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                fetchedAssociation: action.payload,
                selectedAssociation: {
                    id: action.payload.id,
                    name: action.payload.name
                }
            };
        }
        case GET_ASSOCIATION_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case UPDATE_ASSOCIATION: {
            return {
                ...state,
                loading: true,
            };
        }
        case UPDATE_ASSOCIATION_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case UPDATE_ASSOCIATION_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case GET_OPENED_MONTH:{
            return {
                ...state,
                loading: true
            }
        }
        case GET_OPENED_MONTH_SUCCESS: {
            return {
                ...state,
                loading: false,
                openedMonth:action.payload
            }
        }
        case GET_OPENED_MONTH_FAIL:{
            return {
                ...state,
                error:action.payload,
                openedMonth: {
                    code: 0,
                    id: ""
                }
            }
        }
        default: {
            return state;
        }
    }
};

const AssociationProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(associationReducer, initialState);
    return (
        <AssociationStateContext.Provider value={state}>
            <AssociationDispatchContext.Provider value={dispatch}>
                {children}
            </AssociationDispatchContext.Provider>
        </AssociationStateContext.Provider>
    );
};

const useAssociationState = () => {
    const context = useContext(AssociationStateContext);
    if (context === undefined) {
        throw new Error(
            "useAssociationState must be used within a AssociationProvider"
        );
    }
    return context;
};

const useAssociationDispatch = () => {
    const context = useContext(AssociationDispatchContext);
    if (context === undefined) {
        throw new Error(
            "useAssociationDispatch must be used within a AssociationProvider"
        );
    }
    return context;
};
export {AssociationProvider, useAssociationState, useAssociationDispatch};
