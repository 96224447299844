import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_FILE,
    CLEAR_FILES,
    GET_FILE_BY_ID, GET_FILE_BY_ID_FAIL, GET_FILE_BY_ID_SUCCESS,
    GET_FILES,
    GET_FILES_FAIL,
    GET_FILES_SUCCESS,
    REMOVE_FILE
} from "./FilesAction";
import {act} from "react-dom/test-utils";


type State = {
    loading: boolean;
    error: any;
    files: any[];
    filesToUpload: File[];
    fetchedFile: any;
}

const FilesStateContext = createContext<State | undefined>(undefined);
const FilesDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    error: {},
    files: [],
    loading: false,
    filesToUpload: [],
    fetchedFile: {}
}

const filesReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_FILE_BY_ID: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_FILE_BY_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                fetchedFile: action.payload
            }
        }
        case GET_FILE_BY_ID_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case CLEAR_FILES: {
            return {
                ...state,
                filesToUpload: []
            }
        }
        case ADD_FILE: {
            return {
                ...state,
                filesToUpload: [action.payload]
            }
        }
        case REMOVE_FILE: {
            return {
                ...state,
                filesToUpload: []
            }
        }
        case GET_FILES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_FILES_SUCCESS: {
            return {
                ...state,
                files: action.payload,
                loading: false
            }
        }
        case GET_FILES_FAIL: {
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        }
        default: {
            return state;
        }
    }
}

const FilesProvider = ({children}: ReactChildrenType) => {
    const [state, dispatch] = useReducer(filesReducer, initialState);
    return (
        <FilesStateContext.Provider value={state}>
            <FilesDispatchContext.Provider value={dispatch}>
                {children}
            </FilesDispatchContext.Provider>
        </FilesStateContext.Provider>
    )
}

const useFilesState = () => {
    const context = useContext(FilesStateContext);
    if (context === undefined) {
        throw new Error("useFilesState must be used within a FilesProvider")
    }
    return context
}

const useFilesDispatch = () => {
    const context = useContext(FilesDispatchContext);
    if (context === undefined) {
        throw new Error("useFilesDispatch must be used within FilesProvider")
    }
    return context
}

export {useFilesDispatch, useFilesState, FilesProvider}