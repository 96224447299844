import React, {createContext, useContext, useReducer} from "react";
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_EXPENSE_TYPE_GROUP,
    ADD_EXPENSE_TYPE_GROUP_FAIL,
    ADD_EXPENSE_TYPE_GROUP_SUCCESS,
    GET_EXPENSE_TYPE_GROUP,
    GET_EXPENSE_TYPE_GROUP_FAIL,
    GET_EXPENSE_TYPE_GROUP_SUCCESS,
    GET_EXPENSE_TYPES_GROUPS,
    GET_EXPENSE_TYPES_GROUPS_FAIL,
    GET_EXPENSE_TYPES_GROUPS_SUCCESS,
    UPDATE_EXPENSE_TYPE_GROUP,
    UPDATE_EXPENSE_TYPE_GROUP_FAIL,
    UPDATE_EXPENSE_TYPE_GROUP_SUCCESS
} from "./ExpenseTypesGroupsActions";
import {
    REMOVE_ELEMENT_FROM_GROUP,
    REMOVE_ELEMENT_FROM_GROUP_FAIL,
    REMOVE_ELEMENT_FROM_GROUP_SUCCESS
} from "../expenseTypes/ExpenseTypesActions";

type State = {
    loading: boolean;
    error: any;
    expenseTypesGroups: [];
    expenseTypeGroupsChildren: [];
}

const ExpenseTypesGroupsStateContext = createContext<State | undefined>(undefined);
const ExpenseTypesGroupsDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    loading: false,
    error: null,
    expenseTypesGroups: [],
    expenseTypeGroupsChildren: [],
};

const expenseTypesGroupsReducer = (state: State, action: Action) => {
    switch (action.type) {
        case ADD_EXPENSE_TYPE_GROUP: {
            return {
                ...state,
                loading: true
            }
        }
        case ADD_EXPENSE_TYPE_GROUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }
        case ADD_EXPENSE_TYPE_GROUP_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_EXPENSE_TYPES_GROUPS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_EXPENSE_TYPES_GROUPS_SUCCESS: {
            return {
                ...state,
                loading: false,
                expenseTypesGroups: action.payload
            }
        }
        case GET_EXPENSE_TYPES_GROUPS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_EXPENSE_TYPE_GROUP: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_EXPENSE_TYPE_GROUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                expenseTypeGroupsChildren: action.payload
            }
        }
        case GET_EXPENSE_TYPE_GROUP_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case UPDATE_EXPENSE_TYPE_GROUP: {
            return {
                ...state,
                loading: true,
            }
        }
        case UPDATE_EXPENSE_TYPE_GROUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null
            }
        }
        case UPDATE_EXPENSE_TYPE_GROUP_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case REMOVE_ELEMENT_FROM_GROUP: {
            return {
                ...state,
                loading: true
            }
        }
        case REMOVE_ELEMENT_FROM_GROUP_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case REMOVE_ELEMENT_FROM_GROUP_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return state
        }
    }
};

const ExpenseTypesGroupsProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(expenseTypesGroupsReducer, initialState);
    return (
        <ExpenseTypesGroupsStateContext.Provider value={state}>
            <ExpenseTypesGroupsDispatchContext.Provider value={dispatch}>
                {children}
            </ExpenseTypesGroupsDispatchContext.Provider>
        </ExpenseTypesGroupsStateContext.Provider>
    )
};

const useExpenseTypesGroupsState = () => {
    const context = useContext(ExpenseTypesGroupsStateContext);
    if (context === undefined) {
        throw new Error("useExpenseTypesState must be used whitin a ExpenseTypesGroupsStateContext")
    }
    return context
};

const useExpenseTypesGroupsDispatch = () => {
    const context = useContext(ExpenseTypesGroupsDispatchContext);
    if (context === undefined) {
        throw new Error("useExpenseTypesDispatch must be used whitin a ExpenseTypesGroupsDispatchContext")
    }
    return context
};

export {ExpenseTypesGroupsProvider, useExpenseTypesGroupsState, useExpenseTypesGroupsDispatch}