import {AssociationFormValuesTypes} from "../association/form/AssociationForm";
import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";
import {getSelectedProperty, SET_PROPERTY} from "../properties/PropertiesActions";
import _ from "lodash";

export const UPDATE_COUNTER = "update_counter";
export const UPDATE_COUNTER_SUCCESS = "update_counter_success";
export const UPDATE_COUNTER_FAIL = "update_counter_fail";
const updateCounter = ({
                           associationId,
                           counterId,
                           values,
                           dispatch,
                           propertiesDispatch,
                           propertyId,
                           globalDispatch,
                           history
                       }: {
    associationId: string;
    counterId: string;
    values: AssociationFormValuesTypes;
    dispatch: Dispatch;
    propertiesDispatch: any,
    propertyId: string,
    globalDispatch: any,
    history: any
}) => {
    dispatch({
        type: UPDATE_COUNTER,
        payload: {},
    });
    authFetch(`/properties/${associationId}/counters/${counterId}`, {
        method: "PUT",
        body: JSON.stringify(values)
    }).then(response => {
        if (!response.ok) {
            return response.text().then(error => {
                dispatch({
                    type: UPDATE_COUNTER_FAIL,
                    payload: JSON.parse(error)
                })
            })
        }
        return response.json()
    }).then(data => {
        if (data) {
            dispatch({
                type: UPDATE_COUNTER_SUCCESS,
                payload: data
            })
            getSelectedProperty(
                {dispatch: propertiesDispatch},
                propertyId,
                associationId,
                {globalDispatch: globalDispatch}
            );
            history.goBack()
        }
    })
}


export const UPDATE_COUNTER_INDEX = "update_counter_index";
export const UPDATE_COUNTER_INDEX_SUCCESS = "update_counter_index_success";
export const UPDATE_COUNTER_INDEX_FAIL = "update_counter_index_fail";
const updateCounterIndex = ({
                                associationId,
                                counterIndexId,
                                counterId,
                                values,
                                dispatch,


                            }: {
    associationId: string;
    counterId: string;
    counterIndexId: string;
    values: any;
    dispatch: Dispatch;
}) => {
    dispatch({
        type: UPDATE_COUNTER_INDEX,
        payload: {},
    });

    authFetch(`/properties/${associationId}/countersIndexes/${counterIndexId}`, {
        method: "PUT",
        body: JSON.stringify(values)
    }).then((response) => response.json())
        .then((data) => {
                dispatch({
                    type: UPDATE_COUNTER_INDEX_SUCCESS,
                    payload: data,
                });
                getCountersIndexes({counterId: counterId, dispatch: dispatch, selectedAssociationId: associationId})
            }
        ).catch((error) => {
        dispatch({
            type: UPDATE_COUNTER_INDEX_FAIL,
            payload: error,
        });
        getCountersIndexes({counterId: counterId, dispatch: dispatch, selectedAssociationId: associationId})
    });
}

export const ADD_COUNTER_INDEX = "add_counter_index";
export const ADD_COUNTER_INDEX_SUCCESS = "add_counter_index_success";
export const ADD_COUNTER_INDEX_FAIL = "add_counter_index_fail";
const addCounterIndex = ({
                             associationId,
                             counterId,
                             values,
                             dispatch,
                             propertiesDispatch,
                             globalDispatch,
                             propertyId,
                             history
                         }: {
    associationId: string;
    counterId: string;
    values: AssociationFormValuesTypes;
    dispatch: Dispatch;
    propertiesDispatch: any;
    globalDispatch: any;
    propertyId: string;
    history: any;
}) => {
    dispatch({
        type: ADD_COUNTER_INDEX,
        payload: {},
    });
    authFetch(`/properties/${associationId}/countersIndexes/${counterId}`, {
        method: "POST",
        body: JSON.stringify(values)
    }).then(response => {
        if (!response.ok) {
            return response.text().then(error => {
                dispatch({
                    type: ADD_COUNTER_INDEX_FAIL,
                    payload: JSON.parse(error)
                })
            })
        }
        return response.json()
    })
        .then(data => {
            if (data) {
                dispatch({
                    type: ADD_COUNTER_INDEX_SUCCESS,
                    payload: data,
                });
                // getSelectedProperty(
                //     {dispatch: propertiesDispatch},
                //     propertyId,
                //     associationId,
                //     {globalDispatch: globalDispatch}
                // );
                // getCountersIndexes({counterId: counterId, dispatch: dispatch, selectedAssociationId: associationId})
                history.goBack()
            }
        })
        .catch(error => {
            console.log("error", error)
        })
}


export const DELETE_COUNTER = "delete_counter";
export const DELETE_COUNTER_SUCCESS = "delete_counter_success";
export const DELETE_COUNTER_FAIL = "delete_counter_fail";
const deleteCounter = (
    {dispatch}: { dispatch: Dispatch }, idAssociation: string, counterId: string, propertyId: string, propertiesDispatch: any, globalDispatch: any) => {
    dispatch({type: DELETE_COUNTER, payload: {}});
    authFetch(`/properties/${idAssociation}/counters/${counterId}`, {
        method: 'DELETE'
    }).then(response => {
        if (!response.ok) {
            return response.text().then(error => {
                dispatch({
                    type: DELETE_COUNTER_FAIL,
                    payload: JSON.parse(error)
                })
            })
        }
        return response.json()
    })
        .then(data => {
            if(data){
                dispatch({
                    type: DELETE_COUNTER_SUCCESS,
                    payload: data,
                });
                getSelectedProperty(
                    {dispatch: propertiesDispatch},
                    propertyId,
                    idAssociation,
                    {globalDispatch: globalDispatch}
                );
                getAllCountersByOwnerId({associationId: idAssociation, dispatch: dispatch, ownerId: propertyId})
            }
          })
        .catch(error => {
            console.log("error", error)
        })

};

export const DELETE_COUNTER_INDEX = "delete_counter_index";
export const DELETE_COUNTER_INDEX_SUCCESS = "delete_counter_index_success";
export const DELETE_COUNTER_INDEX_FAIL = "delete_counter_index_fail";
const deleteCounterIndex = (
    {dispatch}: { dispatch: Dispatch }, associationId: string, counterIndexId: string, counterId: string) => {
    dispatch({type: DELETE_COUNTER_INDEX, payload: {}});
    authFetch(`/properties/${associationId}/countersIndexes/${counterIndexId}`, {
        method: 'DELETE'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: DELETE_COUNTER_INDEX_SUCCESS,
                payload: data
            });
            getCountersIndexes({counterId: counterId, dispatch: dispatch, selectedAssociationId: associationId})
        })
        .catch((error) => {
            dispatch({
                type: DELETE_COUNTER_INDEX_FAIL,
                payload: error
            });
            getCountersIndexes({counterId: counterId, dispatch: dispatch, selectedAssociationId: associationId})
        });
};

export const ADD_COUNTER = "add_counter";
export const ADD_COUNTER_SUCCESS = "add_counter_success";
export const ADD_COUNTER_FAIL = "add_counter_fail";
const addCounters = ({
                         dispatch,
                         history

                     }: {
    dispatch: Dispatch;
    history: any

}, propertyId: string, values: any, associationId: string, globalDispatch: any, propertiesDispatch: any) => {
    dispatch({
        type: ADD_COUNTER,
        payload: {},
    });
    var respStats: number;

    authFetch(`/properties/${associationId}/counters/${propertyId}`, {
        method: "POST",
        body: JSON.stringify(values),
    })
        .then(response => {
            respStats = response.status;
            return response.json()
        })
        .then(data => {
            dispatch({
                type: ADD_COUNTER_SUCCESS,
                payload: data,
            });
            if (respStats === 200) {
                getSelectedProperty(
                    {dispatch: propertiesDispatch},
                    propertyId,
                    associationId,
                    {globalDispatch: globalDispatch}
                );
                history.goBack();
            } else {
                dispatch({
                    type: ADD_COUNTER_FAIL,
                    payload: data.message,
                });
            }
        })

};

export const GET_COUNTERS_INDEXES = "get_counters_indexes";
const getCountersIndexes = (
    {
        dispatch,
        counterId,
        selectedAssociationId
    }: { dispatch: Dispatch, counterId: string, selectedAssociationId: string }) => {
    dispatch({
        type: GET_COUNTERS_INDEXES,
        payload: []
    })
    authFetch(`/properties/${selectedAssociationId}/countersIndexes/${counterId}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_COUNTERS_INDEXES,
                payload: data
            });
        })

}

export const CLEAR_SELECTED_COUNTER = "clear_selected_counter";
export const clearSelectedCounter = ({dispatch}:{dispatch:Dispatch})=>{
    dispatch({type:CLEAR_SELECTED_COUNTER,payload:{}})
}

export const GET_COUNTER_BY_ID = "get_counter_by_id";
const getCounterById = (
    {dispatch, counterId}: { dispatch: Dispatch, counterId: string }) => {
    dispatch({
        type: GET_COUNTER_BY_ID,
        payload: {}
    })
    authFetch(`/properties//counter/${counterId}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_COUNTER_BY_ID,
                payload: data
            });
        })
}

export const GET_COUNTER_INDEX_BY_ID = "get_counter_index_by_id";
const getCounterIndexById = (
    {dispatch, counterId}: { dispatch: Dispatch, counterId: string }) => {
    dispatch({
        type: GET_COUNTER_INDEX_BY_ID,
        payload: {}
    })
    authFetch(`/properties//counterIndex/${counterId}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_COUNTER_INDEX_BY_ID,
                payload: data
            });
        })
}

export const SET_COUNTER_ID = "set_counter_id";
const setCounterId = (
    {dispatch}: { dispatch: Dispatch }, counterId: string) => {
    localStorage.setItem("counterId", counterId);
    dispatch({
        type: SET_PROPERTY,
        payload: {}
    })
}

export const GET_COUNTER_TYPE = "get_counter_type";
const getCounterType = (
    {dispatch}: { dispatch: Dispatch }) => {
    dispatch({type: GET_COUNTER_TYPE, payload: {}});
    authFetch(`/costs//counterTypes`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_COUNTER_TYPE,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_COUNTER_TYPE,
                payload: error
            })
        });
};

export const ADD_CSV_FILE = 'add_csv_file';
const addCSVFile = ({
                        dispatch,
                        file
                    }: {
                        dispatch: Dispatch,
                        file: File
                    }
) => {
    dispatch({
        type: ADD_CSV_FILE,
        payload: file
    })
};

export const REMOVE_CSV_FILE = 'remove_csv_file';
const removeCSVFile = ({
                           dispatch,
                           fileIndex
                       }: {
                           dispatch: Dispatch,
                           fileIndex: number
                       }
) => {
    dispatch({
        type: REMOVE_CSV_FILE,
        payload: fileIndex
    })
};
export const CLEAR_CSV = "clear_csv"
const clearCSVFiles = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({type: CLEAR_CSV, payload: {}})
}

export const ADD_NEW_CSV_COUNTER_INDEXES = "add_new_csv_counter_indexes"
export const ADD_NEW_CSV_COUNTER_INDEXES_SUCCESS = "add_new_csv_counter_indexes_success"
export const ADD_NEW_CSV_COUNTER_INDEXES_FAIL = "add_new_csv_counter_indexes_fail"

export const addNewCSVCounterIndexes = ({
                                            dispatch,
                                            files,
                                            history,
                                            date
                                        }: { dispatch: Dispatch, files: File[], history: any, date: any }) => {
    dispatch({type: ADD_NEW_CSV_COUNTER_INDEXES, payload: {}});
    let data = new FormData()
    _.forEach(files, file => {
        data.append('files', file)
    });
    data.append('date', date)

    authFetch(`/properties//CsvCountersIndexes`, {
        method: "POST",
        body: data
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: ADD_NEW_CSV_COUNTER_INDEXES_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: ADD_NEW_CSV_COUNTER_INDEXES_SUCCESS,
                    payload: data
                })
                history.goBack()
            }
        })
        .catch(error => {
            console.log("error", error)
        })
}

export const GET_COUNTERS_INDEXES_TYPES = "get_counters_indexes_types";
export const GET_COUNTERS_INDEXES_TYPES_SUCCESS = "get_counters_indexes_types_success";
export const GET_COUNTERS_INDEXES_TYPES_FAIL = "get_counters_indexes_types_fail";

export const getAllCountersIndexesTypes = ({
                                               dispatch,
                                               associationId
                                           }: { dispatch: Dispatch, associationId: string }) => {
    dispatch({type: GET_COUNTERS_INDEXES_TYPES, payload: {}})
    authFetch(`/properties/${associationId}/countersIndexesTypes`,
        {method: "GET"})
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.text().then(error => {
                    dispatch({type: GET_COUNTERS_INDEXES_TYPES_FAIL, payload: error})
                })
            }
        }).then(data => {
        if (data) {
            dispatch({type: GET_COUNTERS_INDEXES_TYPES_SUCCESS, payload: data})
        }
    })
}

export const GET_ALL_COUNTERS = "get_all_counters";
export const GET_ALL_COUNTERS_SUCCESS = "get_all_counters_success";
export const GET_ALL_COUNTERS_FAIL = "get_all_counters_fail";


export const getAllCountersByOwnerId = ({
                                            dispatch,
                                            associationId,
                                            ownerId
                                        }: { dispatch: Dispatch, associationId: string, ownerId: string }) => {
    dispatch({type: GET_ALL_COUNTERS, payload: {}})
    authFetch(`/properties/${associationId}/counters/${ownerId}`,
        {method: "GET"})
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.text().then(error => {
                    dispatch({type: GET_ALL_COUNTERS_FAIL, payload: error})
                })
            }
        }).then(data => {
        dispatch({type: GET_ALL_COUNTERS_SUCCESS, payload: data})
    })
}
export const GET_ALL_COUNTERS_PARENTS = "get_all_counters_parents";
export const GET_ALL_COUNTERS_PARENTS_SUCCESS = "get_all_counters_parents_success";
export const GET_ALL_COUNTERS_PARENTS_FAIL = "get_all_counters_parents_fail";


export const getAllCounterParents = ({
                                            dispatch,
                                            associationId,
                                            ownerId
                                        }: { dispatch: Dispatch, associationId: string, ownerId: string }) => {
    dispatch({type: GET_ALL_COUNTERS_PARENTS, payload: {}})
    authFetch(`/properties/${associationId}/countersParents/${ownerId}`,
        {method: "GET"})
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.text().then(error => {
                    dispatch({type: GET_ALL_COUNTERS_PARENTS_FAIL, payload: error})
                })
            }
        }).then(data => {
        dispatch({type: GET_ALL_COUNTERS_PARENTS_SUCCESS, payload: data})
    })
}

export {
    addCSVFile,
    removeCSVFile,
    updateCounter,
    updateCounterIndex,
    addCounterIndex,
    deleteCounterIndex,
    deleteCounter,
    getCountersIndexes,
    setCounterId,
    getCounterType,
    addCounters,
    getCounterById,
    getCounterIndexById,
    clearCSVFiles
}
