import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";

export const GET_REGISTERED_MONTHS = `get_registered_months`;
export const GET_REGISTERED_MONTHS_SUCCESS = `get_registered_months_success`;
export const GET_REGISTERED_MONTHS_FAIL = `get_registered_months_fail`;

export const getRegisteredMonths = ({
                                        associationId,
                                        year,
                                        dispatch
                                    }: {
                                        associationId: string,
                                        year?: string,
                                        dispatch: Dispatch
                                    }
) => {
    dispatch({
        type: GET_REGISTERED_MONTHS,
        payload: {}
    });
    let url = `/costs/${associationId}/registered-months`;
    if (year) {
        url = `${url}?year=${year}`
    }
    authFetch(url, {
        method: 'GET'
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_REGISTERED_MONTHS_FAIL,
                        payload: JSON.stringify(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_REGISTERED_MONTHS_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const GET_REGISTERED_YEARS = 'get_registered_years';
export const GET_REGISTERED_YEARS_SUCCESS = 'get_registered_years_success';
export const GET_REGISTERED_YEARS_FAIL = 'get_registered_years_fail';
export const getRegisteredYears = ({
                                       associationId,
                                       dispatch
                                   }: {
                                       associationId: string,
                                       dispatch: Dispatch
                                   }
) => {
    dispatch({
        type: GET_REGISTERED_YEARS,
        payload: {}
    })
    authFetch(`/costs/${associationId}/registered-months/registered-years`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_REGISTERED_YEARS_FAIL,
                        payload: JSON.stringify(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_REGISTERED_YEARS_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const SET_SELECTED_YEAR = 'set_selected_year';
export const setSelectedYear = ({
                                    dispatch,
                                    year
                                }: {
                                    dispatch: Dispatch,
                                    year: string
                                }
) => {
    dispatch({
        type: SET_SELECTED_YEAR,
        payload: year
    })
};
export const SET_SELECTED_MONTH = 'set_selected_month';
export const setSelectedMonth = ({
                                     dispatch,
                                     month
                                 }: {
                                     dispatch: Dispatch,
                                     month: string
                                 }
) => {
    dispatch({
        type: SET_SELECTED_MONTH,
        payload: month
    })
};

export const GET_AVAILABLE_MONTHS_BY_YEAR = 'get_available_months_by_year';
export const GET_AVAILABLE_MONTHS_BY_YEAR_SUCCESS = 'get_available_months_by_year_success';
export const GET_AVAILABLE_MONTHS_BY_YEAR_FAIL = 'get_available_months_by_year_fail';
export const getAvailableMonthsByYear = ({
                                             associationId,
                                             year,
                                             dispatch
                                         }: {
                                             associationId: string,
                                             year: string,
                                             dispatch: Dispatch
                                         }
) => {
    dispatch({
        type: GET_AVAILABLE_MONTHS_BY_YEAR,
        payload: {}
    });

    authFetch(`/costs/${associationId}/registered-months/available-months?year=${year}`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_AVAILABLE_MONTHS_BY_YEAR_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_AVAILABLE_MONTHS_BY_YEAR_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const ADD_REGISTERED_MONTH = 'add_registered_month';
export const ADD_REGISTERED_MONTH_SUCCESS = 'add_registered_month_success';
export const ADD_REGISTERED_MONTH_FAIL = 'add_registered_month_fail';
export const addRegisteredMonth = ({
                                       code,
                                       associationId,
                                       dispatch,
                                       callback
                                   }: {
                                       code: number,
                                       associationId: string,
                                       dispatch: Dispatch,
                                       callback: () => void
                                   }
) => {
    dispatch({
        type: ADD_REGISTERED_MONTH,
        payload: {}
    });

    authFetch(`/costs/${associationId}/registered-months`, {
        method: "POST",
        body: JSON.stringify({
            code
        })
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: ADD_REGISTERED_MONTH_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: ADD_REGISTERED_MONTH_SUCCESS,
                    payload: data
                });
                callback()
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const START_MONTH = 'start_month';
export const START_MONTH_SUCCESS = 'start_month_success';
export const START_MONTH_FAIL = 'start_month_fail';
export const startMonth = ({
                               code,
                               associationId,
                               dispatch,
                               callback
                           }: {
                               code: number,
                               associationId: string,
                               dispatch: Dispatch,
                               callback?: () => void
                           }
) => {
    dispatch({
        type: START_MONTH,
        payload: {}
    });
    authFetch(`/costs/${associationId}/registered-months/start-month`, {
        method: "PUT",
        body: JSON.stringify({
            code
        })
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: START_MONTH_FAIL,
                        payload: JSON.stringify(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: START_MONTH_SUCCESS,
                    payload: data
                });
                if (callback) {
                    callback()
                }
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const STOP_MONTH = 'stop_month';
export const STOP_MONTH_SUCCESS = 'stop_month_success';
export const STOP_MONTH_FAIL = 'stop_month_fail';
export const stopMonth = ({
                              code,
                              associationId,
                              displayListAt,
                              dispatch,
                              callback
                          }: {
                              code: number,
                              displayListAt: any,
                              associationId: string,
                              dispatch: Dispatch,
                              callback: () => void
                          }
) => {
    dispatch({
        type: STOP_MONTH,
        payload: {}
    });
    authFetch(`/costs/${associationId}/registered-months/close-month`, {
        method: "PUT",
        body: JSON.stringify({
            code,
            displayListAt
        })
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: STOP_MONTH_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: STOP_MONTH_SUCCESS,
                    payload: data
                });
                callback()
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};