import React, {createContext, useContext, useReducer} from "react";
import {Action, Dispatch, ReactChildrenType,} from "../../constants/globalTypes";
import {
    ADD_NOTIFICATION,
    ADD_NOTIFICATION_FAIL,
    ADD_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_FAIL,
    DELETE_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION,
    GET_NOTIFICATION_FAIL,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_FAIL,
    GET_NOTIFICATIONS_SUCCESS
} from "./NotificationsActions";

type State = {
    loading: boolean;
    error: object;
    notifications: [];
    triggerGet: boolean;
    fetchedNotification: any
};

const NotificationsStateContext = createContext<State | undefined>(undefined);
const NotificationsDispatchContext = createContext<Dispatch | undefined>(
    undefined
);

const initialState: State = {
    loading: false,
    error: {},
    notifications: [],
    triggerGet: false,
    fetchedNotification: {}
};

const notificationsReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS: {
            return {
                ...state,
                loading: true,
                triggerGet: false
            }
        }
        case GET_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                notifications: action.payload,
                loading: false
            }
        }
        case GET_NOTIFICATIONS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case DELETE_NOTIFICATION: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETE_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                triggerGet: true
            }
        }
        case DELETE_NOTIFICATION_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_NOTIFICATION: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                fetchedNotification: action.payload
            }
        }
        case GET_NOTIFICATION_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case ADD_NOTIFICATION: {
            return {
                ...state,
                loading: true
            }
        }
        case ADD_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case ADD_NOTIFICATION_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return state
        }
    }
};

const NotificationsProvider = ({children}: ReactChildrenType) => {
    const [state, dispatch] = useReducer(notificationsReducer, initialState);
    return (
        <NotificationsStateContext.Provider value={state}>
            <NotificationsDispatchContext.Provider value={dispatch}>
                {children}
            </NotificationsDispatchContext.Provider>
        </NotificationsStateContext.Provider>
    )
};

const useNotificationsState = () => {
    const context = useContext(NotificationsStateContext);
    if (context === undefined) {
        throw new Error("useNotificationsState must be used within a NotificationsProvider")
    }
    return context
};

const useNotificationsDispatch = () => {
    const context = useContext(NotificationsDispatchContext);
    if (context === undefined) {
        throw new Error("useNotificationsDispatch must be used within a NotificationsProvider")
    }
    return context;
};

export {NotificationsProvider, useNotificationsDispatch, useNotificationsState}