import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_SUPPLIER,
    ADD_SUPPLIER_FAIL,
    ADD_SUPPLIER_SUCCESS,
    EXPENSES_TYPE,
    EXPENSES_TYPE_FAIL,
    EXPENSES_TYPE_SUCCESS,
    GET_SUPPLIER,
    GET_SUPPLIERS,
    GET_SUPPLIERS_FAIL,
    GET_SUPPLIERS_SUCCESS,
    UPDATE_SUPPLIER,
    UPDATE_SUPPLIER_FAIL
} from "./SupplierActions";


type State = {
    suppliers: any,
    expenseTypes: any,
    selectedSupplier: any,
    loading: boolean,
    message: string
    error: string
}

const SuppliersStateContext = createContext<State | undefined>(undefined);
const SuppliersDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    loading: false,
    suppliers: [],
    selectedSupplier: {},
    expenseTypes: [],
    message: "",
    error: "",
};
const suppliersReducer = (state: State, action: Action) => {
    switch (action.type) {
        case ADD_SUPPLIER: {
            return {
                ...state,
                error: "",
                loading: true,
            };
        }
        case ADD_SUPPLIER_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ADD_SUPPLIER_FAIL: {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        }
        case EXPENSES_TYPE: {
            return {
                ...state,
                expenseTypes: [],
                loading: true
            }
        }
        case EXPENSES_TYPE_SUCCESS: {
            return {
                ...state,
                expenseTypes: action.payload,
                loading: false
            }
        }
        case EXPENSES_TYPE_FAIL: {
            return {
                ...state,
                error: action.payload,
            }
        }
        case GET_SUPPLIERS: {
            return {
                ...state,
                suppliers: [],
                loading: true
            }
        }
        case GET_SUPPLIERS_SUCCESS: {
            return {
                ...state,
                suppliers: action.payload,
                loading: false
            }
        }
        case GET_SUPPLIERS_FAIL: {
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        }
        case GET_SUPPLIER: {
            return {
                ...state,
                error: "",
                selectedSupplier: action.payload
            }
        }
        case UPDATE_SUPPLIER_FAIL: {
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        }
        case UPDATE_SUPPLIER: {
            return {
                ...state,
                error: ""
            }
        }
        default: {
            return state;
        }
    }
};
const SuppliersProvider = ({children}: ReactChildrenType) => {
    const [state, dispatch] = useReducer(suppliersReducer, initialState);
    return (
        <SuppliersStateContext.Provider value={state}>
            <SuppliersDispatchContext.Provider value={dispatch}>
                {children}
            </SuppliersDispatchContext.Provider>
        </SuppliersStateContext.Provider>
    )
};

const useSuppliersState = () => {
    const context = useContext(SuppliersStateContext);
    if (context === undefined) {
        throw new Error('useSuppliersState must be used within a SuppliersProvider')
    }
    return context
};

const useSuppliersDispatch = () => {
    const context = useContext(SuppliersDispatchContext);
    if (context === undefined) {
        throw new Error(' useSuppliersDispatch must be used within a SuppliersProvider');
    }
    return context
}

export {SuppliersProvider, useSuppliersState, useSuppliersDispatch}