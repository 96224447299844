import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_NEW_EXPENSE_TYPE,
    ADD_NEW_EXPENSE_TYPE_FAIL,
    ADD_NEW_EXPENSE_TYPE_SUCCESS,
    CLEAR_SELECTED_ELEMENTS_ARRAY,
    DELETE_EXPENSE_TYPE,
    DELETE_EXPENSE_TYPE_FAIL,
    DELETE_EXPENSE_TYPE_SUCCESS,
    EDIT_EXPENSE_TYPE,
    EDIT_EXPENSE_TYPE_FAIL,
    EDIT_EXPENSE_TYPE_SUCCESS,
    GET_COUNTER_TYPES,
    GET_COUNTER_TYPES_FAIL,
    GET_COUNTER_TYPES_SUCCESS, GET_EXPENSE_TYPE_BY_ID, GET_EXPENSE_TYPE_BY_ID_FAIL, GET_EXPENSE_TYPE_BY_ID_SUCCESS,
    GET_EXPENSE_TYPES,
    GET_EXPENSE_TYPES_FAIL,
    GET_EXPENSE_TYPES_SUCCESS,
    GET_GROUP_TYPES,
    GET_GROUP_TYPES_SUCCESS,
    GET_PROPERTIES_FOR_AN_EXPENSE_TYPE,
    GET_PROPERTIES_FOR_AN_EXPENSE_TYPE_FAIL,
    GET_PROPERTIES_FOR_AN_EXPENSE_TYPE_SUCCESS,
    SELECT_ELEMENT_ACTION
} from "./ExpenseTypesActions";
import _ from "lodash"
import {act} from "react-dom/test-utils";

type State = {
    expenseTypes: [];
    error: {};
    loading: boolean;
    groupTypes: any[];
    getExpenseTypesLoading: boolean;
    counterTypes: [];
    selectedExpenseTypes: { id: string }[];
    expenseType :any;
    expenseTypeProperties: {
        id: string,
        propertyName: number,
        propertyBlockName: string,
        propertyStairName: string,
        amount: number,
        isEditable: boolean,
        monthOfDistribution: number
    }[];
}

const ExpenseTypesStateContext = createContext<State | undefined>(undefined);
const ExpenseTypesDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    expenseTypes: [],
    groupTypes: [],
    error: {},
    loading: false,
    expenseType:{},
    getExpenseTypesLoading: false,
    counterTypes: [],
    selectedExpenseTypes: [],
    expenseTypeProperties: []
};

const expenseTypesReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_EXPENSE_TYPE_BY_ID:{
            return {
                ...state,
                loading: true,
                expenseType:{}
            }
        }
        case GET_EXPENSE_TYPE_BY_ID_SUCCESS:{
            return {
                ...state,
                loading:false,
                expenseType: action.payload
            }
        }
        case GET_EXPENSE_TYPE_BY_ID_FAIL:{
            return {
                ...state,
                loading:false,
                error: action.payload
            }
        }

        case  GET_GROUP_TYPES: {
            return {
                ...state,
                groupTypes: []
            }
        }
        case GET_GROUP_TYPES_SUCCESS: {
            return {
                ...state,
                groupTypes: action.payload
            }
        }
        case GET_EXPENSE_TYPES: {
            return {
                ...state,
                loading: true,
                getExpenseTypesLoading: true
            }
        }
        case GET_EXPENSE_TYPES_SUCCESS: {
            return {
                ...state,
                loading: false,
                expenseTypes: action.payload,
                getExpenseTypesLoading: false
            }
        }
        case GET_EXPENSE_TYPES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                getExpenseTypesLoading: false
            }
        }
        case ADD_NEW_EXPENSE_TYPE: {
            return {
                ...state,
                loading: true,
            }
        }
        case ADD_NEW_EXPENSE_TYPE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case ADD_NEW_EXPENSE_TYPE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case DELETE_EXPENSE_TYPE: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETE_EXPENSE_TYPE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case DELETE_EXPENSE_TYPE_FAIL: {
            return {
                ...state,
                loading: false
            }
        }
        case GET_COUNTER_TYPES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_COUNTER_TYPES_SUCCESS: {
            return {
                ...state,
                loading: false,
                counterTypes: action.payload
            }
        }
        case GET_COUNTER_TYPES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case EDIT_EXPENSE_TYPE: {
            return {
                ...state,
                loading: true
            }
        }
        case EDIT_EXPENSE_TYPE_SUCCESS: {
            return {
                ...state,
                loading: false,
            }
        }
        case EDIT_EXPENSE_TYPE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case SELECT_ELEMENT_ACTION: {
            const found = _.findIndex(state.selectedExpenseTypes, expense => expense.id === action.payload);
            let selectedExpenseTypes = state.selectedExpenseTypes;
            if (found > -1) {
                selectedExpenseTypes.splice(found, 1)
            } else {
                selectedExpenseTypes = [...selectedExpenseTypes, {id: action.payload}]
            }
            return {
                ...state,
                loading: false,
                selectedExpenseTypes
            }
        }
        case CLEAR_SELECTED_ELEMENTS_ARRAY: {
            return {
                ...state,
                selectedExpenseTypes: []
            }
        }
        case GET_PROPERTIES_FOR_AN_EXPENSE_TYPE: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PROPERTIES_FOR_AN_EXPENSE_TYPE_SUCCESS: {
            return {
                ...state,
                loading: false,
                expenseTypeProperties: action.payload
            }
        }
        case GET_PROPERTIES_FOR_AN_EXPENSE_TYPE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

const ExpenseTypesProvider = ({children}: ReactChildrenType) => {
    const [state, dispatch] = useReducer(expenseTypesReducer, initialState);
    return (
        <ExpenseTypesStateContext.Provider value={state}>
            <ExpenseTypesDispatchContext.Provider value={dispatch}>
                {children}
            </ExpenseTypesDispatchContext.Provider>
        </ExpenseTypesStateContext.Provider>
    )
};

const useExpenseTypesState = () => {
    const context = useContext(ExpenseTypesStateContext);
    if (context === undefined) {
        throw new Error("useExpenseTypesState must be used whitin a ExpenseTypesProvider")
    }
    return context
};
const useExpenseTypesDispatch = () => {
    const context = useContext(ExpenseTypesDispatchContext);
    if (context === undefined) {
        throw new Error("useExpenseTypesDispatch must be used whitin a ExpenseTypesProvider")
    }
    return context
};

export {ExpenseTypesProvider, useExpenseTypesState, useExpenseTypesDispatch}
