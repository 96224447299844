import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_COUNTER,
    ADD_COUNTER_FAIL,
    ADD_COUNTER_INDEX,
    ADD_COUNTER_INDEX_FAIL,
    ADD_COUNTER_INDEX_SUCCESS,
    ADD_COUNTER_SUCCESS,
    ADD_CSV_FILE,
    ADD_NEW_CSV_COUNTER_INDEXES,
    ADD_NEW_CSV_COUNTER_INDEXES_FAIL,
    ADD_NEW_CSV_COUNTER_INDEXES_SUCCESS,
    CLEAR_CSV, CLEAR_SELECTED_COUNTER,
    DELETE_COUNTER,
    DELETE_COUNTER_FAIL,
    DELETE_COUNTER_INDEX,
    DELETE_COUNTER_INDEX_FAIL,
    DELETE_COUNTER_INDEX_SUCCESS,
    DELETE_COUNTER_SUCCESS,
    GET_ALL_COUNTERS,
    GET_ALL_COUNTERS_FAIL,
    GET_ALL_COUNTERS_PARENTS, GET_ALL_COUNTERS_PARENTS_FAIL,
    GET_ALL_COUNTERS_PARENTS_SUCCESS,
    GET_ALL_COUNTERS_SUCCESS,
    GET_COUNTER_BY_ID,
    GET_COUNTER_INDEX_BY_ID,
    GET_COUNTER_TYPE,
    GET_COUNTERS_INDEXES,
    GET_COUNTERS_INDEXES_TYPES,
    GET_COUNTERS_INDEXES_TYPES_FAIL,
    GET_COUNTERS_INDEXES_TYPES_SUCCESS,
    REMOVE_CSV_FILE,
    UPDATE_COUNTER,
    UPDATE_COUNTER_FAIL,
    UPDATE_COUNTER_SUCCESS
} from "./CountersActions";


type State = {
    counterTypes: any
    loading: boolean,
    message: string
    error: string,
    csvFiles: File[];
    countersIndexes: any
    selectedCounter: any
    selectedCounterIndex: any
    countersIndexesTypes: any
    fetchCounters:any[],
    parentsCounter:any[],
}

const CountersStateContext = createContext<State | undefined>(undefined);
const CountersDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    loading: false,
    selectedCounter: {},
    counterTypes: [],
    countersIndexes: [],
    countersIndexesTypes: [],
    csvFiles: [],
    message: "",
    error: "",
    selectedCounterIndex: {},
    fetchCounters:[],
    parentsCounter:[]
};
const countersReducer = (state: State, action: Action) => {
    switch (action.type) {
        case  CLEAR_SELECTED_COUNTER:{
            return {
                ...state,
                error: "",
                selectedCounter:action.payload
            }
        }
        case GET_ALL_COUNTERS_PARENTS:{
            return {
                ...state,
                loading: true,
                parentsCounter:[],
                error:""
            }
        }
        case GET_ALL_COUNTERS_PARENTS_SUCCESS:{
            return {
                ...state,
                loading: false,
                parentsCounter:action.payload
            }
        }
        case GET_ALL_COUNTERS_PARENTS_FAIL:{
            return {
                ...state,
                loading: false,
                parentsCounter:[],
                error:action.payload
            }
        }
        case GET_ALL_COUNTERS:{
            return {
                ...state,
                loading: true,
            }
        }
        case GET_ALL_COUNTERS_SUCCESS:{
            return {
                ...state,
                fetchCounters:action.payload,
                loading: false
            }
        }
        case GET_ALL_COUNTERS_FAIL:{
            return {
                ...state,
                error:action.payload,
                loading: false
            }
        }
        case GET_COUNTERS_INDEXES_TYPES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_COUNTERS_INDEXES_TYPES_SUCCESS: {
            return {
                ...state,
                loading: false,
                countersIndexesTypes: action.payload
            }
        }
        case GET_COUNTERS_INDEXES_TYPES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case ADD_NEW_CSV_COUNTER_INDEXES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload.message
            }
        }
        case ADD_NEW_CSV_COUNTER_INDEXES_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: ""
            }
        }
        case ADD_NEW_CSV_COUNTER_INDEXES: {
            return {
                ...state,
                loading: true,
                error: ""
            }
        }
        case CLEAR_CSV: {
            return {
                ...state,
                csvFiles: [],
                error: ""
            }
        }
        case ADD_CSV_FILE: {
            return {
                ...state,
                csvFiles: [...state.csvFiles, action.payload]
            }
        }
        case REMOVE_CSV_FILE: {
            const newInvoiceFiles = state.csvFiles;
            newInvoiceFiles.splice(action.payload, 1);
            return {
                ...state,
                csvFiles: newInvoiceFiles
            }
        }
        case GET_COUNTERS_INDEXES: {
            return {
                ...state,
                countersIndexes: action.payload
            }
        }
        case GET_COUNTER_BY_ID : {
            return {
                ...state,
                selectedCounter: action.payload
            }
        }
        case GET_COUNTER_INDEX_BY_ID: {
            return {
                ...state,
                selectedCounterIndex: action.payload
            }
        }
        case GET_COUNTER_TYPE: {
            return {
                ...state,
                counterTypes: action.payload,
                error: ""
            }
        }
        case ADD_COUNTER: {
            return {
                ...state,
                loading: true,
                error: ""
            };
        }
        case ADD_COUNTER_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: ""
            };
        }
        case ADD_COUNTER_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        }
        case DELETE_COUNTER: {
            return {
                ...state,
                loading: true,
                error: ""
            }
        }
        case DELETE_COUNTER_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: ""
            }
        }
        case DELETE_COUNTER_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload.message,

            }
        }

        case DELETE_COUNTER_INDEX: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETE_COUNTER_INDEX_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case DELETE_COUNTER_INDEX_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,

            }
        }
        case UPDATE_COUNTER: {
            return {
                ...state,
                loading: true,
                error: ""
            };
        }
        case UPDATE_COUNTER_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: ""
            };
        }
        case UPDATE_COUNTER_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        }
        case ADD_COUNTER_INDEX: {
            return {
                ...state,
                loading: true,
                error: ""
            };
        }
        case ADD_COUNTER_INDEX_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: ""
            };
        }
        case ADD_COUNTER_INDEX_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        }
        default: {
            return state;
        }
    }
};
const CountersProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(countersReducer, initialState);
    return (
        <CountersStateContext.Provider value={state}>
            <CountersDispatchContext.Provider value={dispatch}>
                {children}
            </CountersDispatchContext.Provider>
        </CountersStateContext.Provider>
    )
};

const useCountersState = () => {
    const context = useContext(CountersStateContext);
    if (context === undefined) {
        throw new Error('useCountersState must be used within a CountersProvider')
    }
    return context
};

const useCountersDispatch = () => {
    const context = useContext(CountersDispatchContext);
    if (context === undefined) {
        throw new Error(' useCountersDispatch must be used within a CountersProvider');
    }
    return context
}

export {CountersProvider, useCountersState, useCountersDispatch}