import {Dispatch} from "../../constants/globalTypes";
import {setLocalStorage} from "../../services/auth";
import {History} from 'history'
import {authFetch, login, logout, unauthenticatedFetch} from "../../helpers/createAuthProvider";
import {ACCEPT_INVITE_FAIL, ACCEPT_INVITE_SUCCESS} from "../invites/InvitesActions";
import {RegisterInitialValuesProps} from "./register/RegisterComponent";

export const LOG_IN = 'log_in';
export const LOG_IN_SUCCESS = 'log_in_success';
export const LOG_IN_FAIL = 'log_in_fail';

interface InviteProps {
    isInvite: boolean,
    inviteId: string,
    userId: string
}

interface LoginProps {
    dispatch: Dispatch,
    values: {
        email: string,
        password: string
    },
    history: History,
    invite: InviteProps
}

export const logInAction = (
    {
        dispatch,
        values,
        history,
        invite
    }: LoginProps) => {
    dispatch({type: LOG_IN, payload: {}});

    let formData = new FormData();
    for (let key in values) {
        // @ts-ignore
        formData.append(key, values[key]);
    }
    unauthenticatedFetch('/auth/login', {
        method: "POST",
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
            return response.text().then(error => {
                dispatch({
                    type: LOG_IN_FAIL,
                    payload: {
                        message: "*Incorrect email or password",
                    }
                })
            })
        })
        .then((data) => {
            dispatch({
                type: LOG_IN_SUCCESS,
                payload: data
            });
            let authObject = {
                token: data.auth_token,
                expirationDate: data.expire,
                refreshToken: data.refresh_token
            };
            localStorage.setItem("email", values.email);
            localStorage.setItem("path", "home");

            login(authObject);
            // setLocalStorage(data.token, data.expire);


            if (invite.isInvite) {
                //is an invite link
                if (invite.userId === "") {
                    //accept invite for an existing account
                    acceptLoggedInvite({inviteId: invite.inviteId, userId: "", dispatch, history})
                } else {
                    //accept invite for a just made account
                    acceptUnloggedInvite({inviteId: invite.inviteId, userId: invite.userId, dispatch, history})
                }
            } else {
                history.push("/");
            }
        })
        .catch((error) => {
            console.error("Error:", error);
            dispatch({
                type: LOG_IN_FAIL,
                payload: error
            });
        });
};

interface registerProps {
    dispatch: Dispatch,
    values: RegisterInitialValuesProps,
    history: History,
    isInvite: boolean,
    inviteId: string
}

export const REGISTER_ACCOUNT = 'register_account';
export const REGISTER_ACCOUNT_SUCCESS = 'register_account_success';
export const REGISTER_ACCOUNT_FAIL = 'register_account_fail';
export const registerAction = ({
                                   dispatch,
                                   values,
                                   history,
                                   isInvite,
                                   inviteId
                               }: registerProps) => {
    dispatch({type: REGISTER_ACCOUNT, payload: {}});
    // @ts-ignore
    unauthenticatedFetch('/users/', {
        method: 'POST',
        body: JSON.stringify(values)
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                response.text().then(error => {
                    dispatch({
                        type: REGISTER_ACCOUNT_FAIL,
                        payload: error
                    });
                });
                throw new Error("")
            }
        })
        .then(data => {
            dispatch({
                type: REGISTER_ACCOUNT_SUCCESS,
                payload: data
            });
            if (isInvite) {
                history.push(`/auth/login?inviteId=${inviteId}&userId=${data.userId}`);
            } else {
                history.push('/auth/login');
            }
        })
        .catch(error => {
            console.log(error)
        })
};

export const LOGOUT = 'logout';
export const logoutAction = ({dispatch, history}: { dispatch: Dispatch, history: History }) => {
    dispatch({type: LOGOUT, payload: {}});
    logout(() => {
        history.push('/auth/login');
    });

};

export const ACCEPT_INVITE = "accept_invite";
type AcceptInviteProps = {
    dispatch: Dispatch,
    history: History,
    inviteId: string,
    userId: string
};
export const acceptLoggedInvite = ({dispatch, history, inviteId}: AcceptInviteProps) => {
    dispatch({
        type: ACCEPT_INVITE,
        payload: {}
    });
    authFetch(`/users/invites/acceptInvite/${inviteId}`, {
        method: "PUT"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: ACCEPT_INVITE_SUCCESS,
                payload: data
            });
            history.push('/');
        })
        .catch(error => {
            dispatch({
                type: ACCEPT_INVITE_FAIL,
                payload: error
            });
        });
};
export const acceptUnloggedInvite = ({dispatch, history, inviteId, userId}: AcceptInviteProps) => {
    dispatch({
        type: ACCEPT_INVITE,
        payload: {}
    });
    authFetch(`/users/invites/editInvite/${inviteId}/${userId}`, {
        method: "PUT"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: ACCEPT_INVITE_SUCCESS,
                payload: data
            });
            acceptLoggedInvite({inviteId: inviteId, userId: "", dispatch, history})
        })
        .catch(error => {
            dispatch({
                type: ACCEPT_INVITE_FAIL,
                payload: error
            });
        });
};

