import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from '../../helpers/createAuthProvider';
import {History} from "history";
import _ from "lodash";
import moment from "moment";
import {API_URL} from "../../constants/globalConstants";


export const ADD_NEW_CSV_PROPERTIES = "add_new_csv_counter_indexes"
export const ADD_NEW_CSV_PROPERTIES_SUCCESS = "add_new_csv_counter_indexes_success"
export const ADD_NEW_CSV_PROPERTIES_FAIL = "add_new_csv_counter_indexes_fail"

export const addNewCSVProperties = ({
                                        dispatch,
                                        files,
                                        history,
                                        date,
                                        associationId,
                                        groupId
                                    }: { dispatch: Dispatch, files: File[], associationId: string, groupId: string, history: any, date: any }) => {
    dispatch({type: ADD_NEW_CSV_PROPERTIES, payload: {}});
    let data = new FormData()
    _.forEach(files, file => {
        data.append('files', file)
    });
    data.append('groupId', groupId)
    data.append('associationId', associationId)
    data.append('date', date)
    let responseStats: number;

    authFetch(`/properties//CSVProperties`, {
        method: "POST",
        body: data
    })
        .then((response) => {
            responseStats = response.status
            return response.json()
        }).then((data) => {
        if (responseStats === 200) {
            dispatch({
                type: ADD_NEW_CSV_PROPERTIES_SUCCESS,
                payload: data
            });
            history.goBack();
        } else {
            dispatch({
                type: ADD_NEW_CSV_PROPERTIES_FAIL,
                payload: data.message
            })
        }
    })
}
export const ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS = "add_new_csv_counter_indexes_initial_amounts"
export const ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS_SUCCESS = "add_new_csv_counter_indexes_initial_amounts_success"
export const ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS_FAIL = "add_new_csv_counter_indexes_initial_amounts_fail"


export const addNewCSVPropertiesInitialAmounts = ({
                                                      dispatch,
                                                      files,
                                                      history,
                                                  }: { dispatch: Dispatch, files: File[], history: any }) => {
    dispatch({type: ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS, payload: {}});
    let data = new FormData()
    _.forEach(files, file => {
        data.append('files', file)
    });


    authFetch(`/properties//initialAmounts`, {
        method: "POST",
        body: data
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS_SUCCESS,
                    payload: data
                });
                history.goBack();
            }
        }).catch(error => {
        console.log("error", error)
    })
}
export const ADD_NEW_CSV_PROPERTIES_PARKINGS = "add_new_csv_parkings"
export const ADD_NEW_CSV_PROPERTIES_PARKINGS_SUCCESS = "add_new_csv_parkings_success"
export const ADD_NEW_CSV_PROPERTIES_PARKINGS_FAIL = "add_new_csv_parkings_fail"


export const addNewCSVPropertiesParkings = ({
                                                dispatch,
                                                files,
                                                history,
                                            }: { dispatch: Dispatch, files: File[], history: any }) => {
    dispatch({type: ADD_NEW_CSV_PROPERTIES_PARKINGS, payload: {}});
    let data = new FormData()
    _.forEach(files, file => {
        data.append('files', file)
    });


    authFetch(`/properties//parkings`, {
        method: "POST",
        body: data
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: ADD_NEW_CSV_PROPERTIES_PARKINGS_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: ADD_NEW_CSV_PROPERTIES_PARKINGS_SUCCESS,
                    payload: data
                });
                history.goBack();
            }
        }).catch(error => {
        console.log("error", error)
    })
}

export const ADD_NEW_CSV_PROPERTIES_TRANSACTIONS = "add_new_csv_transactions"
export const ADD_NEW_CSV_PROPERTIES_TRANSACTIONS_SUCCESS = "add_new_csv_transactions_success"
export const ADD_NEW_CSV_PROPERTIES_TRANSACTIONS_FAIL = "add_new_csv_transactions_fail"


export const addNewCSVPropertiesTransactions = ({
                                                    dispatch,
                                                    files,
                                                    associations,
                                                    history,
                                                }: { dispatch: Dispatch, files: File[], history: any, associations: string }) => {
    dispatch({type: ADD_NEW_CSV_PROPERTIES_TRANSACTIONS, payload: {}});
    let data = new FormData()
    _.forEach(files, file => {
        data.append('files', file)
    });


    authFetch(`/properties/${associations}/transactions`, {
        method: "POST",
        body: data
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: ADD_NEW_CSV_PROPERTIES_TRANSACTIONS_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: ADD_NEW_CSV_PROPERTIES_TRANSACTIONS_SUCCESS,
                    payload: data
                });
                history.goBack();
            }
        }).catch(error => {
        console.log("error", error)
    })
}

export const ADD_CSV_FILE = 'add_csv_file';
const addCSVFile = ({
                        dispatch,
                        file
                    }: {
                        dispatch: Dispatch,
                        file: File
                    }
) => {
    dispatch({
        type: ADD_CSV_FILE,
        payload: file
    })
};

export const REMOVE_CSV_FILE = 'remove_csv_file';
const removeCSVFile = ({
                           dispatch,
                           fileIndex
                       }: {
                           dispatch: Dispatch,
                           fileIndex: number
                       }
) => {
    dispatch({
        type: REMOVE_CSV_FILE,
        payload: fileIndex
    })
};
export const CLEAR_CSV = "clear_csv"
const clearCSVFiles = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({type: CLEAR_CSV, payload: {}})
}

export const UPDATE_PROPERTY = "update_property";
export const UPDATE_PROPERTY_SUCCESS = "update_property_success";
export const UPDATE_PROPERTY_FAIL = "update_property_fail";
const updateProperty = ({
                            associationId,
                            propertyId,
                            values,
                            dispatch,
                            history,
                            redirectLink
                        }: {
    associationId: string;
    propertyId: string;
    values: any;
    dispatch: Dispatch;
    history: History;
    redirectLink: string
}) => {
    dispatch({
        type: UPDATE_PROPERTY,
        payload: {},
    });
    if (values.initialAmounts != null) {
        for (let index = 0; index < values.initialAmounts.length; index++) {
            values.initialAmounts[index].month = parseInt(values.initialAmounts[index].month)
            values.initialAmounts[index].year = parseInt(values.initialAmounts[index].year)
        }
    }

    if (!_.isEmpty(values.counters)) {
        for (let index = 0; index < values.counters.length; index++) {
            values.counters[index].date = moment(values.counters[index].date, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ssZ")
        }
    }

    authFetch(`/properties/${associationId}/property/${propertyId}`, {
        method: "PUT",
        body: JSON.stringify(values)
    }).then((response) => response.json())
        .then((data) => {
                dispatch({
                    type: UPDATE_PROPERTY_SUCCESS,
                    payload: data,
                });
                getPropertyById({
                    dispatch: dispatch,
                    propertyId: propertyId,
                    associationId: associationId
                })
                history.push(redirectLink);
            }
        ).catch((error) => {
        dispatch({
            type: UPDATE_PROPERTY_FAIL,
            payload: error,
        });
    });
}


export const GET_PROPERTIES = "get_properties";
export const GET_PROPERTIES_SUCCESS = "get_properties_success";
export const GET_PROPERTIES_FAIL = "get_properties_fail";
const getProperties = (
    {dispatch}: { dispatch: Dispatch },
    id: string,
    expenseTypeId?: string
) => {

    dispatch({type: GET_PROPERTIES, payload: {}});
    let url = `/properties/${id}/`;
    if (expenseTypeId) {
        url = `${url}?filterByExpenseTypeBelonging=${expenseTypeId}`
    }
    authFetch(url, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_PROPERTIES_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_PROPERTIES_FAIL,
                payload: error
            })
        });
};

export const GET_INITIAL_AMOUNTS = "get_initial_amounts";
export const GET_INITIAL_AMOUNTS_SUCCESS = "get_initial_amounts_success";
export const GET_INITIAL_AMOUNTS_FAIL = "get_initial_amounts_fail";
const getInitialAmounts = (
    {dispatch}: { dispatch: Dispatch },
    associationId: string,
    propertyId: string
) => {
    dispatch({type: GET_INITIAL_AMOUNTS, payload: []});
    authFetch(`/properties/${associationId}/initialAmounts/${propertyId}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_INITIAL_AMOUNTS_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_INITIAL_AMOUNTS_FAIL,
                payload: error
            })
        });
}


export const SET_PROPERTY = "set_property";
const setProperty = (
    {dispatch}: { dispatch: Dispatch }, property: any) => {
    localStorage.setItem("propertyId", property.id);
    dispatch({
        type: SET_PROPERTY,
        payload: property
    })
}


export const GET_SELECTED_PROPERTY = "get_selected_property";
export const GET_SELECTED_PROPERTY_SUCCESS = "get_selected_property_success";
export const GET_SELECTED_PROPERTY_FAIL = "get_selected_property_fail";

const getSelectedProperty = (
    {dispatch}: { dispatch: Dispatch }, propertyId: string, associationId: string, {globalDispatch}: { globalDispatch?: Dispatch }) => {
    dispatch({
        type: GET_SELECTED_PROPERTY,
        payload: {}
    })
    authFetch(`/properties/${associationId}/property/${propertyId}`, {
        method: 'GET'
    }).then(response => {
        if (!response.ok) {
            return response.text().then(error => {
                dispatch({type: GET_SELECTED_PROPERTY_FAIL, payload: JSON.parse(error)})
            })
        }
        return response.json()
    }).then(data => {
        dispatch({type: GET_SELECTED_PROPERTY_SUCCESS, payload: data})
        dispatch({
            type: SET_PROPERTY,
            payload: data
        });
    }).then(error => {
        console.log(error)
    })
}

export const GET_PROPERTY_COSTS_STATUS = 'get_property_costs_status';
export const GET_PROPERTY_COSTS_STATUS_SUCCESS = 'get_property_costs_success';
export const GET_PROPERTY_COSTS_STATUS_FAIL = 'get_property_costs_fail';
export const getPropertyCostsStatus = ({
                                           dispatch,
                                           associationId,
                                           propertyId,
                                           year
                                       }:
                                           {
                                               dispatch: Dispatch,
                                               associationId: string,
                                               propertyId: string,
                                               year: string
                                           }
) => {
    dispatch({
        type: GET_PROPERTY_COSTS_STATUS,
        payload: {}
    });
    authFetch(`/costs/${associationId}/distributions/${propertyId}?year=${year}`, {
        method: "GET"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: GET_PROPERTY_COSTS_STATUS_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_PROPERTY_COSTS_STATUS_FAIL,
                payload: error
            })
        })
};

export const GET_PROPERTY_RECORDED_YEARS = 'get_property_recorded_years';
export const GET_PROPERTY_RECORDED_YEARS_SUCCESS = 'get_property_recorded_years_success';
export const GET_PROPERTY_RECORDED_YEARS_FAIL = 'get_property_recorded_years_fail';
export const getPropertyRecordedYears = ({
                                             dispatch,
                                             associationId,
                                             propertyId
                                         }: {
                                             dispatch: Dispatch,
                                             associationId: string,
                                             propertyId: string
                                         }
) => {
    dispatch({
        type: GET_PROPERTY_RECORDED_YEARS,
        payload: {}
    });
    authFetch(`/costs/${associationId}/distributions/${propertyId}/registered-years`, {
        method: "GET"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: GET_PROPERTY_RECORDED_YEARS_SUCCESS,
                payload: data
            })
        })
        .then(error => {
            dispatch({
                type: GET_PROPERTY_RECORDED_YEARS_FAIL,
                payload: error
            })
        })
};

export const CHANGE_SELECTED_YEAR = 'change_selected_year';
export const changeSelectedYear = ({dispatch, year}: { dispatch: Dispatch, year: string }) => {
    dispatch({
        type: CHANGE_SELECTED_YEAR,
        payload: year
    })
};

export const GET_PROPERTY = "get_property";
export const GET_PROPERTY_SUCCESS = "get_property_success";
export const GET_PROPERTY_FAIL = "get_property_fail";
const getPropertyById = (
    {dispatch, propertyId, associationId}: { dispatch: Dispatch, propertyId: string, associationId: string }) => {
    dispatch({
        type: GET_SELECTED_PROPERTY,
        payload: {}
    })
    authFetch(`/properties/${associationId}/property/${propertyId}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_PROPERTY_SUCCESS,
                payload: data
            });
        }).catch((error) => {
        dispatch({
            type: GET_PROPERTY_FAIL,
            payload: error,
        });
    });
}

export const SORT_PROPERTY_BY_NAME_ASCENDED = "sort_property_by_name_ascended";
export const SORT_PROPERTY_BY_NAME_DESCENDED = "sort_property_by_name_descended";
const sortPropertyByName = (
    {dispatch}: { dispatch: Dispatch }, idAssociation: string, type: string) => {
    if (type === "ascended") {
        authFetch(`/properties/${idAssociation}/sort/ascended`, {
            method: 'GET'
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch({
                    type: SORT_PROPERTY_BY_NAME_DESCENDED,
                    payload: data
                });
            })
    } else {
        authFetch(`/properties/${idAssociation}/sort/descended`, {
            method: 'GET'
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch({
                    type: SORT_PROPERTY_BY_NAME_ASCENDED,
                    payload: data
                });
            })
    }
}


export const ADD_PROPERTY = "add_property";
export const ADD_PROPERTY_SUCCESS = "add_property_success";
export const ADD_PROPERTY_FAIL = "add_property_fail";

const addProperty = ({
                         values,
                         dispatch,
                         funds,
                         id,
                         stairId,
                         callback
                     }: {
                         values: any;
                         dispatch: Dispatch;
                         funds: { fundId: string }[],
                         id: string,
                         callback: () => void;
                         stairId: string;
                     },
) => {
    dispatch({
        type: ADD_PROPERTY,
        payload: {},
    });

    values.funds = funds;
    authFetch(`/properties/${id}/stair/${stairId}`, {
        method: "POST",
        body: JSON.stringify(values),
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: ADD_PROPERTY_FAIL,
                        payload: JSON.parse(error),
                    });
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: ADD_PROPERTY_SUCCESS,
                    payload: data,
                });
                callback()
            }
        })
        .catch(error => {
            console.log("error add property", error)
        })
};


export const DELETE_PROPERTY = "delete_property";
export const DELETE_PROPERTY_SUCCESS = "delete_property_success";
export const DELETE_PROPERTY_FAIL = "delete_property_fail";

const deleteProperty = (
    {dispatch}: { dispatch: Dispatch }, idAssociation: string, idProperty: string) => {
    dispatch({type: DELETE_PROPERTY, payload: {}});
    authFetch(`/properties/${idAssociation}/property/${idProperty}`, {
        method: 'DELETE'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: DELETE_PROPERTY_SUCCESS,
                payload: data
            });
            getProperties({dispatch}, idAssociation);
        })
        .catch((error) => {
            dispatch({
                type: DELETE_PROPERTY_FAIL,
                payload: error
            });
            getProperties({dispatch}, idAssociation);
        });
};

export const SET_SELECTED_MONTH = 'set_selected_month'
export const setSelectedMonth = ({dispatch, month}: { dispatch: Dispatch, month: string }) => {
    dispatch({
        type: SET_SELECTED_MONTH,
        payload: month
    })
};


export const GET_PARKING_TYPES = "get_parking_types";
export const GET_PARKING_TYPES_SUCCESS = "get_parking_types_success";
export const GET_PARKING_TYPES_FAIL = "get_parking_types_fail";
export const getParkingTypes = ({dispatch, association}: { dispatch: Dispatch, association: string }) => {
    dispatch({type: GET_PARKING_TYPES, payload: {}})
    authFetch(`/properties/${association}/parkingTypes`, {
        method: "GET"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.text().then(error => {
                    dispatch({
                        type: GET_PARKING_TYPES_FAIL,
                        payload: error
                    })
                })
            }
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_PARKING_TYPES_SUCCESS,
                    payload: data
                })
            }
        })
}

export const GET_COSTS_DETAILS_BY_MONTH = 'get_costs_details_by_month';
export const GET_COSTS_DETAILS_BY_MONTH_SUCCESS = 'get_costs_details_by_month_success';
export const GET_COSTS_DETAILS_BY_MONTH_FAIL = 'get_costs_details_by_month_fail';
export const getCostsDetailsByMonth = ({
                                           dispatch,
                                           associationId,
                                           propertyId,
                                           year,
                                           month
                                       }:
                                           {
                                               dispatch: Dispatch,
                                               associationId: string,
                                               propertyId: string,
                                               year: string,
                                               month: string
                                           }
) => {
    dispatch({
        type: GET_COSTS_DETAILS_BY_MONTH,
        payload: {}
    });
    authFetch(`/costs/${associationId}/distributions/${propertyId}?year=${year}&month=${month}`, {
        method: "GET"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.text().then(error => {
                    dispatch({
                        type: GET_COSTS_DETAILS_BY_MONTH_FAIL,
                        payload: error
                    })
                })
            }
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_COSTS_DETAILS_BY_MONTH_SUCCESS,
                    payload: data
                })
            }
        })
};

export const EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT = 'edit_property_expense_type_amount';
export const EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT_SUCCESS = 'edit_property_expense_type_amount_success';
export const EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT_FAIL = 'edit_property_expense_type_amount_fail';
export const editPropertyExpenseTypeAmount = ({
                                                  values,
                                                  dispatch,
                                                  expenseTypeId,
                                                  propertyId,
                                                  associationId,
                                                  callback
                                              }: {
                                                  values: {
                                                      month: string,
                                                      year: string,
                                                      amount: number
                                                  },
                                                  dispatch: Dispatch,
                                                  expenseTypeId: string,
                                                  propertyId: string,
                                                  associationId: string,
                                                  callback?: () => void
                                              }
) => {
    dispatch({
        type: EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT,
        payload: {}
    });
    authFetch(`/costs/${associationId}/monthlyAmount/${expenseTypeId}/${propertyId}`, {
        method: "PUT",
        body: JSON.stringify(values)
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT_SUCCESS,
                    payload: data
                });
                if (callback) {
                    callback()
                }
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const GET_PROPERTY_BY_CUP = "get_property_by_cup";
export const GET_PROPERTY_BY_CUP_SUCCESS = "get_property_by_cup_success";
export const GET_PROPERTY_BY_CUP_FAIL = "get_property_by_cup_fail";
export const getPropertyByCup = ({
                                     associationId,
                                     cup,
                                     dispatch,
                                     callback
                                 }: {

                                     associationId: string,
                                     cup: string,
                                     callback: (gotError: boolean) => boolean,
                                     dispatch: Dispatch
                                 }
) => {
    dispatch({
        type: GET_PROPERTY_BY_CUP,
        payload: {}
    });
    authFetch(`/properties/${associationId}/cup/${cup}`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_PROPERTY_BY_CUP_FAIL,
                        payload: JSON.parse(error)
                    });
                    callback(true)
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_PROPERTY_BY_CUP_SUCCESS,
                    payload: data
                });
                callback(false)
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const getPropertyByCupAndPropId = ({
                                              associationId,
                                              cup,
                                              propId,
                                              dispatch,
                                              callback
                                          }: {

                                              associationId: string,
                                              cup: string,
                                              propId: string,
                                              callback: (gotError: boolean) => boolean,
                                              dispatch: Dispatch
                                          }
) => {
    dispatch({
        type: GET_PROPERTY_BY_CUP,
        payload: {}
    });
    authFetch(`/properties/${associationId}/property/${propId}/cup/${cup}`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_PROPERTY_BY_CUP_FAIL,
                        payload: JSON.parse(error)
                    });
                    callback(true)
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_PROPERTY_BY_CUP_SUCCESS,
                    payload: data
                });
                callback(false)
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const CREATE_TEMPLATE_ADD_PARKINGS = "create_template_add_parkings";
export const CREATE_TEMPLATE_ADD_PARKINGS_SUCCESS = "create_template_add_parkings_success";
export const CREATE_TEMPLATE_ADD_PARKINGS_FAIL = "create_template_add_parkings_fail";


export const createTemplateAddParkings = ({dispatch, associationId}: { dispatch: Dispatch, associationId: string }) => {
    dispatch({type: CREATE_TEMPLATE_ADD_PARKINGS, payload: {}});
    authFetch(`/properties/${associationId}/createTemplateAddParkings`, {
        method: 'POST'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: CREATE_TEMPLATE_ADD_PARKINGS_FAIL,
                payload: data
            });
            let path = "";
            if (API_URL === "https://api.test.proadminserv.ro") {
                path = "https://test.proadminserv.ro"
            } else {
                path = "https://manager.staging.proadminserv.ro"
            }
            window.open(`${path}/files/templateAddParking.csv`)
        })
        .catch((error) => {
            dispatch({
                type: CREATE_TEMPLATE_ADD_PARKINGS_SUCCESS,
                payload: error
            })
        });
};
export const CREATE_TEMPLATE_ADD_TRANSACTIONS = "create_template_add_transactions";
export const CREATE_TEMPLATE_ADD_TRANSACTIONS_SUCCESS = "create_template_add_transactions_success";
export const CREATE_TEMPLATE_ADD_TRANSACTIONS_FAIL = "create_template_add_transactions_fail";


export const createTemplateAddTransactions = ({
                                                  dispatch,
                                                  associationId,
                                                  groupId
                                              }: { dispatch: Dispatch, associationId: string, groupId: string }) => {
    dispatch({type: CREATE_TEMPLATE_ADD_TRANSACTIONS, payload: {}});
    authFetch(`/properties/${associationId}/group/${groupId}/createTemplateAddTransactions`, {
        method: 'POST'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: CREATE_TEMPLATE_ADD_TRANSACTIONS_FAIL,
                payload: data
            });
            let path = "";
            if (API_URL === "https://api.test.proadminserv.ro") {
                path = "https://test.proadminserv.ro"
            } else {
                path = "https://manager.staging.proadminserv.ro"
            }
            window.open(`${path}/files/templateAddTransactions-${groupId}.csv`)
        })
        .catch((error) => {
            dispatch({
                type: CREATE_TEMPLATE_ADD_TRANSACTIONS_SUCCESS,
                payload: error
            })
        });
};

export {
    getProperties,
    addProperty,
    deleteProperty,
    sortPropertyByName,
    setProperty,
    updateProperty,
    getSelectedProperty,
    getPropertyById,
    clearCSVFiles,
    addCSVFile,
    removeCSVFile,
    getInitialAmounts
}