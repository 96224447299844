import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_REGISTERED_MONTH,
    ADD_REGISTERED_MONTH_FAIL,
    ADD_REGISTERED_MONTH_SUCCESS,
    GET_AVAILABLE_MONTHS_BY_YEAR,
    GET_AVAILABLE_MONTHS_BY_YEAR_FAIL,
    GET_AVAILABLE_MONTHS_BY_YEAR_SUCCESS,
    GET_REGISTERED_MONTHS,
    GET_REGISTERED_MONTHS_FAIL,
    GET_REGISTERED_MONTHS_SUCCESS,
    GET_REGISTERED_YEARS,
    GET_REGISTERED_YEARS_FAIL,
    GET_REGISTERED_YEARS_SUCCESS, SET_SELECTED_MONTH,
    SET_SELECTED_YEAR,
    START_MONTH,
    START_MONTH_FAIL,
    START_MONTH_SUCCESS,
    STOP_MONTH,
    STOP_MONTH_FAIL,
    STOP_MONTH_SUCCESS
} from "./RegisteredMonthsActions";

type State = {
    loading: boolean;
    registeredMonths: [];
    error: {};
    registeredYears: [];
    selectedYear: string;
    selectedMonth: string;
    availableMonths: [];
    mustGetRegisteredMonths: boolean;
    isMonthOpened: boolean;
}

const RegisteredMonthsStateContext = createContext<State | undefined>(undefined);
const RegisteredMonthsDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    loading: false,
    registeredMonths: [],
    error: {},
    registeredYears: [],
    selectedYear: "",
    selectedMonth:"",
    availableMonths: [],
    mustGetRegisteredMonths: false,
    isMonthOpened: false
};

const registeredMonthsReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_REGISTERED_MONTHS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_REGISTERED_MONTHS_SUCCESS: {
            let isMonthOpened: boolean = false;
            action.payload && action.payload.forEach((month: { status: string }) => {
                if (month.status === "opened") {
                    isMonthOpened = true
                }
            });
            return {
                ...state,
                loading: false,
                registeredMonths: action.payload,
                mustGetRegisteredMonths: false,
                isMonthOpened
            }
        }
        case GET_REGISTERED_MONTHS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                mustGetRegisteredMonths: false
            }
        }
        case GET_REGISTERED_YEARS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_REGISTERED_YEARS_SUCCESS: {
            let selectedYear: string = "";
            action.payload && action.payload.forEach((year: {
                year: string;
                currentYear: boolean
            }) => {
                if (year.currentYear) {
                    selectedYear = year.year
                }
            });

            return {
                ...state,
                loading: false,
                registeredYears: action.payload,
                selectedYear
            }
        }
        case GET_REGISTERED_YEARS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case SET_SELECTED_YEAR: {
            return {
                ...state,
                selectedYear: action.payload
            }
        }
        case SET_SELECTED_MONTH:{
            return {
                ...state,
                selectedMonth:action.payload
            }
        }
        case GET_AVAILABLE_MONTHS_BY_YEAR: {
            return {
                ...state,
                loading: true,
                error: {}
            }
        }
        case GET_AVAILABLE_MONTHS_BY_YEAR_SUCCESS: {
            return {
                ...state,
                loading: false,
                availableMonths: action.payload
            }
        }
        case GET_AVAILABLE_MONTHS_BY_YEAR_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case ADD_REGISTERED_MONTH: {
            return {
                ...state,
                loading: true,
                error: {}
            }
        }
        case ADD_REGISTERED_MONTH_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case ADD_REGISTERED_MONTH_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case START_MONTH: {
            return {
                ...state,
                loading: true
            }
        }
        case START_MONTH_SUCCESS: {
            return {
                ...state,
                loading: false,
                mustGetRegisteredMonths: true
            }
        }
        case START_MONTH_FAIL: {
            return {
                ...state,
                loading: false
            }
        }
        case STOP_MONTH: {
            return {
                ...state,
                loading: true
            }
        }
        case STOP_MONTH_SUCCESS: {
            return {
                ...state,
                loading: false,
                mustGetRegisteredMonths: true
            }
        }
        case STOP_MONTH_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return state
        }
    }
};

const RegisteredMonthsProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(registeredMonthsReducer, initialState);
    return (
        <RegisteredMonthsStateContext.Provider value={state}>
            <RegisteredMonthsDispatchContext.Provider value={dispatch}>
                {children}
            </RegisteredMonthsDispatchContext.Provider>
        </RegisteredMonthsStateContext.Provider>
    )
};

const useRegisteredMonthsState = () => {
    const context = useContext(RegisteredMonthsStateContext);
    if (context === undefined) {
        throw new Error("useRegisteredMonths must be used within RegisteredMonthsProvider")
    }
    return context
};

const useRegisteredMonthsDispatch = () => {
    const context = useContext(RegisteredMonthsDispatchContext);
    if (context === undefined) {
        throw new Error("useRegisteredMonthsDispatch must be used within RegisteredMonthsProvider")
    }
    return context
};

export {RegisteredMonthsProvider, useRegisteredMonthsDispatch, useRegisteredMonthsState}