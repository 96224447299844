import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";
import {ExpenseTypesFormInitialValues} from "./form/ExpenseTypesForm";


export const ADD_EXPENSE_TYPE = "add_expense_type"
export const ADD_EXPENSE_TYPE_SUCCESS = "add_expense_type_success"
export const ADD_EXPENSE_TYPE_FAIL = "add_expense_type_fail"

export const addExpenseType = ({
                                   dispatch,
                                   values,
                                   associationId
                               }: {
    dispatch: Dispatch,
    values: ExpenseTypesFormInitialValues,
    associationId: string
}) => {
    dispatch({
        type: ADD_EXPENSE_TYPE,
        payload: {}
    });

    authFetch(`/associations/group/${associationId}`, {
        method: "POST",
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: ADD_EXPENSE_TYPE_SUCCESS,
                payload: data
            });
            getExpenseTypes({dispatch: dispatch, associationId: associationId});
        })
        .catch(error => {
            dispatch({
                type: ADD_EXPENSE_TYPE_FAIL,
                payload: error
            })
        })
};

export const GET_EXPENSE_TYPES = 'get_expense_types';
export const GET_EXPENSE_TYPES_SUCCESS = 'get_expense_types_success';
export const GET_EXPENSE_TYPES_FAIL = 'get_expense_types_fail';
export const getExpenseTypes = ({
                                    dispatch,
                                    associationId
                                }: {
    dispatch: Dispatch,
    associationId: string
}) => {
    dispatch({
        type: GET_EXPENSE_TYPES,
        payload: {}
    });
    authFetch(`/associations/expense-groups/${associationId}`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: GET_EXPENSE_TYPES_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_EXPENSE_TYPES_FAIL,
                payload: error
            })
        })
};

export const GET_GROUP_TYPES = "get_group_types";
export const GET_GROUP_TYPES_SUCCESS = "get_group_types_success";
export const GET_GROUP_TYPES_FAIL = "get_group_types_fail";

export const getGroupTypes = ({
                                  dispatch
                              }: {
    dispatch: Dispatch
}) => {
    dispatch({
        type: GET_GROUP_TYPES,
        payload: {}
    });
    authFetch(`/associations/groupTypes?getExpenseTypes=true`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: GET_GROUP_TYPES_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_GROUP_TYPES_FAIL,
                payload: error
            })
        })
};

export const ADD_NEW_EXPENSE_TYPE = 'add_new_expense_type';
export const ADD_NEW_EXPENSE_TYPE_SUCCESS = 'add_new_expense_type_success';
export const ADD_NEW_EXPENSE_TYPE_FAIL = 'add_new_expense_type_fail';
export const addNewExpenseType = ({
                                      dispatch,
                                      values,
                                      associationId
                                  }: {
    dispatch: Dispatch,
    values: ExpenseTypesFormInitialValues,
    associationId: string
}) => {
    dispatch({
        type: ADD_NEW_EXPENSE_TYPE,
        payload: {}
    });
    const newExpenseTypes = []
    newExpenseTypes.push({
        name: values.name,
        isMetered: values.isMetered,
        counterTypeId: values.isMetered ? values.counterTypeId : ""
    });
    const data = {
        expenseTypes: newExpenseTypes
    }
    authFetch(`/costs/${associationId}/expenseTypes`, {
        method: "POST",
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: ADD_NEW_EXPENSE_TYPE_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: ADD_NEW_EXPENSE_TYPE_FAIL,
                payload: error
            })
        })
};

export const DELETE_EXPENSE_TYPE = 'delete_expense_type';
export const DELETE_EXPENSE_TYPE_SUCCESS = 'delete_expense_type_success';
export const DELETE_EXPENSE_TYPE_FAIL = 'delete_expense_type_fail';
export const deleteExpenseType = ({
                                      dispatch,
                                      associationId,
                                      expenseTypeId
                                  }:
                                      {
                                          dispatch: Dispatch,
                                          associationId: string,
                                          expenseTypeId: string
                                      }
) => {
    dispatch({
        type: DELETE_EXPENSE_TYPE,
        payload: {}
    });
    authFetch(`/costs/${associationId}/expenseTypes/${expenseTypeId}`, {
        method: 'DELETE'
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: DELETE_EXPENSE_TYPE_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: DELETE_EXPENSE_TYPE_FAIL,
                payload: error
            })
        })
};

export const GET_COUNTER_TYPES = "get_counter_types";
export const GET_COUNTER_TYPES_SUCCESS = "get_counter_types_success";
export const GET_COUNTER_TYPES_FAIL = "get_counter_types_fail";
export const getCounterTypes = ({
                                    dispatch,
                                    associationId
                                }: {
                                    dispatch: Dispatch,
                                    associationId: string
                                }
) => {
    dispatch({
        type: GET_COUNTER_TYPES,
        payload: {}
    });
    authFetch(`/costs/${associationId}/counterTypes`, {
        method: "GET"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: GET_COUNTER_TYPES_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_COUNTER_TYPES_FAIL,
                payload: error
            })
        })
};

export const EDIT_EXPENSE_TYPE = 'edit_expense_type';
export const EDIT_EXPENSE_TYPE_SUCCESS = 'edit_expense_type_success';
export const EDIT_EXPENSE_TYPE_FAIL = 'edit_expense_type_fail';
export const editExpenseType = ({
                                    dispatch,
                                    associationId,
                                    expenseTypeId,
                                    values
                                }: {
                                    dispatch: Dispatch,
                                    associationId: string,
                                    expenseTypeId: string,
                                    values: { name: string }
                                }
) => {
    dispatch({
        type: EDIT_EXPENSE_TYPE,
        payload: {}
    });
    authFetch(`/costs/${associationId}/expenseTypes/${expenseTypeId}`, {
        method: "PUT",
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: EDIT_EXPENSE_TYPE_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: EDIT_EXPENSE_TYPE_FAIL,
                payload: error
            })
        })
};

export const SELECT_ELEMENT_ACTION = 'select_element_action';
export const selectElementAction = ({
                                        id,
                                        dispatch
                                    }: {
                                        id: string,
                                        dispatch: Dispatch
                                    }
) => {
    dispatch({
        type: SELECT_ELEMENT_ACTION,
        payload: id
    })
};

export const CLEAR_SELECTED_ELEMENTS_ARRAY = 'clear_selected_elements_array';
export const clearSelectedElementArray = (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_SELECTED_ELEMENTS_ARRAY,
        payload: {}
    })
};

export const REMOVE_ELEMENT_FROM_GROUP = 'remove_element_from_group';
export const REMOVE_ELEMENT_FROM_GROUP_SUCCESS = 'remove_element_from_group_success';
export const REMOVE_ELEMENT_FROM_GROUP_FAIL = 'remove_element_from_group_fail';
export const removeElementFromGroup = ({
                                           dispatch,
                                           associationId,
                                           expenseGroupId,
                                           expenseTypeId,
                                           callback
                                       }: {
                                           dispatch: Dispatch,
                                           associationId: string,
                                           expenseGroupId: string,
                                           expenseTypeId: string,
                                           callback?: () => void
                                       }
) => {
    dispatch({
        type: REMOVE_ELEMENT_FROM_GROUP,
        payload: {}
    });
    authFetch(`/associations/expenseTypes-groups/${associationId}/${expenseGroupId}/${expenseTypeId}`, {
        method: "DELETE"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: REMOVE_ELEMENT_FROM_GROUP_FAIL,
                        payload: error
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            dispatch({
                type: REMOVE_ELEMENT_FROM_GROUP_SUCCESS,
                payload: data
            });
            if (callback) {
                callback()
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const GET_PROPERTIES_FOR_AN_EXPENSE_TYPE = 'get_properties_for_an_expense_type';
export const GET_PROPERTIES_FOR_AN_EXPENSE_TYPE_SUCCESS = 'get_properties_for_an_expense_type_success';
export const GET_PROPERTIES_FOR_AN_EXPENSE_TYPE_FAIL = 'get_properties_for_an_expense_type_fail';
export const getPropertiesForAnExpenseType = ({
                                                  dispatch,
                                                  associationId,
                                                  expenseTypeId
                                              }: {
                                                  dispatch: Dispatch,
                                                  associationId: string,
                                                  expenseTypeId: string
                                              }
) => {
    dispatch({
        type: GET_PROPERTIES_FOR_AN_EXPENSE_TYPE,
        payload: {}
    })
    authFetch(`/associations/expense-groups/${associationId}/${expenseTypeId}/properties`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_PROPERTIES_FOR_AN_EXPENSE_TYPE_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_PROPERTIES_FOR_AN_EXPENSE_TYPE_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
}

export const GET_EXPENSE_TYPE_BY_ID = "get_expense_type_by_id";
export const GET_EXPENSE_TYPE_BY_ID_SUCCESS = "get_expense_type_by_id_success";
export const GET_EXPENSE_TYPE_BY_ID_FAIL = "get_expense_type_by_id_fail";
export const getExpenseTypeById = ({
                                       dispatch,
                                       associationId,
                                       expenseTypeId
                                   }: {
                                       dispatch: Dispatch,
                                       associationId: string,
                                       expenseTypeId: string
                                   }
) => {
    dispatch({
        type: GET_EXPENSE_TYPE_BY_ID,
        payload: {}
    })
    authFetch(`/associations/group/${associationId}/${expenseTypeId}`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_EXPENSE_TYPE_BY_ID_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_EXPENSE_TYPE_BY_ID_SUCCESS,
                    payload: data.details
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
}