import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";
import {History} from 'history';
import {ExpensesFormInitialValues} from "./form/ExpensesForm";
import _ from "lodash";

export const GET_INVOICES = "get_invoices";
export const GET_INVOICES_SUCCESS = "get_invoices_success";
export const GET_INVOICES_FAIL = "get_invoices_fail";
export const SORT_EXPENSES = "sort_expenses";
export const SORT_EXPENSES_SUCCESS = "sort_expenses_success";
export const SORT_EXPENSES_FAIL = "sort_expenses_fail";
export const CHANGE_SORT_ITEM = "change_sort_item";

export const SELECT_ELEMENT_ACTION_EXPENSE = 'select_element_action_expense';
export const selectElementAction = ({
                                        id,
                                        dispatch
                                    }: {
                                        id: string,
                                        dispatch: Dispatch
                                    }
) => {
    dispatch({
        type: SELECT_ELEMENT_ACTION_EXPENSE,
        payload: id
    })
};

export const CLEAR_SELECTED_ELEMENTS_ARRAY_EXPENSE = 'clear_selected_elements_array_expense';
export const clearSelectedElementArray = (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_SELECTED_ELEMENTS_ARRAY_EXPENSE,
        payload: {}
    })
};


const getInvoices = ({dispatch,}: { dispatch: Dispatch; }, id: string) => {
    dispatch({
        type: GET_INVOICES,
        payload: {}
    });
    authFetch(`/costs/${id}/expenses`, {
        method: "GET",
    }).then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_INVOICES_SUCCESS,
                payload: data,
            });
        }).catch((error) => {
        dispatch({
            type: GET_INVOICES_FAIL,
            payload: error,
        });
    });
};

const sortExpenses = ({dispatch,}: { dispatch: Dispatch; }, id: string, sortOrder: string, sortBy: string) => {
    dispatch({
        type: SORT_EXPENSES,
        payload: {}
    });
    authFetch(`/costs/${id}/expenses?sortOrder=${sortOrder}&sortBy=${sortBy}`, {
        method: "GET",
    }).then((response) => response.json()).then((data) => {
        dispatch({
            type: SORT_EXPENSES_SUCCESS,
            payload: data,
        });
    }).catch((error) => {
        dispatch({
            type: SORT_EXPENSES_FAIL,
            payload: error,
        });
    });
};
export const DELETE_INVOICE = "delete_invoice";
export const DELETE_INVOICE_SUCCESS = "delete_invoice_success";
export const DELETE_INVOICE_FAIL = "delete_invoice_fail";

const deleteInvoice = ({dispatch}: { dispatch: Dispatch; }, idInvoice: string, idAssociation: string) => {
    dispatch({
        type: DELETE_INVOICE,
        payload: {}
    });
    authFetch(`/costs/${idAssociation}/expenses/${idInvoice}`, {
        method: "DELETE",
    }).then((response) => response.json()).then((data) => {
        dispatch({
            type: DELETE_INVOICE_SUCCESS,
            payload: data,
        });
        getInvoices({dispatch}, idAssociation);
    }).catch((error) => {
        dispatch({
            type: DELETE_INVOICE_FAIL,
            payload: error,
        });
        getInvoices({dispatch}, idAssociation);
    });
};

export const ADD_EXPENSE_FILE = 'add_expense_file';
export const addExpenseFile = ({
                                   dispatch,
                                   file
                               }: {
                                   dispatch: Dispatch,
                                   file: File
                               }
) => {
    dispatch({
        type: ADD_EXPENSE_FILE,
        payload: file
    })
};

export const REMOVE_EXPENSE_FILE = 'remove_expense_file';
export const removeExpenseFile = ({
                                      dispatch,
                                      fileIndex
                                  }: {
                                      dispatch: Dispatch,
                                      fileIndex: number
                                  }
) => {
    dispatch({
        type: REMOVE_EXPENSE_FILE,
        payload: fileIndex
    })
};

export const ADD_NEW_EXPENSE = 'add_new_expense';
export const ADD_NEW_EXPENSE_SUCCESS = 'add_new_expense_success';
export const ADD_NEW_EXPENSE_FAIL = 'add_new_expense_fail';
export const addNewExpense = ({
                                  dispatch,
                                  values,
                                  history,
                                  insertedFiles,
                                  associationId
                              }: {
                                  dispatch: Dispatch,
                                  values: ExpensesFormInitialValues,
                                  history: History,
                                  insertedFiles: File[],
                                  associationId: string
                              }
) => {
    dispatch({
        type: ADD_NEW_EXPENSE,
        payload: {}
    });

    let data = new FormData();

    _.forOwn(values, (value, key) => {
        if (key !== "files") {
            if (!!value) {
                data.append(key, value as string);
            }
        }
    });

    _.forEach(insertedFiles, file => {
        data.append('file', file)
    });

    authFetch(`/costs/${associationId}/expenses`, {
        method: "POST",
        body: data
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: ADD_NEW_EXPENSE_SUCCESS,
                payload: data
            });
            history.push(`/associations/${associationId}/expenses`);
        })
        .catch(error => {
            dispatch({
                type: ADD_NEW_EXPENSE_FAIL,
                payload: error
            })
        })
};

export const UPDATE_INVOICE = "update_invoice"
export const UPDATE_INVOICE_SUCCESS = "update_invoice_success"
export const UPDATE_INVOICE_FAIL = "update_invoice_success"
export const updateExpense = ({
                                  dispatch,
                                  values,
                                  history,
                                  insertedFiles,
                                  associationId,
                                  expenseId
                              }: {
                                  dispatch: Dispatch,
                                  values: ExpensesFormInitialValues,
                                  history: History,
                                  insertedFiles: File[],
                                  associationId: string,
                                  expenseId: string
                              }
) => {


    dispatch({
        type: UPDATE_INVOICE,
        payload: {}
    });

    let data = new FormData();

    _.forOwn(values, (value, key) => {
        if (key !== "files") {
            data.append(key, value as string);
        }
    });

    _.forEach(insertedFiles, file => {
        if (file instanceof File) {
            data.append('file', file)
        } else {
            _.forOwn(file, (fileValue, fileKey) => {
                // @ts-ignore
                data.append(`file[${fileKey}]`, fileValue);
            })
        }
    });

    authFetch(`/costs/${associationId}/expenses/${expenseId}`, {
        method: "PUT",
        body: data
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: UPDATE_INVOICE_SUCCESS,
                payload: data
            });
            history.goBack();
        })
        .catch(error => {
            dispatch({
                type: UPDATE_INVOICE_FAIL,
                payload: error
            })
        })
};

export const POST_DISTRIBUTION_PROPS = "post_distribution_props"
export const POST_DISTRIBUTION_PROPS_SUCCESS = "post_distribution_props_success"
export const POST_DISTRIBUTION_PROPS_FAIL = "post_distribution_props_fail"

const postDistributionPros = ({
                                  dispatch,
                                  expenseId,
                                  associationId,
                                  callback
                              }: {
                                  dispatch: Dispatch,
                                  expenseId: string,
                                  associationId: string,
                                  callback?: () => void
                              }
) => {
    dispatch({type: POST_DISTRIBUTION_PROPS, payload: {}});
    authFetch(`/costs/${associationId}/distributions/${expenseId}`, {
        method: "POST",
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: POST_DISTRIBUTION_PROPS_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: POST_DISTRIBUTION_PROPS_SUCCESS,
                    payload: data
                });
                if (callback) {
                    callback()
                }
            }
        })
        .catch(error => {
            console.log("eror", error)
        })
}

export const GET_SELECTED_EXPENSE = "get_selected_expense";
export const GET_SELECTED_EXPENSE_SUCCESS = "get_selected_expense_success";
export const GET_SELECTED_EXPENSE_FAIL = "get_selected_expense_fail";

const getSelectedExpense = (
    {
        dispatch
    }: {
        dispatch: Dispatch
    }, invoiceId: string,
    associationId: string,
    callback?: () => void
) => {
    dispatch({
        type: GET_SELECTED_EXPENSE,
        payload: {}
    });
    authFetch(`/costs/${associationId}/expenses/${invoiceId}`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_SELECTED_EXPENSE_SUCCESS,
                payload: data
            });
            if (callback) {
                callback();
            }
        })
        .catch(error => {
            dispatch({
                type: GET_SELECTED_EXPENSE_FAIL,
                payload: error
            })
        })
};

export const GET_DOCUMENT_TYPES = 'get_document_types';
export const GET_DOCUMENT_TYPES_SUCCESS = 'get_document_types_success';
export const GET_DOCUMENT_TYPES_FAIL = 'get_document_types_fail';
export const getDocumentTypes = ({
                                     dispatch,
                                     associationId
                                 }:
                                     {
                                         dispatch: Dispatch,
                                         associationId: string
                                     }
) => {
    dispatch({
        type: GET_DOCUMENT_TYPES,
        payload: {}
    });
    authFetch(`/costs/${associationId}/documentTypes`, {
        method: "GET"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: GET_DOCUMENT_TYPES_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_DOCUMENT_TYPES_FAIL,
                payload: error
            })
        })
};

export const GET_DISTRIBUTION_TYPES = 'get_distribution_types';
export const GET_DISTRIBUTION_TYPES_SUCCESS = 'get_distribution_types_success';
export const GET_DISTRIBUTION_TYPES_FAIL = 'get_distribution_types_fail';
export const getDistributionTypes = ({
                                         dispatch,
                                         associationId,
                                         forFunds
                                     }:
                                         {
                                             dispatch: Dispatch,
                                             associationId: string,
                                             forFunds?: boolean
                                         }
) => {
    dispatch({
        type: GET_DISTRIBUTION_TYPES,
        payload: {}
    });
    let url = `/costs/${associationId}/distributionTypes`;
    if (forFunds) {
        url = `${url}?forFunds=true`
    }
    authFetch(url, {
        method: "GET"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: GET_DISTRIBUTION_TYPES_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_DISTRIBUTION_TYPES_FAIL,
                payload: error
            })
        })
};

export const GET_DISTRIBUTION_DIFFERENCES_TYPES = 'get_distribution_differences_types';
export const GET_DISTRIBUTION_TYPES_DIFFERENCES_SUCCESS = 'get_distribution_types_differences_success';
export const GET_DISTRIBUTION_TYPES_DIFFERENCES_FAIL = 'get_distribution_types_differences_fail';
export const getDistributionDifferencesTypes = ({
                                                    dispatch,
                                                    associationId,

                                                }:
                                                    {
                                                        dispatch: Dispatch,
                                                        associationId: string,
                                                    }
) => {
    dispatch({
        type: GET_DISTRIBUTION_DIFFERENCES_TYPES,
        payload: {}
    });
    let url = `/costs/${associationId}/distributionDifferenceTypes`;
    authFetch(url, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({type: GET_DISTRIBUTION_TYPES_DIFFERENCES_FAIL, payload: JSON.parse(error)})
                })
            }
            return response.json()
        }).then(data => {
        dispatch({type: GET_DISTRIBUTION_TYPES_DIFFERENCES_SUCCESS, payload: data})
    }).catch(error => {
        console.log("error", error)
    })

};

export const GET_EXPENSE_TYPES = 'get_expense_types';
export const GET_EXPENSE_TYPES_SUCCESS = 'get_expense_types_success';
export const GET_EXPENSE_TYPES_FAIL = 'get_expense_types_fail';
export const getExpenseTypes = ({
                                    dispatch,
                                    associationId
                                }:
                                    {
                                        dispatch: Dispatch,
                                        associationId: string
                                    }
) => {
    dispatch({
        type: GET_EXPENSE_TYPES,
        payload: {}
    });
    authFetch(`/costs/${associationId}/expenseTypes`, {
        method: "GET"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: GET_EXPENSE_TYPES_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_EXPENSE_TYPES_FAIL,
                payload: error
            })
        })
};

export const CLEAR_FILES_ARRAY = 'clear_files_array';
export const clearFilesArray = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({
        type: CLEAR_FILES_ARRAY,
        payload: {}
    })
};

export const changeSortItem = ({dispatch}: { dispatch: Dispatch }, field: string) => {
    dispatch({
        type: CHANGE_SORT_ITEM,
        payload: field
    })
};

export const REDISTRIBUTE_EXPENSE = "redistribute_expense";
export const REDISTRIBUTE_EXPENSE_SUCCESS = "redistribute_expense_success";
export const REDISTRIBUTE_EXPENSE_FAIL = "redistribute_expense_fail";
export const redistributeExpense = ({
                                        dispatch,
                                        associationId,
                                        expenseId,
                                        callback
                                    }: {
                                        dispatch: Dispatch,
                                        associationId: string,
                                        expenseId: string,
                                        callback: () => void
                                    }
) => {
    dispatch({
        type: REDISTRIBUTE_EXPENSE,
        payload: {}
    });

    authFetch(`/costs/${associationId}/distributions/${expenseId}/redistribute`, {
        method: "POST"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: REDISTRIBUTE_EXPENSE_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: REDISTRIBUTE_EXPENSE_SUCCESS,
                    payload: data
                });
                callback()
            }
        })
        .catch(error => {
            console.log("error", error)
        })

};

export {
    getInvoices,
    deleteInvoice,
    postDistributionPros,
    getSelectedExpense,
    sortExpenses
}
