import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";

export const POST_MANAGER = "post_manager";
export const POST_MANAGER_SUCCESS = "post_manager_success";
export const POST_MANAGER_FAIL = "post_manager_fail";
export const GET_MANAGER = "get_manager";
export const GET_MANAGER_SUCCES = "get_manager_succes";
export const GET_MANAGER_FAIL = "get_manger_fail";
export const DELETE_MANAGER_INVITATION = "delete_manager_invitation";
export const DELETE_MANAGER_INVITATION_SUCCES =
    "delete_manager_invitation_succes";
export const DELETE_MANAGER_INVITATION_FAIL = "delete_manager_invitation_fail";

interface PostProps {
    dispatch: Dispatch;
}

export const inviteManager = ({dispatch}: PostProps, associationId: string, userEmail: string) => {
    dispatch({type: POST_MANAGER, payload: {}});
    let formData = new FormData();
    formData.append("associationId", associationId);
    formData.append("email", userEmail);
    formData.append("type", "manager")

    authFetch(`/associations/invite`, {
        method: "POST",
        body: formData,
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: POST_MANAGER_SUCCESS,
                payload: {
                    message: data.message,
                },
            });
            getManagersInvitation({dispatch}, associationId);

        })
        .catch((error) => {
            console.error("Error:", error);
            dispatch({
                type: POST_MANAGER_FAIL,
                payload: error,
            });
        });
};
export const getManagersInvitation = ({dispatch}: PostProps, id: string) => {
    dispatch({type: GET_MANAGER, payload: {}});
    authFetch(`/associations/invite/${id}`, {
        method: "GET",
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_MANAGER_SUCCES,
                payload: data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_MANAGER_FAIL,
                payload: error,
            });
        });
};
export const deleteManagersInvitation = (
    {dispatch}: PostProps,
    idInvitation: string,
    idAssociation: string
) => {
    dispatch({type: DELETE_MANAGER_INVITATION, payload: {}});
    authFetch(`/associations/invite/${idInvitation}`, {
        method: "DELETE",
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: DELETE_MANAGER_INVITATION_SUCCES,
                payload: data,
            });
            getManagersInvitation({dispatch}, idAssociation);
        })
        .catch((error) => {
            dispatch({
                type: DELETE_MANAGER_INVITATION_FAIL,
                payload: error,
            });
        });
};
