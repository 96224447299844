import React from "react";
import {AssociationProvider} from "./components/association/AssociationContext";
import AppRouter from "./router/AppRouter";
import {LoginProvider} from "./components/auth/AuthContext";
import {GroupProvider} from "./components/group/GroupContext";
import {UserProvider} from "./components/users/UsersContext";
import {ManagersProvider} from "./components/managers/ManagersContext";
import {PropertiesProvider} from "./components/properties/PropertiesContext";
import {InvitesProvider} from "./components/invites/InvitesContext";
import {OwnersProvider} from "./components/owners/OwnersContext";
import {SuppliersProvider} from "./components/supplier/SupplierContext";
import {ExpenseProvider} from "./components/expenses/ExpensesContext";
import {ExpenseTypesProvider} from './components/expenseTypes/ExpenseTypesContext';
import {GlobalProvider} from "./config/GlobalContext"
import {CountersProvider} from "./components/counters/CountersContext";
import {PaymentsProvider} from "./components/payments/PaymentsContext";
import {NotificationsProvider} from "./components/notifications/NotificationsContext";
import {ExpenseTypesGroupsProvider} from "./components/expenseTypesGroups/ExpenseTypesGroupsContext";
import {FundsProvider} from "./components/funds/FundContext";
import {RegisteredMonthsProvider} from "./components/registeredMonths/RegisteredMonthsContext";
import {TransactionsProvider} from "./components/transactions/TransactionsContext";
import {FilesProvider} from "./components/files/FilesContext";
import {SettingsProvider} from "./components/settings/SettingsContext";

function App() {
    return (
        <div className="App">
            <GlobalProvider>
                <LoginProvider>
                    <UserProvider>
                        <AssociationProvider>
                            <GroupProvider>
                                <ManagersProvider>
                                    <PropertiesProvider>
                                        <OwnersProvider>
                                            <InvitesProvider>
                                                <SuppliersProvider>
                                                    <ExpenseProvider>
                                                        <ExpenseTypesProvider>
                                                            <PaymentsProvider>
                                                                <CountersProvider>
                                                                    <NotificationsProvider>
                                                                        <ExpenseTypesGroupsProvider>
                                                                            <FundsProvider>
                                                                                <RegisteredMonthsProvider>
                                                                                    <TransactionsProvider>
                                                                                        <FilesProvider>
                                                                                            <SettingsProvider>
                                                                                                <AppRouter/>
                                                                                            </SettingsProvider>
                                                                                        </FilesProvider>
                                                                                    </TransactionsProvider>
                                                                                </RegisteredMonthsProvider>
                                                                            </FundsProvider>
                                                                        </ExpenseTypesGroupsProvider>
                                                                    </NotificationsProvider>
                                                                </CountersProvider>
                                                            </PaymentsProvider>
                                                        </ExpenseTypesProvider>
                                                    </ExpenseProvider>
                                                </SuppliersProvider>
                                            </InvitesProvider>
                                        </OwnersProvider>
                                    </PropertiesProvider>
                                </ManagersProvider>
                            </GroupProvider>
                        </AssociationProvider>
                    </UserProvider>
                </LoginProvider>
            </GlobalProvider>
        </div>
    );
}

export default App;
