import React, {createContext, useContext, useReducer} from "react";
import {Action, Dispatch, ReactChildrenType,} from "../../constants/globalTypes";
import {
  DELETE_MANAGER_INVITATION,
  DELETE_MANAGER_INVITATION_FAIL,
  DELETE_MANAGER_INVITATION_SUCCES,
  GET_MANAGER,
  GET_MANAGER_FAIL,
  GET_MANAGER_SUCCES,
  POST_MANAGER,
  POST_MANAGER_FAIL,
  POST_MANAGER_SUCCESS,
} from "./ManagersActions";

type State = {
    loading: boolean;
    error: object;
    message: object;
    managers: object;
};

const ManagersStateContext = createContext<State | undefined>(undefined);
const ManagersDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    managers: [],
    loading: false,
    error: {},
    message: {},
};

const managersReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_MANAGER: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_MANAGER_SUCCES: {
            return {
                ...state,
                loading: false,
                managers: action.payload,
            };
        }
        case GET_MANAGER_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                managers: []
            };
        }
        case POST_MANAGER: {
            return {
                ...state,
                loading: true,
            };
        }
        case POST_MANAGER_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case POST_MANAGER_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case DELETE_MANAGER_INVITATION: {
            return {
                ...state,
                loading: true,
            };
        }
        case DELETE_MANAGER_INVITATION_SUCCES: {
            return {
                ...state,
                loading: false,
            };
        }
        case DELETE_MANAGER_INVITATION_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

const ManagersProvider = ({children}: ReactChildrenType) => {
    const [state, dispatch] = useReducer(managersReducer, initialState);
    return (
        <ManagersStateContext.Provider value={state}>
            <ManagersDispatchContext.Provider value={dispatch}>
                {children}
            </ManagersDispatchContext.Provider>
        </ManagersStateContext.Provider>
    );
};

const useManagersState = () => {
    const context = useContext(ManagersStateContext);
    if (context === undefined) {
        throw new Error("useManagersState must be used within a LoginProvider");
    }
    return context;
};

const useManagersDispatch = () => {
    const context = useContext(ManagersDispatchContext);
    if (context === undefined) {
        throw new Error("useManagersState must be used within a LoginProvider");
    }
    return context;
};

export {ManagersProvider, useManagersDispatch, useManagersState};
