import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_PAYMENT,
    ADD_PAYMENT_FAIL,
    ADD_PAYMENT_SUCCESS,
    DELETE_PAYMENT,
    DELETE_PAYMENT_FAIL,
    DELETE_PAYMENT_SUCCESS,
    GET_GROUP_PAYMENTS,
    GET_GROUP_PAYMENTS_FAIL,
    GET_GROUP_PAYMENTS_SUCCESS,
    GET_PAYMENT,
    GET_PAYMENT_FAIL,
    GET_PAYMENT_SUCCESS,
    GET_PAYMENTS,
    GET_PAYMENTS_FAIL,
    GET_PAYMENTS_SUCCESS,
    GET_PROPERTY_PAYMENTS,
    GET_PROPERTY_PAYMENTS_FAIL,
    GET_PROPERTY_PAYMENTS_SUCCESS,
    UPDATE_PAYMENT,
    UPDATE_PAYMENT_FAIL,
    UPDATE_PAYMENT_SUCCESS
} from "./PaymentsActions";

type State = {
    payments: [],
    loading: boolean,
    message: string
    error: object,
    selectedPayment: object,
    propertyPayments: [],
    groupPayments: []
}

const PaymentsStateContext = createContext<State | undefined>(undefined);
const PaymentsDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    payments: [],
    loading: false,
    message: "",
    error: {},
    selectedPayment: {},
    propertyPayments: [],
    groupPayments: []
};

const paymentsReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_PAYMENTS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PAYMENTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                payments: action.payload
            }
        }
        case GET_PAYMENTS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case ADD_PAYMENT: {
            return {
                ...state,
                loading: true
            }
        }
        case ADD_PAYMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                message: action.payload
            }
        }
        case ADD_PAYMENT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case UPDATE_PAYMENT: {
            return {
                ...state,
                loading: true
            }
        }
        case UPDATE_PAYMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                message: action.payload
            }
        }
        case UPDATE_PAYMENT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case DELETE_PAYMENT: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETE_PAYMENT_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case DELETE_PAYMENT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_PAYMENT: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PAYMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                selectedPayment: action.payload
            }
        }
        case GET_PAYMENT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_PROPERTY_PAYMENTS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PROPERTY_PAYMENTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                propertyPayments: action.payload
            }
        }
        case GET_PROPERTY_PAYMENTS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_GROUP_PAYMENTS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_GROUP_PAYMENTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                groupPayments: action.payload
            }
        }
        case GET_GROUP_PAYMENTS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

const PaymentsProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(paymentsReducer, initialState);
    return (
        <PaymentsStateContext.Provider value={state}>
            <PaymentsDispatchContext.Provider value={dispatch}>
                {children}
            </PaymentsDispatchContext.Provider>
        </PaymentsStateContext.Provider>
    )
};

const usePaymentsState = () => {
    const context = useContext(PaymentsStateContext);
    if (context === undefined) {
        throw new Error('usePaymentsState must be used within a GroupProvider')
    }
    return context
};

const usePaymentsDispatch = () => {
    const context = useContext(PaymentsDispatchContext);
    if (context === undefined) {
        throw new Error(' usePaymentsDispatch must be used within a GroupProvider');
    }
    return context
}

export {PaymentsProvider, usePaymentsState, usePaymentsDispatch}
