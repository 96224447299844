import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_TRANSACTION, ADD_TRANSACTION_FAIL, ADD_TRANSACTION_SUCCESS,
    GET_EXPENSES_BALANCES,
    GET_EXPENSES_BALANCES_FAIL,
    GET_EXPENSES_BALANCES_SUCCESS,
    GET_FUNDS_BALANCES, GET_FUNDS_BALANCES_FAIL,
    GET_FUNDS_BALANCES_SUCCESS, GET_MONEY_STATS, GET_MONEY_STATS_FAIL, GET_MONEY_STATS_SUCCESS,
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_FAIL,
    GET_TRANSACTIONS_SUCCESS, SET_ERROR_TO_INITIAL_STATE
} from "./TransactionsActions";

type State = {
    loading: boolean;
    error: { message: string };
    transactions: Transaction[];
    expensesBalances: {
        balance: number,
        toPay: number,
        paid: number
    };
    fundsBalances: {
        balance: number,
        toPay: number,
        paid: number
    };
    paidForOptions: { label: string, value: string }[];
    moneyStats: {
        availableMoney: number,
        totalPaid: number,
        totalWithdrawn: number,
    }
};

export type Transaction = {
    id: string;
    addedAt: string;
    addedByDetails: {
        firstName: string;
        lastName: string;
        email: string;
    }
    amount: number;
    type: string;
    text: string;
    propertyDetails: {
        number: number;
        stairDetails: {
            name: string;
        }
        blockDetails: {
            name: string;
        }
    };
    fundDetails: {
        name: string;
    };
}

const TransactionsStateContext = createContext<State | undefined>(undefined);
const TransactionsDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    loading: false,
    error: {
        message: ""
    },
    transactions: [],
    expensesBalances: {
        balance: 0,
        toPay: 0,
        paid: 0
    },
    fundsBalances: {
        balance: 0,
        toPay: 0,
        paid: 0
    },
    paidForOptions: [
        {
            value: "expenses",
            label: "Cheltuieli"
        }, {
            value: "fund",
            label: "Fond"
        }
    ],
    moneyStats: {
        availableMoney: 0,
        totalPaid: 0,
        totalWithdrawn: 0,
    }
};

const transactionsReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_TRANSACTIONS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                transactions: action.payload
            }
        }
        case GET_TRANSACTIONS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_EXPENSES_BALANCES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_EXPENSES_BALANCES_SUCCESS: {
            return {
                ...state,
                loading: false,
                expensesBalances: action.payload
            }
        }
        case GET_EXPENSES_BALANCES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_FUNDS_BALANCES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_FUNDS_BALANCES_SUCCESS: {
            return {
                ...state,
                loading: false,
                fundsBalances: action.payload
            }
        }
        case GET_FUNDS_BALANCES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case ADD_TRANSACTION: {
            return {
                ...state,
                loading: true,
                error: {
                    message: ""
                }
            }
        }
        case ADD_TRANSACTION_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case ADD_TRANSACTION_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case SET_ERROR_TO_INITIAL_STATE: {
            return {
                ...state,
                error: {
                    message: ""
                }
            }
        }
        case GET_MONEY_STATS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_MONEY_STATS_SUCCESS: {
            return {
                ...state,
                loading: false,
                moneyStats: action.payload
            }
        }
        case GET_MONEY_STATS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return state
        }
    }
};

const TransactionsProvider = ({children}: ReactChildrenType) => {
    const [state, dispatch] = useReducer(transactionsReducer, initialState);
    return (
        <TransactionsStateContext.Provider value={state}>
            <TransactionsDispatchContext.Provider value={dispatch}>
                {children}
            </TransactionsDispatchContext.Provider>
        </TransactionsStateContext.Provider>
    )
};

const useTransactionsState = () => {
    const context = useContext(TransactionsStateContext);
    if (context === undefined) {
        throw new Error("useTransactionsState must be used within TransactionsProvider")
    }
    return context
};

const useTransactionsDispatch = () => {
    const context = useContext(TransactionsDispatchContext);
    if (context === undefined) {
        throw new Error("useTransactionsDispatch must be used within TransactionsProvider")
    }
    return context
};

export {TransactionsProvider, useTransactionsState, useTransactionsDispatch}
