import React, {createContext, useContext, useReducer} from "react"
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    GET_SETTINGS_BY_ASSOCIATION_ID,
    GET_SETTINGS_BY_ASSOCIATION_ID_FAIL,
    GET_SETTINGS_BY_ASSOCIATION_ID_SUCCESS,
    UPDATE_ASSOCIATION_SETTINGS,
    UPDATE_ASSOCIATION_SETTINGS_FAIL,
    UPDATE_ASSOCIATION_SETTINGS_SUCCESS
} from "./SettingsActions";

type State = {
    settings: {
        penaltiesPercentagePerDay: number,
        numberMonthsMaximEstimate:number;
        numberMcColdWaterPausal:number;
        numberMcHotWaterPausal:number;
        penaltiesAfterNrOfDays:number;},
    loading: boolean,
    error: {
        message: ""
    }
}

const SettingsStateContext = createContext<State | undefined>(undefined);
const SettingsDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    settings: {
        penaltiesPercentagePerDay: 0.2,
        numberMonthsMaximEstimate:2,
        numberMcColdWaterPausal:4,
        numberMcHotWaterPausal:3,
        penaltiesAfterNrOfDays:60,
    },
    loading: false,
    error: {
        message: ""
    }
};

const settingsReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_SETTINGS_BY_ASSOCIATION_ID: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_SETTINGS_BY_ASSOCIATION_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                settings: action.payload
            }
        }
        case GET_SETTINGS_BY_ASSOCIATION_ID_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case UPDATE_ASSOCIATION_SETTINGS: {
            return {
                ...state,
                loading: true
            }
        }
        case UPDATE_ASSOCIATION_SETTINGS_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case UPDATE_ASSOCIATION_SETTINGS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return state

        }
    }
};

const SettingsProvider = ({children}: ReactChildrenType) => {
    const [state, dispatch] = useReducer(settingsReducer, initialState);
    return (
        <SettingsStateContext.Provider value={state}>
            <SettingsDispatchContext.Provider value={dispatch}>
                {children}
            </SettingsDispatchContext.Provider>
        </SettingsStateContext.Provider>
    )
};

const useSettingsState = () => {
    const context = useContext(SettingsStateContext);
    if (context === undefined) {
        throw new Error("useSettingsState must be use within SettingsProvider")
    }
    return context
};

const useSettingsDispatch = () => {
    const context = useContext(SettingsDispatchContext);
    if (context === undefined) {
        throw new Error("useSettingsDispatch must be use within SettingsProvider")
    }
    return context
}

export {SettingsProvider, useSettingsDispatch, useSettingsState}