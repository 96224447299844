import React, {createContext, useContext, useReducer} from "react";
import {Action, Dispatch, ReactChildrenType,} from "../../constants/globalTypes";
import {GET_OWNERS, GET_OWNERS_FAIL, GET_OWNERS_SUCCESS} from "./OwnersActions";

type State = {
    owners: [];
    loading: boolean;
    error: object;
};

const OwnersStateContext = createContext<State | undefined>(undefined);
const OwnersDispatchContext = createContext<Dispatch | undefined>(
    undefined
);

const initialState: State = {
    owners: [],
    loading: false,
    error: {},
};

const ownersReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_OWNERS: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_OWNERS_SUCCESS: {
            return {
                ...state,
                loading: false,
                owners: action.payload,
            };
        }
        case GET_OWNERS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                owners: [],
            };
        }
        default: {
            return state;
        }
    }
};

const OwnersProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(ownersReducer, initialState);
    return (
        <OwnersStateContext.Provider value={state}>
            <OwnersDispatchContext.Provider value={dispatch}>
                {children}
            </OwnersDispatchContext.Provider>
        </OwnersStateContext.Provider>
    );
};

const useOwnersState = () => {
    const context = useContext(OwnersStateContext);
    if (context === undefined) {
        throw new Error(
            "useOwnersState must be used within a OwnerProvider"
        );
    }
    return context;
};

const useOwnersDispatch = () => {
    const context = useContext(OwnersDispatchContext);
    if (context === undefined) {
        throw new Error(
            "useOwnersDispatch must be used within a OwnerProvider"
        );
    }
    return context;
};
export {OwnersProvider, useOwnersDispatch, useOwnersState};
