import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";
import {History} from "history";
import React from "react";
import {NotificationsFormInitialValuesTypes} from "./form/NotificationsForm";

export const GET_NOTIFICATIONS = "get_notifications";
export const GET_NOTIFICATIONS_SUCCESS = "get_notifications_success";
export const GET_NOTIFICATIONS_FAIL = "get_notifications_fail";
export const getNotifications = ({
                                     dispatch,
                                     associationId
                                 }
                                     :
                                     {
                                         dispatch: Dispatch,
                                         associationId: string
                                     }
) => {
    dispatch({
        type: GET_NOTIFICATIONS,
        payload: {}
    });
    authFetch(`/associations/events/${associationId}`, {
        method: "GET"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                response.text().then(
                    error => {
                        dispatch({
                            type: GET_NOTIFICATIONS_FAIL,
                            payload: error
                        })
                    }
                );
                throw new Error("")
            }
        })
        .then(data => {
            dispatch({
                type: GET_NOTIFICATIONS_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            console.log(error)
        })
};

export const DELETE_NOTIFICATION = "delete_notification";
export const DELETE_NOTIFICATION_SUCCESS = "delete_notification_success";
export const DELETE_NOTIFICATION_FAIL = "delete_notification_fail";
export const deleteNotification = ({
                                       dispatch,
                                       notificationId,
                                       associationId,
                                       callback
                                   }
                                       : {
                                       dispatch: Dispatch,
                                       notificationId: string,
                                       associationId: string,
                                       callback: () => void
                                   }
) => {
    dispatch({
        type: DELETE_NOTIFICATION,
        payload: {}
    });
    authFetch(`/associations/events/${associationId}/${notificationId}`, {
        method: "DELETE"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                response.text().then(error => {
                    dispatch({
                        type: DELETE_NOTIFICATION_FAIL,
                        payload: error
                    })
                });
                throw new Error("")
            }
        })
        .then(data => {
            dispatch({
                type: DELETE_NOTIFICATION_SUCCESS,
                payload: data
            });
            callback();
        })
        .catch(error => {
            console.log(error)
        })
};

export const GET_NOTIFICATION = "get_notification";
export const GET_NOTIFICATION_SUCCESS = "get_notification_success";
export const GET_NOTIFICATION_FAIL = "get_notification_fail";
export const getNotification = ({
                                    dispatch,
                                    associationId,
                                    notificationId
                                }
                                    :
                                    {
                                        dispatch: Dispatch,
                                        associationId: string,
                                        notificationId: string
                                    }
) => {
    dispatch({
        type: GET_NOTIFICATION,
        payload: {}
    });
    authFetch(`/associations/events/${associationId}/${notificationId}`, {
        method: "GET"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                response.text().then(error => {
                    dispatch({
                        type: GET_NOTIFICATION_FAIL,
                        payload: error
                    })
                });
                throw new Error("")
            }
        })
        .then(data => {
            dispatch({
                type: GET_NOTIFICATION_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            console.log(error)
        })
};

export const ADD_NOTIFICATION = "add_notification";
export const ADD_NOTIFICATION_SUCCESS = "add_notification_success";
export const ADD_NOTIFICATION_FAIL = "add_notification_fail";
export const addNotification = ({
                                    dispatch,
                                    associationId,
                                    values,
                                    history
                                }
                                    :
                                    {
                                        dispatch: Dispatch,
                                        associationId: string,
                                        values: NotificationsFormInitialValuesTypes,
                                        history: History
                                    }
) => {
    dispatch({
        type: ADD_NOTIFICATION,
        payload: {}
    });
    const data = {
        name: values.name,
        description: values.description,
        date: values.date,
        groups: values.groups,
    }

    authFetch(`/associations/events/${associationId}`, {
        method: "POST",
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                response.text().then(error => {
                    dispatch({
                        type: ADD_NOTIFICATION_FAIL,
                        payload: error
                    })
                });
                throw new Error("")
            }
        })
        .then(data => {
            dispatch({
                type: ADD_NOTIFICATION_SUCCESS,
                payload: data
            })
            history.push(`/associations/${associationId}/notifications`)
        })
        .catch(error => {
            console.log(error)
        })
};