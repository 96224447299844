import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";
import _ from "lodash";


export const GET_FILES = "get_files";
export const GET_FILES_SUCCESS = "get_files_success";
export const GET_FILES_FAIL = "get_files_fail";

export const getFilesByAssociationId = ({dispatch, associationId}: { dispatch: Dispatch, associationId: string }) => {
    dispatch({type: GET_FILES, payload: {}});

    authFetch(`/associations/files/${associationId}`, {method: "GET"}).then(response => {
        if (!response.ok) {
            return response.text().then(error => {
                dispatch({type: GET_FILES_FAIL, payload: JSON.parse(error)})
            })
        }
        return response.json()
    }).then(data => {
        dispatch({type: GET_FILES_SUCCESS, payload: data})
    })
}

export const DELETE_FILE = "delete_file";
export const DELETE_FILE_SUCCESS = "delete_file_success";
export const DELETE_FILE_FAIL = "delete_file_fail";

export const deleteFileById = ({
                                   dispatch,
                                   fileId,
                                   associationId
                               }: { dispatch: Dispatch, fileId: string, associationId: string }) => {
    dispatch({type: DELETE_FILE, payload: {}});

    authFetch(`/associations/files/${fileId}`, {method: "DELETE"}).then(response => {
        if (!response.ok) {
            return response.text().then(error => {
                dispatch({type: DELETE_FILE_FAIL, payload: JSON.parse(error)})
            })
        }
        return response.json()
    }).then(data => {
        dispatch({type: DELETE_FILE_SUCCESS, payload: data})
        getFilesByAssociationId({associationId: associationId, dispatch: dispatch})
    })
}

export const ADD_FILE = "add_file";
export const addFile = ({
                            dispatch,
                            file
                        }: {
                            dispatch: Dispatch,
                            file: File
                        }
) => {
    dispatch({
        type: ADD_FILE,
        payload: file
    })
};

export const REMOVE_FILE = 'remove_file';
export const removeFileList = ({
                                   dispatch,
                                   fileIndex
                               }: {
                                   dispatch: Dispatch,
                                   fileIndex: number
                               }
) => {
    dispatch({
        type: REMOVE_FILE,
        payload: fileIndex
    })
};

export const CLEAR_FILES = "clear_files"
export const clearFiles = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({type: CLEAR_FILES, payload: {}})
}

export const ADD_FILE_ASSOCIATION = "add_file_association";
export const ADD_FILE_ASSOCIATION_SUCCESS = "add_file_association_success";
export const ADD_FILE_ASSOCIATION_FAIL = "add_file_association_fail";

export const addFileAssociation = ({
                                       dispatch,
                                       files,
                                       description,
                                       associationId,
                                       history
                                   }: { dispatch: Dispatch, files: File[], history: any, description: string, associationId: string }) => {
    dispatch({type: ADD_FILE_ASSOCIATION, payload: {}});
    let data = new FormData()
    _.forEach(files, file => {
        data.append('file', file)
    });
    data.append("description", description)

    authFetch(`/associations/files/${associationId}`, {method: "POST", body: data}).then(response => {
        if (!response.ok) {
            return response.text().then(error => {
                dispatch({type: ADD_FILE_ASSOCIATION_FAIL, payload: JSON.parse(error)})
            })
        }
        return response.json()
    }).then(data => {
        dispatch({type: ADD_FILE_ASSOCIATION_SUCCESS, payload: data})
        history.push(`/associations/${associationId}/files`)
    })
}

export const GET_FILE_BY_ID = "get_file_by_id";
export const GET_FILE_BY_ID_SUCCESS = "get_file_by_id_success";
export const GET_FILE_BY_ID_FAIL = "get_file_by_id_fail";

export const getFileById = ({
                                dispatch,
                                fileId
                            }: { dispatch: Dispatch, fileId: string }) => {
    dispatch({type: GET_FILE_BY_ID, payload: {}});

    authFetch(`/associations/file/${fileId}`, {method: "GET"}).then(response => {
        if (!response.ok) {
            return response.text().then(error => {
                dispatch({type: GET_FILE_BY_ID_FAIL, payload: JSON.parse(error)})
            })
        }
        return response.json()
    }).then(data => {
        dispatch({type: GET_FILE_BY_ID_SUCCESS, payload: data})
    })
}

export const UPDATE_FILE = "update_file";
export const UPDATE_FILE_SUCCESS = "update_file_success";
export const UPDATE_FILE_FAIL = "update_file_fail";

export const updateFileById = ({
                                   dispatch,
                                   file,
                                   associationId,
                                   history
                               }: { dispatch: Dispatch, file: any, associationId: string,history:any }) => {
    dispatch({type: UPDATE_FILE, payload: {}});

    authFetch(`/associations/${associationId}/files/${file.id} `, {
        method: "PUT",
        body: JSON.stringify(file)
    }).then(response => {
        if (!response.ok) {
            return response.text().then(error => {
                dispatch({type: UPDATE_FILE_FAIL, payload: JSON.parse(error)})
            })
        }
        return response.json()
    }).then(data => {
        dispatch({type: UPDATE_FILE_SUCCESS, payload: data})
        history.push(`/associations/${associationId}/files`)
    })
}