import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";
import {TransactionsAddPaymentFormInitialValues} from "./form/TransactionsAddPaymentForm";
import {TransactionsWithdrawMoneyFormInitialValues} from "./form/TransactionsWithdrawMoneyForm";

export const GET_TRANSACTIONS = "get_transactions";
export const GET_TRANSACTIONS_SUCCESS = "get_transactions_success";
export const GET_TRANSACTIONS_FAIL = "get_transactions_fail";
export const getTransactions = ({
                                    dispatch,
                                    associationId
                                }: {
                                    dispatch: Dispatch,
                                    associationId: string
                                }
) => {
    dispatch({
        type: GET_TRANSACTIONS,
        payload: {}
    });
    authFetch(`/costs/${associationId}/transactions`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_TRANSACTIONS_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_TRANSACTIONS_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const GET_EXPENSES_BALANCES = "get_expenses_balances";
export const GET_EXPENSES_BALANCES_SUCCESS = "get_expenses_balances_success";
export const GET_EXPENSES_BALANCES_FAIL = "get_expenses_balances_fail";
export const getExpensesBalances = ({
                                        dispatch,
                                        associationId
                                    }: {
                                        dispatch: Dispatch,
                                        associationId: string
                                    }
) => {
    dispatch({
        type: GET_EXPENSES_BALANCES,
        payload: {}
    });
    authFetch(`/costs/${associationId}/balances/expenses/status`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_EXPENSES_BALANCES_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_EXPENSES_BALANCES_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const GET_FUNDS_BALANCES = "get_funds_balances";
export const GET_FUNDS_BALANCES_SUCCESS = "get_funds_balances_success";
export const GET_FUNDS_BALANCES_FAIL = "get_funds_balances_fail";
export const getFundsBalances = ({
                                     dispatch,
                                     associationId
                                 }: {
                                     dispatch: Dispatch,
                                     associationId: string
                                 }
) => {
    dispatch({
        type: GET_FUNDS_BALANCES,
        payload: {}
    });
    authFetch(`/costs/${associationId}/balances/funds/status`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_FUNDS_BALANCES_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_FUNDS_BALANCES_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const ADD_TRANSACTION = 'add_transaction';
export const ADD_TRANSACTION_SUCCESS = 'add_transaction_success';
export const ADD_TRANSACTION_FAIL = 'add_transaction_fail';
export const addTransaction = ({
                                   dispatch,
                                   associationId,
                                   values,
                                   callback
                               }: {
                                   dispatch: Dispatch,
                                   associationId: string,
                                   values: TransactionsAddPaymentFormInitialValues | TransactionsWithdrawMoneyFormInitialValues,
                                   callback: () => void
                               }
) => {
    dispatch({
        type: ADD_TRANSACTION,
        payload: {}
    });
    authFetch(`/costs/${associationId}/transactions`, {
        method: "POST",
        body: JSON.stringify(values)
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: ADD_TRANSACTION_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: ADD_TRANSACTION_SUCCESS,
                    payload: data
                });
                callback()
            }
        })
        .catch(error => {
            console.log('error', error)
        })
};

export const SET_ERROR_TO_INITIAL_STATE = "set_error_to_initial_state";
export const setErrorToInitialState = (dispatch: Dispatch) => {
    dispatch({
        type: SET_ERROR_TO_INITIAL_STATE,
        payload: {}
    })
};

export const GET_MONEY_STATS = "get_money_stats";
export const GET_MONEY_STATS_SUCCESS = "get_money_stats_success";
export const GET_MONEY_STATS_FAIL = "get_money_stats_fail";
export const getMoneyStats = ({
                                  dispatch,
                                  associationId
                              }: {
                                  dispatch: Dispatch,
                                  associationId: string
                              }
) => {
    dispatch({
        type: GET_MONEY_STATS,
        payload: {}
    });

    authFetch(`/costs/${associationId}/balances/money-stats`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_MONEY_STATS_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_MONEY_STATS_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
}