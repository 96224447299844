import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_EXPENSE_FILE,
    ADD_NEW_EXPENSE,
    ADD_NEW_EXPENSE_FAIL,
    ADD_NEW_EXPENSE_SUCCESS,
    CHANGE_SORT_ITEM,
    CLEAR_FILES_ARRAY,
    CLEAR_SELECTED_ELEMENTS_ARRAY_EXPENSE, GET_DISTRIBUTION_DIFFERENCES_TYPES,
    GET_DISTRIBUTION_TYPES, GET_DISTRIBUTION_TYPES_DIFFERENCES_FAIL, GET_DISTRIBUTION_TYPES_DIFFERENCES_SUCCESS,
    GET_DISTRIBUTION_TYPES_FAIL,
    GET_DISTRIBUTION_TYPES_SUCCESS,
    GET_DOCUMENT_TYPES,
    GET_DOCUMENT_TYPES_FAIL,
    GET_DOCUMENT_TYPES_SUCCESS,
    GET_EXPENSE_TYPES,
    GET_EXPENSE_TYPES_FAIL,
    GET_EXPENSE_TYPES_SUCCESS,
    GET_INVOICES,
    GET_INVOICES_FAIL,
    GET_INVOICES_SUCCESS,
    GET_SELECTED_EXPENSE,
    GET_SELECTED_EXPENSE_FAIL,
    GET_SELECTED_EXPENSE_SUCCESS,
    POST_DISTRIBUTION_PROPS,
    POST_DISTRIBUTION_PROPS_FAIL,
    POST_DISTRIBUTION_PROPS_SUCCESS,
    REDISTRIBUTE_EXPENSE,
    REDISTRIBUTE_EXPENSE_FAIL,
    REDISTRIBUTE_EXPENSE_SUCCESS,
    REMOVE_EXPENSE_FILE,
    SELECT_ELEMENT_ACTION_EXPENSE,
    SORT_EXPENSES,
    SORT_EXPENSES_FAIL,
    SORT_EXPENSES_SUCCESS,
    UPDATE_INVOICE,
    UPDATE_INVOICE_FAIL,
    UPDATE_INVOICE_SUCCESS
} from "./ExpensesActions";
import _ from "lodash";

export type FileObjType = {
    name: string,
    originalName: string,
    path: string
}
export type SortOrder = {
    amount: string,
    dueDate: string,
    addedDate: string
}
type State = {
    expenses: any;
    selectedExpense: any;
    loading: boolean;
    message: string;
    error: object;
    expenseFiles: File[];
    documentTypes: [];
    expenseTypes: [];
    distributionTypes: [];
    distributionDifferenceTypes:[];
    sortOrder: SortOrder;
    sortedField: string;
    errorDistribution: {};
    selectedExpenseCheck: any[]
}

const ExpensesStateContext = createContext<State | undefined>(undefined);
const ExpensesDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    loading: false,
    expenses: [],
    selectedExpense: {},
    message: "",
    error: {},
    expenseFiles: [],
    documentTypes: [],
    distributionTypes: [],
    distributionDifferenceTypes:[],
    expenseTypes: [],
    sortOrder: {
        amount: "Asc",
        dueDate: "Asc",
        addedDate: "Asc"
    },
    sortedField: "",
    errorDistribution: {},
    selectedExpenseCheck: [],

};
const expensesReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_DISTRIBUTION_DIFFERENCES_TYPES: {

            return {
                ...state,
                loading: true,
                distributionDifferenceTypes:[],
                error:""
            }
        }
        case GET_DISTRIBUTION_TYPES_DIFFERENCES_SUCCESS:{
            return {
                ...state,
                loading: false,
                distributionDifferenceTypes: action.payload,
                error: ""
            }
        }
        case GET_DISTRIBUTION_TYPES_DIFFERENCES_FAIL:{
            return {
                ...state,
                loading: false,
                distributionDifferenceTypes: [],
                error: action.payload,
            }
        }
        case SELECT_ELEMENT_ACTION_EXPENSE: {
            const found = _.findIndex(state.selectedExpenseCheck, expense => expense.id === action.payload);
            let selectedExpenseCheck = state.selectedExpenseCheck;
            if (found > -1) {
                selectedExpenseCheck.splice(found, 1)
            } else {
                selectedExpenseCheck = [...selectedExpenseCheck, {id: action.payload}]
            }

            return {
                ...state,
                loading: false,
                selectedExpenseCheck
            }
        }
        case CLEAR_SELECTED_ELEMENTS_ARRAY_EXPENSE: {
            return {
                ...state,
                selectedExpenseCheck: []
            }
        }
        case GET_INVOICES: {
            return {
                ...state,
                expenses: [],
                loading: true
            }
        }

        case GET_INVOICES_SUCCESS: {
            return {
                ...state,
                expenses: action.payload,
                loading: false,
                errorDistribution: {},
                selectedExpenseCheck: []
            }
        }
        case GET_INVOICES_FAIL: {
            return {
                ...state,
                error: action.payload,
                loading: false,
                errorDistribution: {}
            }
        }
        case ADD_EXPENSE_FILE: {
            return {
                ...state,
                expenseFiles: [...state.expenseFiles, action.payload]
            }
        }
        case REMOVE_EXPENSE_FILE: {
            const newInvoiceFiles = state.expenseFiles;
            newInvoiceFiles.splice(action.payload, 1);
            return {
                ...state,
                expenseFiles: newInvoiceFiles
            }
        }
        case ADD_NEW_EXPENSE: {
            return {
                ...state,
                loading: true
            }
        }
        case POST_DISTRIBUTION_PROPS: {
            return {
                ...state,
                errorDistribution: {}
            }
        }
        case POST_DISTRIBUTION_PROPS_FAIL: {
            return {
                ...state,
                errorDistribution: action.payload
            }
        }
        case POST_DISTRIBUTION_PROPS_SUCCESS: {
            return {
                ...state,
                errorDistribution: {}
            }
        }
        case ADD_NEW_EXPENSE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case ADD_NEW_EXPENSE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case UPDATE_INVOICE: {
            return {
                ...state,
                loading: true
            }
        }
        case UPDATE_INVOICE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case UPDATE_INVOICE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_SELECTED_EXPENSE: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_SELECTED_EXPENSE_SUCCESS: {
            return {
                ...state,
                selectedExpense: action.payload,
                loading: false,
                expenseFiles: action.payload.files || []
            }
        }
        case GET_SELECTED_EXPENSE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_DOCUMENT_TYPES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_DOCUMENT_TYPES_SUCCESS: {
            return {
                ...state,
                loading: false,
                documentTypes: action.payload
            }
        }
        case GET_DOCUMENT_TYPES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_DISTRIBUTION_TYPES: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_DISTRIBUTION_TYPES_SUCCESS: {
            return {
                ...state,
                loading: false,
                distributionTypes: action.payload
            }
        }
        case GET_DISTRIBUTION_TYPES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_EXPENSE_TYPES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_EXPENSE_TYPES_SUCCESS: {
            return {
                ...state,
                loading: false,
                expenseTypes: action.payload
            }
        }
        case GET_EXPENSE_TYPES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case CLEAR_FILES_ARRAY: {
            return {
                ...state,
                loading: false,
                expenseFiles: []
            }
        }
        case SORT_EXPENSES: {
            return {
                ...state,
                expenses: [],
                loading: true
            }
        }
        case SORT_EXPENSES_SUCCESS: {
            return {
                ...state,
                expenses: action.payload,
                loading: false
            }
        }
        case SORT_EXPENSES_FAIL: {
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        }
        case CHANGE_SORT_ITEM: {
            // @ts-ignore
            if (state.sortOrder[action.payload] === "Asc") {
                return {
                    ...state,
                    sortOrder: {
                        ...state.sortOrder, [action.payload]: "Desc"
                    },
                    sortedField: action.payload
                }
            } else {
                return {
                    ...state,
                    sortOrder: {
                        ...state.sortOrder, [action.payload]: "Asc"
                    },
                    sortedField: action.payload
                }
            }
        }
        case REDISTRIBUTE_EXPENSE: {
            return {
                ...state,
                loading: true
            }
        }
        case REDISTRIBUTE_EXPENSE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case REDISTRIBUTE_EXPENSE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return state;
        }
    }
};
const ExpenseProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(expensesReducer, initialState);
    return (
        <ExpensesStateContext.Provider value={state}>
            <ExpensesDispatchContext.Provider value={dispatch}>
                {children}
            </ExpensesDispatchContext.Provider>
        </ExpensesStateContext.Provider>
    )
};

const useExpensesState = () => {
    const context = useContext(ExpensesStateContext);
    if (context === undefined) {
        throw new Error('useExpensesState must be used within a InvoiceProvider')
    }
    return context
};

const useExpensesDispatch = () => {
    const context = useContext(ExpensesDispatchContext);
    if (context === undefined) {
        throw new Error(' useExpensesDispatch must be used within InvoicesProvider');
    }
    return context
};

export {ExpenseProvider, useExpensesState, useExpensesDispatch}