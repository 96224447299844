import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";

export const ADD_EXPENSE_TYPE_GROUP = 'add_expense_type_group';
export const ADD_EXPENSE_TYPE_GROUP_SUCCESS = 'add_expense_type_group_success';
export const ADD_EXPENSE_TYPE_GROUP_FAIL = 'add_expense_type_group_fail';
export const addExpenseTypeGroup = ({
                                        dispatch,
                                        values,
                                        associationId,
                                        callback
                                    }: {
                                        dispatch: Dispatch,
                                        values: { name: string, groups: { id: string }[] },
                                        associationId: string,
                                        callback?: () => void
                                    }
) => {
    dispatch({
        type: ADD_EXPENSE_TYPE_GROUP,
        payload: {}
    });
    authFetch(`/associations/expenseTypes-groups/${associationId}`, {
        method: "POST",
        body: JSON.stringify(values)
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: ADD_EXPENSE_TYPE_GROUP_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: ADD_EXPENSE_TYPE_GROUP_SUCCESS,
                    payload: data
                });
                if (callback) {
                    callback()
                }
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const GET_EXPENSE_TYPES_GROUPS = 'get_expense_types_groups';
export const GET_EXPENSE_TYPES_GROUPS_SUCCESS = 'get_expense_types_groups_success';
export const GET_EXPENSE_TYPES_GROUPS_FAIL = 'get_expense_types_groups_fail';
export const getExpenseTypesGroups = ({
                                          dispatch,
                                          associationId
                                      }: {
                                          dispatch: Dispatch,
                                          associationId: string
                                      }
) => {
    dispatch({
        type: GET_EXPENSE_TYPES_GROUPS,
        payload: {}
    });
    authFetch(`/associations/expenseTypes-groups/${associationId}`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_EXPENSE_TYPES_GROUPS_FAIL,
                        payload: error
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            dispatch({
                type: GET_EXPENSE_TYPES_GROUPS_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            console.log("Error", error)
        })
};

export const GET_EXPENSE_TYPE_GROUP = 'get_expense_type_group';
export const GET_EXPENSE_TYPE_GROUP_SUCCESS = 'get_expense_type_group_success';
export const GET_EXPENSE_TYPE_GROUP_FAIL = 'get_expense_type_group_fail';
export const getExpenseTypeGroup = ({
                                        dispatch,
                                        associationId,
                                        groupTypeId
                                    }: {
                                        dispatch: Dispatch,
                                        associationId: string,
                                        groupTypeId: string
                                    }
) => {
    dispatch({
        type: GET_EXPENSE_TYPE_GROUP,
        payload: {}
    });
    authFetch(`/associations/expenseTypes-groups/${associationId}/${groupTypeId}`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_EXPENSE_TYPE_GROUP_FAIL,
                        payload: error
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            dispatch({
                type: GET_EXPENSE_TYPE_GROUP_SUCCESS,
                payload: data
            })
        })
};

export const UPDATE_EXPENSE_TYPE_GROUP = "update_expense_type_group";
export const UPDATE_EXPENSE_TYPE_GROUP_SUCCESS = "update_expense_type_group_success";
export const UPDATE_EXPENSE_TYPE_GROUP_FAIL = "update_expense_type_group_fail";
export const updateExpenseTypeGroup = ({
                                           dispatch,
                                           associationId,
                                           expenseGroupId,
                                           values,
                                           callback
                                       }: {
                                           dispatch: Dispatch,
                                           associationId: string,
                                           expenseGroupId: string,
                                           values: { name: string, groups: { id: string }[] },
                                           callback?: () => void
                                       }
) => {
    dispatch({
        type: UPDATE_EXPENSE_TYPE_GROUP,
        payload: {}
    });
    authFetch(`/associations/${associationId}/expenseTypes-groups/${expenseGroupId}`, {
        method: "PUT",
        body: JSON.stringify(values)
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: UPDATE_EXPENSE_TYPE_GROUP_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: UPDATE_EXPENSE_TYPE_GROUP_SUCCESS,
                    payload: data
                });
                if (callback) {
                    callback()
                }
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

