import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_STAIR,
    ADD_STAIR_FAIL,
    ADD_STAIR_SUCCESS,
    CLEAR_PROPERTIES,
    CLEAR_STATE_INFOS,
    CREATE_CSV_BLOCK,
    CREATE_CSV_BLOCK_FAIL,
    CREATE_CSV_BLOCK_SUCCESS,
    CustomPayloadSliceBreadcrumbs,
    DELETE_GROUP,
    DELETE_GROUP_FAIL,
    DELETE_GROUP_PROPERTY,
    DELETE_GROUP_PROPERTY_FAIL,
    DELETE_GROUP_PROPERTY_SUCCESS,
    DELETE_GROUP_SUCCESS,
    GET_ALL_GROUPS_FROM_ASSOCIATION,
    GET_ALL_GROUPS_FROM_ASSOCIATION_FAIL,
    GET_ALL_GROUPS_FROM_ASSOCIATION_SUCCESS,
    GET_GROUP_INFOS,
    GET_GROUP_INFOS_FAIL,
    GET_GROUP_INFOS_SUCCESS,
    GET_GROUP_PARENTS,
    GET_GROUP_PARENTS_FAIL,
    GET_GROUP_PARENTS_SUCCESS,
    GET_GROUP_PROPERTIES,
    GET_GROUP_PROPERTIES_COSTS,
    GET_GROUP_PROPERTIES_COSTS_FAIL,
    GET_GROUP_PROPERTIES_COSTS_SUCCESS,
    GET_GROUP_PROPERTIES_FAIL,
    GET_GROUP_PROPERTIES_REPORTS_BY_MONTH,
    GET_GROUP_PROPERTIES_REPORTS_BY_MONTH_FAIL,
    GET_GROUP_PROPERTIES_REPORTS_BY_MONTH_SUCCESS,
    GET_GROUP_PROPERTIES_SUCCESS,
    GET_GROUP_TYPES,
    GET_GROUPS,
    GET_GROUPS_FAIL,
    GET_GROUPS_SUCCESS,
    GET_NOT_EXCLUDED_GROUPS,
    GET_NOT_EXCLUDED_GROUPS_FAIL,
    GET_NOT_EXCLUDED_GROUPS_SUCCESS,
    GET_PREDEFINE_GROUPS,
    POP_GROUPS_BREADCRUMB,
    POST_GROUPS,
    POST_GROUPS_FAIL,
    POST_GROUPS_SUCCESS,
    SET_ACTIVE_STAIR,
    SET_PARENT_ID,
    SLICE_GROUPS_BREADCRUMBS,
    UPDATE_GROUP,
    UPDATE_GROUP_FAIL,
    UPDATE_GROUP_SUCCESS
} from "./GroupActions";
import _ from 'lodash'
import {StairElementType} from "../globals/selectElement/selectElement";

type SelectedParentType = {
    id: string;
    name: string;
};

type groupType = {
    id: string,
    name: string,
}
type GroupDetails = {
    name: string,
    address: string,
    email: string,
    representative: string,
    seriesCI: string,
    telephone: string,
    isEditable: boolean,
    parentGroupId: string,
    id: string
}

type GroupChild = {
    name: string,
    id: string
}


type State = {
    groups: any[],
    properties: [],
    loading: boolean,
    loadingCSV: boolean,
    message: string
    error: object,
    fetchedGroup: {
        details: GroupDetails,
        children: StairElementType[],
        type: string,
        groupType: string,
        expenseGroups: string[],
    },
    groupTypes: groupType[],
    stairs: StairElementType[],
    predefineGroups: groupType[],
    selectedParent: SelectedParentType,
    groupsBreadCrumbs: SelectedParentType[],
    notExcludedGroups: [],
    activeStair: { id: string }
    associationsGroups: [];
    propertiesCosts: any[];
    total:any[];
    parkingTypes:any[];
    groupParents: any[]
}

const GroupStateContext = createContext<State | undefined>(undefined);
const GroupDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    groups: [],
    properties: [],
    loading: false,
    loadingCSV: false,
    message: "",
    error: {},
    groupTypes: [],
    predefineGroups: [],
    parkingTypes:[],
    total:[],
    fetchedGroup: {
        expenseGroups: [],
        details: {
            email: "",
            name: "",
            address: "",
            representative: "",
            seriesCI: "",
            telephone: "",
            parentGroupId: "",
            isEditable: false,
            id: ""
        },
        children: [],
        type: "",
        groupType: ""
    },
    stairs: [],
    selectedParent: {
        id: "",
        name: ""
    },
    groupsBreadCrumbs: [],
    notExcludedGroups: [],
    activeStair: {id: ""},
    associationsGroups: [],
    propertiesCosts: [],
    groupParents: []
};

const groupReducer = (state: State, action: Action) => {
    switch (action.type) {
        case CREATE_CSV_BLOCK: {
            return {
                ...state,
                loadingCSV: true
            }
        }
        case CREATE_CSV_BLOCK_SUCCESS: {
            return {
                ...state,
                loadingCSV: false
            }
        }
        case CREATE_CSV_BLOCK_FAIL: {
            return {
                ...state,
                error: action.payload,
                loadingCSV: false
            }
        }
        case GET_GROUP_TYPES: {
            return {
                ...state,
                groupTypes: action.payload
            }
        }
        case GET_PREDEFINE_GROUPS: {
            return {
                ...state,
                predefineGroups: action.payload
            }
        }
        case GET_GROUPS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_GROUPS_SUCCESS: {
            let newBreadCrumbsArray: SelectedParentType[] = [];
            if (state.selectedParent.id) {
                newBreadCrumbsArray = [...state.groupsBreadCrumbs, state.selectedParent];
                newBreadCrumbsArray = _.uniqBy(newBreadCrumbsArray, 'id');
            }
            return {
                ...state,
                loading: false,
                groups: action.payload,
                groupsBreadCrumbs: newBreadCrumbsArray
            }
        }
        case GET_GROUPS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                groups: []
            }
        }
        case POST_GROUPS: {
            return {
                ...state,
                loading: true,
            }
        }
        case POST_GROUPS_SUCCESS: {
            return {
                ...state,
                loading: false,
                message: action.payload,
                groups: []
            }
        }
        case POST_GROUPS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                groups: []
            }
        }
        case GET_GROUP_INFOS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_GROUP_INFOS_SUCCESS: {
            if (action.payload.groupType === "block") {
                let stairs = action.payload.children
                stairs !== null && stairs.map((element: { name: string; id: string }) => element.name = `Scara ${element.name}`)
                return {
                    ...state,
                    loading: false,
                    fetchedGroup: action.payload,
                    stairs: stairs
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    fetchedGroup: action.payload,
                    stairs: []
                }
            }
        }
        case GET_GROUP_INFOS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case SET_PARENT_ID: {
            return {
                ...state,
                selectedParent: action.payload
            }
        }
        case POP_GROUPS_BREADCRUMB: {
            const newBreadCrumbsArray = state.groupsBreadCrumbs;
            newBreadCrumbsArray.pop();
            let newSelectedParent = {};
            if (newBreadCrumbsArray.length - 1 >= 0) {
                newSelectedParent = newBreadCrumbsArray[newBreadCrumbsArray.length - 1]
            } else {
                newSelectedParent = {
                    id: "",
                    name: ""
                }
            }

            return {
                ...state,
                groupsBreadCrumbs: newBreadCrumbsArray,
                selectedParent: newSelectedParent
            }
        }
        case SLICE_GROUPS_BREADCRUMBS: {
            let newBreadcrumbsArray = state.groupsBreadCrumbs;
            const payload: CustomPayloadSliceBreadcrumbs = action.payload as CustomPayloadSliceBreadcrumbs;
            _.remove(newBreadcrumbsArray, (element, index) => index > payload.index);
            return {
                ...state,
                groupsBreadCrumbs: newBreadcrumbsArray,
                selectedParent: newBreadcrumbsArray[newBreadcrumbsArray.length - 1]
            }
        }
        case DELETE_GROUP: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETE_GROUP_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case DELETE_GROUP_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_NOT_EXCLUDED_GROUPS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_NOT_EXCLUDED_GROUPS_SUCCESS: {
            return {
                ...state,
                loading: false,
                notExcludedGroups: action.payload
            }
        }
        case GET_NOT_EXCLUDED_GROUPS_FAIL: {
            return {
                ...state,
                loading: false,
                error: {},
                notExcludedGroups: {}
            }
        }
        case UPDATE_GROUP: {
            return {
                ...state,
                loading: true
            }
        }
        case UPDATE_GROUP_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case UPDATE_GROUP_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case CLEAR_STATE_INFOS: {
            return {
                ...state,
                loading: false,
                groupsBreadCrumbs: [],
                selectedParent: {
                    id: "",
                    name: ""
                }
            }
        }
        case GET_GROUP_PROPERTIES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_GROUP_PROPERTIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                properties: action.payload,
            }
        }
        case GET_GROUP_PROPERTIES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                properties: []
            }
        }
        case GET_GROUP_PROPERTIES_COSTS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_GROUP_PROPERTIES_COSTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                propertiesCosts: action.payload,
            }
        }
        case GET_GROUP_PROPERTIES_COSTS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                propertiesCosts: []
            }
        }
        case DELETE_GROUP_PROPERTY: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETE_GROUP_PROPERTY_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case DELETE_GROUP_PROPERTY_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        }
        case ADD_STAIR: {
            return {
                ...state,
                loading: true
            }
        }
        case ADD_STAIR_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case ADD_STAIR_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case SET_ACTIVE_STAIR: {
            return {
                ...state,
                activeStair: action.payload
            }
        }
        case GET_ALL_GROUPS_FROM_ASSOCIATION: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_ALL_GROUPS_FROM_ASSOCIATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                associationsGroups: action.payload
            }
        }
        case GET_ALL_GROUPS_FROM_ASSOCIATION_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case CLEAR_PROPERTIES: {
            return {
                ...state,
                properties: [],
            }
        }
        case GET_GROUP_PARENTS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_GROUP_PARENTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                groupParents: action.payload
            }
        }
        case GET_GROUP_PARENTS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_GROUP_PROPERTIES_REPORTS_BY_MONTH: {
            return {
                ...state,
                loading: true,
                propertiesCosts: [],
                total:[],
                parkingTypes:[]
            }
        }
        case GET_GROUP_PROPERTIES_REPORTS_BY_MONTH_SUCCESS: {
            return {
                ...state,
                loading: false,
                propertiesCosts: action.payload.rows,
                total:action.payload.total,
                parkingTypes:action.payload.parkingTypes
            }
        }
        case GET_GROUP_PROPERTIES_REPORTS_BY_MONTH_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                total:[],
                parkingTypes:[]
            }
        }
        default: {
            return state;
        }
    }
};

const GroupProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(groupReducer, initialState);
    return (
        <GroupStateContext.Provider value={state}>
            <GroupDispatchContext.Provider value={dispatch}>
                {children}
            </GroupDispatchContext.Provider>
        </GroupStateContext.Provider>
    )
};

const useGroupState = () => {
    const context = useContext(GroupStateContext);
    if (context === undefined) {
        throw new Error('useGroupState must be used within a GroupProvider')
    }
    return context
};

const useGroupDispatch = () => {
    const context = useContext(GroupDispatchContext);
    if (context === undefined) {
        throw new Error(' useGroupDispatch must be used within a GroupProvider');
    }
    return context
}

export {GroupProvider, useGroupState, useGroupDispatch}
