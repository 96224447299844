import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {
    ADD_CSV_FILE,
    ADD_NEW_CSV_PROPERTIES,
    ADD_NEW_CSV_PROPERTIES_FAIL,
    ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS,
    ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS_FAIL,
    ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS_SUCCESS,
    ADD_NEW_CSV_PROPERTIES_PARKINGS,
    ADD_NEW_CSV_PROPERTIES_PARKINGS_FAIL,
    ADD_NEW_CSV_PROPERTIES_PARKINGS_SUCCESS,
    ADD_NEW_CSV_PROPERTIES_SUCCESS,
    ADD_NEW_CSV_PROPERTIES_TRANSACTIONS,
    ADD_NEW_CSV_PROPERTIES_TRANSACTIONS_FAIL,
    ADD_NEW_CSV_PROPERTIES_TRANSACTIONS_SUCCESS,
    ADD_PROPERTY,
    ADD_PROPERTY_FAIL,
    ADD_PROPERTY_SUCCESS,
    CHANGE_SELECTED_YEAR,
    CLEAR_CSV,
    CREATE_TEMPLATE_ADD_PARKINGS,
    CREATE_TEMPLATE_ADD_PARKINGS_FAIL,
    CREATE_TEMPLATE_ADD_PARKINGS_SUCCESS,
    CREATE_TEMPLATE_ADD_TRANSACTIONS,
    CREATE_TEMPLATE_ADD_TRANSACTIONS_FAIL,
    CREATE_TEMPLATE_ADD_TRANSACTIONS_SUCCESS,
    DELETE_PROPERTY,
    DELETE_PROPERTY_FAIL,
    DELETE_PROPERTY_SUCCESS,
    EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT,
    EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT_FAIL,
    EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT_SUCCESS,
    GET_COSTS_DETAILS_BY_MONTH,
    GET_COSTS_DETAILS_BY_MONTH_FAIL,
    GET_COSTS_DETAILS_BY_MONTH_SUCCESS,
    GET_INITIAL_AMOUNTS,
    GET_INITIAL_AMOUNTS_FAIL,
    GET_INITIAL_AMOUNTS_SUCCESS,
    GET_PARKING_TYPES,
    GET_PARKING_TYPES_FAIL,
    GET_PARKING_TYPES_SUCCESS,
    GET_PROPERTIES,
    GET_PROPERTIES_FAIL,
    GET_PROPERTIES_SUCCESS,
    GET_PROPERTY,
    GET_PROPERTY_BY_CUP,
    GET_PROPERTY_BY_CUP_FAIL,
    GET_PROPERTY_BY_CUP_SUCCESS,
    GET_PROPERTY_COSTS_STATUS,
    GET_PROPERTY_COSTS_STATUS_FAIL,
    GET_PROPERTY_COSTS_STATUS_SUCCESS,
    GET_PROPERTY_FAIL,
    GET_PROPERTY_RECORDED_YEARS,
    GET_PROPERTY_RECORDED_YEARS_SUCCESS,
    GET_PROPERTY_SUCCESS,
    GET_SELECTED_PROPERTY,
    REMOVE_CSV_FILE,
    SET_PROPERTY,
    SET_SELECTED_MONTH,
    SORT_PROPERTY_BY_NAME_ASCENDED,
    SORT_PROPERTY_BY_NAME_DESCENDED,
    UPDATE_PROPERTY,
    UPDATE_PROPERTY_FAIL,
    UPDATE_PROPERTY_SUCCESS
} from "./PropertiesActions";
import {
    GET_FUNDS_BY_ASSOCIATIONS_ID,
    GET_FUNDS_BY_ASSOCIATIONS_ID_FAIL,
    GET_FUNDS_BY_ASSOCIATIONS_ID_SUCCESS
} from "../funds/FundActions";
import moment from "moment";


type State = {
    properties: any[];
    selectedProperty: any;
    property: any;
    sortByName: string;
    counterTypes: any;
    countersIndexesTypes: any;
    loading: boolean;
    message: string;
    error: any;
    countersIndexes: any;
    costsStatus: [];
    years: { year: string; currentYear: boolean }[];
    selectedYear: string;
    selectedMonth: string;
    monthCostsDetails: {
        debt: number,
        monthOfDistribution: number,
        total: number,
        totalToPay: number,
        amounts: { amount: number, isEditable: boolean, columnId: string }[],
    } | null;
    monthColumnsDetails: [];
    tableColumns: [];
    updatePropertyAmountActionFinished: boolean;
    csvFiles: File[];
    propertyFunds: { id: string; checked: boolean; name: string }[];
    initialAmounts: any[];
    foundPropertyByCup: {};
    errorFoundByCup: {
        message: ""
    };
    parkingTypes: any[]
}

const PropertiesStateContext = createContext<State | undefined>(undefined);
const PropertiesDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    sortByName: "",
    properties: [],
    property: {},
    selectedProperty: {},
    loading: false,
    counterTypes: [],
    countersIndexesTypes: [],
    countersIndexes: [],
    message: "",
    error: {
        message: ""
    },
    costsStatus: [],
    years: [],
    selectedYear: '',
    selectedMonth: '',
    monthCostsDetails: null,
    monthColumnsDetails: [],
    tableColumns: [],
    updatePropertyAmountActionFinished: false,
    csvFiles: [],
    propertyFunds: [],
    initialAmounts: [],
    foundPropertyByCup: {},
    errorFoundByCup: {
        message: ""
    },
    parkingTypes: []
};

const propertiesReducer = (state: State, action: Action) => {
    switch (action.type) {
        case CREATE_TEMPLATE_ADD_TRANSACTIONS_FAIL:{
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case CREATE_TEMPLATE_ADD_TRANSACTIONS_SUCCESS:{
            return {
                ...state,
                loading: false,
                error: ""
            }
        }
        case CREATE_TEMPLATE_ADD_TRANSACTIONS:{
            return {
                ...state,
                loading: true,
                error: ""
            }
        }
        case ADD_NEW_CSV_PROPERTIES_TRANSACTIONS:{
            return {
                ...state,
                loading: true,
                error: ""
            }
        }
        case ADD_NEW_CSV_PROPERTIES_TRANSACTIONS_SUCCESS:{
            return {
                ...state,
                loading: false,
                error: ""
            }
        }
        case ADD_NEW_CSV_PROPERTIES_TRANSACTIONS_FAIL:{
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case ADD_NEW_CSV_PROPERTIES_PARKINGS_FAIL:{
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case ADD_NEW_CSV_PROPERTIES_PARKINGS_SUCCESS:{
            return {
                ...state,
                loading: false,
                error: ""
            }
        }
        case ADD_NEW_CSV_PROPERTIES_PARKINGS:{
            return {
                ...state,
                loading: true,
                error: ""
            }
        }
        case CREATE_TEMPLATE_ADD_PARKINGS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        } case CREATE_TEMPLATE_ADD_PARKINGS_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: ""
            }
        }
        case CREATE_TEMPLATE_ADD_PARKINGS: {
            return {
                ...state,
                loading: true,
                error: ""
            }
        }
        case GET_PARKING_TYPES: {
            return {
                ...state,
                loading: true,
                error: ""
            }
        }
        case GET_PARKING_TYPES_SUCCESS: {
            return {
                ...state,
                loading: false,
                parkingTypes: action.payload
            }
        }
        case GET_PARKING_TYPES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_INITIAL_AMOUNTS: {
            return {
                ...state,
                initialAmounts: []
            }
        }
        case GET_INITIAL_AMOUNTS_SUCCESS: {
            const initialAmounts = action.payload;
            initialAmounts && initialAmounts.map((amount: any, index: number) => {
                if (!amount.displayedAt) {
                    initialAmounts[index].displayedAt = moment().format("YYYY-MM-DD")
                }
            })

            return {
                ...state,
                initialAmounts
            }
        }
        case GET_INITIAL_AMOUNTS_FAIL: {
            return {
                ...state,
                error: action.payload
            }
        }
        case ADD_NEW_CSV_PROPERTIES: {
            return {
                ...state,
                error: "",
                loading: true,
            }
        }
        case ADD_NEW_CSV_PROPERTIES_SUCCESS: {
            return {
                ...state,
                error: "",
                loading: false,
            }
        }
        case ADD_NEW_CSV_PROPERTIES_FAIL: {
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        }

        case CLEAR_CSV: {
            return {
                ...state,
                csvFiles: [],
                error: ""
            }
        }
        case ADD_CSV_FILE: {
            return {
                ...state,
                csvFiles: [...state.csvFiles, action.payload]
            }
        }
        case REMOVE_CSV_FILE: {
            const newInvoiceFiles = state.csvFiles;
            newInvoiceFiles.splice(action.payload, 1);
            return {
                ...state,
                csvFiles: newInvoiceFiles
            }
        }
        case GET_PROPERTIES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PROPERTIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                properties: action.payload
            }
        }
        case GET_PROPERTIES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                properties: []
            }
        }


        case ADD_PROPERTY: {
            return {
                ...state,
                loading: true,
            };
        }
        case ADD_PROPERTY_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: ""
            };
        }
        case ADD_PROPERTY_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        }


        case DELETE_PROPERTY: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETE_PROPERTY_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case DELETE_PROPERTY_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,

            }
        }


        case SORT_PROPERTY_BY_NAME_ASCENDED: {
            return {
                ...state,
                properties: action.payload,
                sortByName: "ascended"
            }
        }
        case SORT_PROPERTY_BY_NAME_DESCENDED: {
            return {
                ...state,
                properties: action.payload,
                sortByName: "descended"
            }
        }
        case SET_PROPERTY: {
            return {
                ...state,
                selectedProperty: action.payload
            }
        }


        case UPDATE_PROPERTY: {
            return {
                ...state,
                loading: true,
            };
        }
        case UPDATE_PROPERTY_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case UPDATE_PROPERTY_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case GET_SELECTED_PROPERTY: {
            return {
                ...state,
            }
        }
        case GET_PROPERTY: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PROPERTY_SUCCESS: {
            return {
                ...state,
                loading: false,
                property: action.payload
            }
        }
        case GET_PROPERTY_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                property: {}
            }
        }
        case GET_PROPERTY_COSTS_STATUS: {
            return {
                ...state,
                loading: true,
                updatePropertyAmountActionFinished: false
            }
        }
        case GET_PROPERTY_COSTS_STATUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                costsStatus: action.payload.months,
                tableColumns: action.payload.columns,
            }
        }
        case GET_PROPERTY_COSTS_STATUS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        }
        case CHANGE_SELECTED_YEAR: {
            return {
                ...state,
                selectedYear: action.payload
            }
        }
        case GET_PROPERTY_RECORDED_YEARS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PROPERTY_RECORDED_YEARS_SUCCESS: {
            let selectedYear = "";
            action.payload && action.payload.forEach((year: { year: string, currentYear: boolean }) => {
                if (year.currentYear) {
                    selectedYear = year.year
                }
            });
            return {
                ...state,
                loading: false,
                years: action.payload,
                selectedYear
            }
        }
        case SET_SELECTED_MONTH: {
            return {
                ...state,
                selectedMonth: action.payload
            }
        }
        case GET_COSTS_DETAILS_BY_MONTH: {
            return {
                ...state,
                loading: true,
                updatePropertyAmountActionFinished: false
            }
        }
        case GET_COSTS_DETAILS_BY_MONTH_SUCCESS: {
            return {
                ...state,
                loading: false,
                monthCostsDetails: action.payload.months && action.payload.months[0],
                monthColumnsDetails: action.payload.columns,
            }
        }
        case GET_COSTS_DETAILS_BY_MONTH_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        }
        case EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT: {
            return {
                ...state,
                loading: true,
                updatePropertyAmountActionFinished: false
            }
        }
        case EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT_SUCCESS: {
            return {
                ...state,
                loading: false,
                updatePropertyAmountActionFinished: true
            }
        }
        case EDIT_PROPERTY_EXPENSE_TYPE_AMOUNT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                updatePropertyAmountActionFinished: true
            }
        }
        case GET_FUNDS_BY_ASSOCIATIONS_ID: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_FUNDS_BY_ASSOCIATIONS_ID_SUCCESS: {
            const funds: { id: string; checked: boolean; name: string }[] = [];
            action.payload && action.payload.forEach((fund: { id: string; name: string }) => funds.push({
                id: fund.id,
                checked: false,
                name: fund.name
            }));
            return {
                ...state,
                loading: false,
                propertyFunds: funds
            }
        }
        case GET_FUNDS_BY_ASSOCIATIONS_ID_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_PROPERTY_BY_CUP: {
            return {
                ...state,
                loading: true,
                // errorFoundByCup: {
                //     message:""
                // }
            }
        }
        case ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS: {
            return {
                ...state,
                loading: true,
                error: ""
            }
        }
        case ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case ADD_NEW_CSV_PROPERTIES_INITIAL_AMOUNTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: ""
            }
        }
        case GET_PROPERTY_BY_CUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                // property: action.payload,
                errorFoundByCup: {
                    message: ""
                }
            }
        }
        case GET_PROPERTY_BY_CUP_FAIL: {
            return {
                ...state,
                loading: false,
                errorFoundByCup: action.payload
            }
        }
        default: {
            return state;
        }
    }
};
const PropertiesProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(propertiesReducer, initialState);
    return (
        <PropertiesStateContext.Provider value={state}>
            <PropertiesDispatchContext.Provider value={dispatch}>
                {children}
            </PropertiesDispatchContext.Provider>
        </PropertiesStateContext.Provider>
    )
};

const usePropertiesState = () => {
    const context = useContext(PropertiesStateContext);
    if (context === undefined) {
        throw new Error('usePropertiesState must be used within a PropertiesProvider')
    }
    return context
};

const usePropertiesDispatch = () => {
    const context = useContext(PropertiesDispatchContext);
    if (context === undefined) {
        throw new Error(' usePropertiesDispatch must be used within a PropertiesProvider');
    }
    return context
}

export {PropertiesProvider, usePropertiesState, usePropertiesDispatch}