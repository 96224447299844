import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";
import {History} from "history";
import React from "react";
import {AssociationFormValuesTypes} from "./form/AssociationForm";

export const GET_ASSOCIATIONS = "get_associations";
export const GET_ASSOCIATIONS_SUCCESS = "get_associations_success";
export const GET_ASSOCIATIONS_FAIL = "get_associations_fail";

const getAssociations = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({type: GET_ASSOCIATIONS, payload: {}});
    dispatch({type: DESELECT_ASSOCIATION, payload: {}});
    authFetch(`/associations/managed/${localStorage.getItem("userId")}`, {
        method: "GET",
    }).then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_ASSOCIATIONS_SUCCESS,
                payload: data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ASSOCIATIONS_FAIL,
                payload: error,
            });
        });
};

export const SET_SELECTED_ASSOCIATION = "set_selected_association";
const setSelectedAssociation = ({
                                    dispatch,
                                    associationDetails,
                                    history,
                                }: {
    dispatch: Dispatch;
    associationDetails: {
        id: string;
        name: string;
    };
    history: History;
}) => {
    dispatch({
        type: SET_SELECTED_ASSOCIATION,
        payload: associationDetails,
    });
    localStorage.setItem(
        "selectedAssociation",
        JSON.stringify(associationDetails)
    );
    history.push("/");
};

export const GET_SELECTED_ASSOCIATION = "get_selected_association";
const getSelectedAssociation = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({
        type: GET_SELECTED_ASSOCIATION,
        payload: {},
    });
};

export const CHECK_IF_IS_ASSOCIATION_SELECTED =
    "check_if_is_association_selected";
export const IS_NOT_ASSOCIATION_SELECTED = "is_not_association_selected";

export const checkIfIsAssociationSelected = ({
                                                 dispatch,
                                                 history,
                                                 pushToHome,
                                             }: {
    dispatch: Dispatch;
    history: History;
    pushToHome: boolean;
}) => {
    dispatch({type: CHECK_IF_IS_ASSOCIATION_SELECTED, payload: {}});
    const selectedAssociation = localStorage.getItem("selectedAssociation");
    const selectedAssociationObject = JSON.parse(selectedAssociation || "null");
    if (selectedAssociationObject) {
        dispatch({
            type: SET_SELECTED_ASSOCIATION,
            payload: selectedAssociationObject,
        });
    } else {
        dispatch({
            type: IS_NOT_ASSOCIATION_SELECTED,
            payload: {},
        });
        if (pushToHome) {
            history.push("/");
        }
    }
};

export const DESELECT_ASSOCIATION = "deselect_association";
export const deselectAssociation = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({type: DESELECT_ASSOCIATION, payload: {}});
    localStorage.removeItem("selectedAssociation");
};

export const ADD_ASSOCIATION = "add_association";
export const ADD_ASSOCIATION_SUCCESS = "add_association_success";
export const ADD_ASSOCIATION_FAIL = "add_association_fail";
export const addAssociation = ({
                                   values,
                                   dispatch,
                                   history,
                               }: {
    values: any;
    dispatch: Dispatch;
    history: History;
}) => {
    dispatch({
        type: ADD_ASSOCIATION,
        payload: {},
    });

    let responseStats: number;
    authFetch("/associations/", {
        method: "POST",
        body: JSON.stringify(values),
    })
        .then((response) => {
            responseStats = response.status
            return response.json()
        }).then((data) => {
        if (responseStats == 200) {
            dispatch({
                type: ADD_ASSOCIATION_SUCCESS,
                payload: data,
            });
            history.push("/");
        }
    })
        .catch((error) => {
            dispatch({
                type: ADD_ASSOCIATION_FAIL,
                payload: error,
            });
        });
};

export const GET_ASSOCIATION = "get_association";
export const GET_ASSOCIATION_SUCCESS = "get_association_success";
export const GET_ASSOCIATION_FAIL = "get_association_fail";

export const getAssociation = ({
                                   associationId,
                                   dispatch,
                               }: {
    associationId: string;
    dispatch: Dispatch;
}) => {
    dispatch({
        type: GET_ASSOCIATION,
        payload: {},
    });
    authFetch(`/associations/id/${associationId}`, {
        method: "GET",
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_ASSOCIATION_SUCCESS,
                payload: data[0],
            });
        })
        .catch((error) => {
            // console.error("Error:", error);
            dispatch({
                type: GET_ASSOCIATION_FAIL,
                payload: error,
            });
        });
};

export const UPDATE_ASSOCIATION = "update_association";
export const UPDATE_ASSOCIATION_SUCCESS = "update_association_success";
export const UPDATE_ASSOCIATION_FAIL = "update_association_fail";
export const updateAssociation = ({
                                      associationId,
                                      values,
                                      dispatch,
                                      history,
                                  }: {
    associationId: string;
    values: AssociationFormValuesTypes;
    history: History;
    dispatch: Dispatch;
}) => {
    dispatch({
        type: UPDATE_ASSOCIATION,
        payload: {},
    });


    authFetch(`/associations/${associationId}`, {
        method: "PUT",
        body: JSON.stringify(values),
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: UPDATE_ASSOCIATION_SUCCESS,
                payload: data,
            });
            history.push("/");
            getAssociations({dispatch});

        })
        .catch((error) => {
            dispatch({
                type: UPDATE_ASSOCIATION_FAIL,
                payload: error,
            });
        });
};

export const GET_OPENED_MONTH = "get_opened_month";
export const GET_OPENED_MONTH_SUCCESS = "get_opened_month_success";
export const GET_OPENED_MONTH_FAIL = "get_opened_month_fail";
export const getOpenedMonth = ({
                                   dispatch,
                                   associationId
                               }: {
                                   dispatch: Dispatch,
                                   associationId: string
                               }
) => {
    dispatch({
        type: GET_OPENED_MONTH,
        payload: {}
    });
    authFetch(`/costs/${associationId}/registered-months/opened-month`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_OPENED_MONTH_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_OPENED_MONTH_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export {getAssociations, setSelectedAssociation, getSelectedAssociation};
