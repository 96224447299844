import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";

export const GET_OWNERS = "get_owners";
export const GET_OWNERS_SUCCESS = "get_owners_success";
export const GET_OWNERS_FAIL = "get_owners_fail";


// @ts-ignore
export const getOwners = ({ownersDispatch}: { dispatch: Dispatch }, id: string) => {
    ownersDispatch({type: GET_OWNERS, payload: {}});
    authFetch(`/users/residents/${id}`, {
        method: "GET",
    })
        .then((response) => response.json())
        .then((data) => {
            ownersDispatch({
                type: GET_OWNERS_SUCCESS,
                payload: data,
            });
        })
        .catch((error) => {
            ownersDispatch({
                type: GET_OWNERS_FAIL,
                payload: error,
            });
        });
};
