import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";
import {SettingsFormInitialValues} from "./form/SettingsForm";

export const GET_SETTINGS_BY_ASSOCIATION_ID = 'get_settings_by_association_id';
export const GET_SETTINGS_BY_ASSOCIATION_ID_SUCCESS = 'get_settings_by_association_id_success';
export const GET_SETTINGS_BY_ASSOCIATION_ID_FAIL = 'get_settings_by_association_id_fail';
export const getSettingsByAssociationId = ({
                                               dispatch,
                                               associationId
                                           }: {
                                               dispatch: Dispatch,
                                               associationId: string
                                           }
) => {
    dispatch({
        type: GET_SETTINGS_BY_ASSOCIATION_ID,
        payload: {}
    });

    authFetch(`/associations/settings/${associationId}`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_SETTINGS_BY_ASSOCIATION_ID_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_SETTINGS_BY_ASSOCIATION_ID_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const UPDATE_ASSOCIATION_SETTINGS = "update_association_settings";
export const UPDATE_ASSOCIATION_SETTINGS_SUCCESS = "update_association_settings_success";
export const UPDATE_ASSOCIATION_SETTINGS_FAIL = "update_association_settings_fail";
export const updateAssociationSettings = ({
                                              data,
                                              associationId,
                                              dispatch,
                                              callback
                                          }: {
                                              data: SettingsFormInitialValues,
                                              associationId: string,
                                              dispatch: Dispatch,
                                              callback: () => void
                                          }
) => {
    dispatch({
        type: UPDATE_ASSOCIATION_SETTINGS,
        payload: {}
    });
    authFetch(`/associations/${associationId}/settings`, {
        method: "PUT",
        body: JSON.stringify(data)
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: UPDATE_ASSOCIATION_SETTINGS_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: UPDATE_ASSOCIATION_SETTINGS_SUCCESS,
                    payload: data
                });
                callback()
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};