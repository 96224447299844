import React, {createContext, useContext, useReducer} from 'react';
import {Action, Dispatch, ReactChildrenType} from "../../constants/globalTypes";
import {GET_USER, GET_USER_FAIL, GET_USER_SUCCESS} from "./UserActions";

interface User {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    apartment: string
    building: string,
    number: string,
    phone: string,
    stair: string,
    street: string
}

type State = {
    loading: boolean,
    user: User
    error: object
}

const UserStateContext = createContext<State | undefined>(undefined);
const UserDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    loading: false,
    user: {
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        apartment: "",
        building: "",
        number: "",
        phone: "",
        stair: "",
        street: ""
    },
    error: {}
};

const userReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_USER: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: action.payload
            }
        }
        case GET_USER_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                user: {}
            }
        }
        default: {
            return state;
        }
    }
};

const UserProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(userReducer, initialState);
    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    )
};

const useUserState = () => {
    const context = useContext(UserStateContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within a AssociationProvider')
    }
    return context
};

const useUserDispatch = () => {
    const context = useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error(' useUserDispatch must be used within a AssociationProvider');
    }
    return context
}

export {UserProvider, useUserState, useUserDispatch}
