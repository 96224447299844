import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from '../../helpers/createAuthProvider';
import {History} from "history";
import {GroupFormValuesTypes} from "./form/GroupForm";
import {StairFormInitialValues} from "./form/StairForm";
import {API_URL} from "../../constants/globalConstants";

export const GET_GROUPS = 'get_groups';
export const GET_GROUPS_SUCCESS = 'get_groups_success';
export const GET_GROUPS_FAIL = 'get_groups_fail';
export const POST_GROUPS = 'get_groups';
export const POST_GROUPS_SUCCESS = 'get_groups';
export const POST_GROUPS_FAIL = 'get_groups';
export const GET_GROUP_PROPERTIES = 'get_group';
export const GET_GROUP_PROPERTIES_SUCCESS = 'get_group_properties_success';
export const GET_GROUP_PROPERTIES_FAIL = 'get_group_properties_fail';
export const DELETE_GROUP_PROPERTY = "delete_group_property";
export const DELETE_GROUP_PROPERTY_SUCCESS = "delete_group_property_success";
export const DELETE_GROUP_PROPERTY_FAIL = "delete_property_fail";
export const GET_GROUP_PROPERTIES_COSTS = 'get_group_properties_costs';
export const GET_GROUP_PROPERTIES_COSTS_SUCCESS = 'get_group_properties_costs_success';
export const GET_GROUP_PROPERTIES_COSTS_FAIL = 'get_group_properties_costs_fail';
export const CREATE_CSV_BLOCK = "create_csv_block";
export const CREATE_CSV_BLOCK_SUCCESS = "create_csv_block_success";
export const CREATE_CSV_BLOCK_FAIL = "create_csv_block_fail";
export const createCSVBlock = ({dispatch, groupId,groupName, associationId}: { dispatch: Dispatch, groupId: string, groupName: string, associationId: string }) => {
    dispatch({type: CREATE_CSV_BLOCK, payload: {}});
    authFetch(`/properties/${associationId}/group/${groupId}/CSVProperties`, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: CREATE_CSV_BLOCK_SUCCESS,
                payload: data
            });
            let path = "";
            if (API_URL == "https://api.test.proadminserv.ro") {
                path = "https://test.proadminserv.ro"
            } else {
                path = "https://manager.staging.proadminserv.ro"
            }
            window.open(`${path}/files/${groupName}-indecsi.csv`)
        })
        .catch((error) => {
            dispatch({
                type: CREATE_CSV_BLOCK_FAIL,
                payload: error
            })
        });
};

export const CREATE_TEMPLATE_ADD_PROPERTIES = "create_template_add_properties";
export const CREATE_TEMPLATE_ADD_PROPERTIES_SUCCESS = "create_template_add_properties_success";
export const CREATE_TEMPLATE_ADD_PROPERTIES_FAIL = "create_template_add_properties_fail";

export const createTemplateAddProperties = ({dispatch, associationId}: { dispatch: Dispatch, associationId: string }) => {
    dispatch({type: CREATE_TEMPLATE_ADD_PROPERTIES, payload: {}});
    authFetch(`/properties/${associationId}/createTemplateAddProperty`, {
        method: 'POST'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: CREATE_TEMPLATE_ADD_PROPERTIES_SUCCESS,
                payload: data
            });
            let path = "";
            if (API_URL == "https://api.test.proadminserv.ro") {
                path = "https://test.proadminserv.ro"
            } else {
                path = "https://manager.staging.proadminserv.ro"
            }
            window.open(`${path}/files/templateAddProperties.csv`)
        })
        .catch((error) => {
            dispatch({
                type: CREATE_TEMPLATE_ADD_PROPERTIES_FAIL,
                payload: error
            })
        });
};

export const GET_PROPERTY_EXPENSE_TYPE_CSV = "get_property_expense_type_csv";
export const GET_PROPERTY_EXPENSE_TYPE_CSV_SUCCESS = "get_property_expense_type_csv_success";
export const GET_PROPERTY_EXPENSE_TYPE_CSV_FAIL = "get_property_expense_type_csv_fail";


export const getPropertyExpenseTypeCSV= ({dispatch, associationId}: { dispatch: Dispatch, associationId: string }) => {
    dispatch({type: GET_PROPERTY_EXPENSE_TYPE_CSV, payload: {}});
    authFetch(`/properties/${associationId}/CsvPropertiesExpenseTypes`, {
        method: 'POST'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_PROPERTY_EXPENSE_TYPE_CSV_SUCCESS,
                payload: data
            });
            let path = "";
            if (API_URL == "https://api.test.proadminserv.ro") {
                path = "https://test.proadminserv.ro"
            } else {
                path = "https://manager.staging.proadminserv.ro"
            }
            window.open(`${path}/files/tipuriCheltuieliProprietati.csv`)
        })
        .catch((error) => {
            dispatch({
                type: GET_PROPERTY_EXPENSE_TYPE_CSV_FAIL,
                payload: error
            })
        });
};

export const CREATE_TEMPLATE_ADD_INITIAL_AMOUNTS = "create_template_add_initial_amounts";
export const CREATE_TEMPLATE_ADD_INITIAL_AMOUNTS_SUCCESS = "create_template_add_initial_amounts_success";
export const CREATE_TEMPLATE_ADD_INITIAL_AMOUNTS_FAIL = "create_template_add_initial_amounts_fail";


export const createTemplateAddInitialAmounts = ({dispatch, associationId,groupId}: { dispatch: Dispatch, associationId: string,groupId:string }) => {
    dispatch({type: CREATE_TEMPLATE_ADD_INITIAL_AMOUNTS, payload: {}});
    authFetch(`/properties/${associationId}/group/${groupId}/createTemplateAddInitialAmounts`, {
        method: 'POST'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: CREATE_TEMPLATE_ADD_INITIAL_AMOUNTS_FAIL,
                payload: data
            });
            let path = "";
            if (API_URL == "https://api.test.proadminserv.ro") {
                path = "https://test.proadminserv.ro"
            } else {
                path = "https://manager.staging.proadminserv.ro"
            }
            window.open(`${path}/files/templateAddInitialAmounts-${groupId}.csv`)
        })
        .catch((error) => {
            dispatch({
                type: CREATE_TEMPLATE_ADD_INITIAL_AMOUNTS_SUCCESS,
                payload: error
            })
        });
};


const getGroups = ({
                       dispatch,
                       id,
                       parentId,
                       globalDispatch
                   }:
                       {
                           dispatch: Dispatch,
                           id: string,
                           parentId: string,
                           globalDispatch: Dispatch
                       }
) => {
    let url = `/associations/group/${id}`;
    if (parentId) {
        url = `${url}?parentId=${parentId}`
    }
    dispatch({type: GET_GROUPS, payload: {}});
    authFetch(url, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_GROUPS_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_GROUPS_FAIL,
                payload: error
            })
        });
};
export const GET_GROUP_TYPES = "get_group_type"

const getGroupTypes = ({dispatch}: { dispatch: Dispatch }) => {
    authFetch("/associations/groupTypes", {
        method: "GET"
    }).then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_GROUP_TYPES,
                payload: data
            })
        })

}
export const GET_PREDEFINE_GROUPS = "get_predefine_groups"

const getPredefineGroups = ({
                                dispatch,
                                associationId,
                                parentGroupId
                            }: {
    dispatch: Dispatch,
    associationId: string,
    parentGroupId?: string
}) => {
    let url = `/associations/predefineGroups/${associationId}`;
    if (parentGroupId) {
        url = `${url}?parentId=${parentGroupId}`
    }
    authFetch(url, {
        method: "GET"
    }).then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_PREDEFINE_GROUPS,
                payload: data
            })
        })

}

//
// const getPredefineGroups =({dispatch}:{dispatch:Dispatch}) =>{
//     authFetch("/associations/predefineGroups",{
//         method:"GET"
//     }).then((response) => response.json())
//         .then((data) => {
//             dispatch({
//                 type: GET_PREDEFINE_GROUPS,
//                 payload: data
//             })
//         })
//
// }

const getGroupProperties = (
    {dispatch, groupId, associationId}: { dispatch: Dispatch, groupId: string, associationId: string }) => {
    let url = `/properties/${associationId}/group/${groupId}`;
    dispatch({type: GET_GROUP_PROPERTIES, payload: {}});
    authFetch(url, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_GROUP_PROPERTIES_SUCCESS,
                payload: data
            })
        })
        .catch((error) => {
            dispatch({
                type: GET_GROUP_PROPERTIES_FAIL,
                payload: error
            })
        });
};

const getGroupPropertiesCosts = (
    {dispatch, groupId, associationId}: { dispatch: Dispatch, groupId: string, associationId: string }) => {
    let url = `/costs/${associationId}/group/${groupId}`;
    dispatch({type: GET_GROUP_PROPERTIES_COSTS, payload: {}});
    authFetch(url, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_GROUP_PROPERTIES_COSTS_SUCCESS,
                payload: data
            })
        })
        .catch((error) => {
            dispatch({
                type: GET_GROUP_PROPERTIES_COSTS_FAIL,
                payload: error
            })
        });
};
export const SET_ACTIVE_STAIR = "set_active_stair";
export const setActiveStair = ({
                                   dispatch,
                                   id
                               }:
                                   {
                                       dispatch: Dispatch,
                                       id: string
                                   }
) => {
    dispatch({
        type: SET_ACTIVE_STAIR,
        payload: {id}
    })
};

const deleteGroupProperty = (
    {dispatch}: { dispatch: Dispatch }, groupId: string, propertyId: string, associationId: string) => {
    dispatch({type: DELETE_GROUP_PROPERTY, payload: {}});
    authFetch(`/properties/${associationId}/group/${groupId}/${propertyId}`, {
        method: 'DELETE'
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: DELETE_GROUP_PROPERTY_SUCCESS,
                payload: data
            });
            getGroupProperties({dispatch: dispatch, groupId: groupId, associationId: associationId});
        })
        .catch((error) => {
            dispatch({
                type: DELETE_GROUP_PROPERTY_FAIL,
                payload: error
            });
            getGroupProperties({dispatch: dispatch, groupId: groupId, associationId: associationId});
        });
};

export const SET_PARENT_ID = 'set_parent_id';
export const setParentId = ({dispatch, selectedGroup}: { dispatch: Dispatch, selectedGroup: { id: string, name: string } }) => {
    dispatch({
        type: SET_PARENT_ID,
        payload: selectedGroup
    })
};

export const POP_GROUPS_BREADCRUMB = 'pop_group_breadcrumb';
export const popGroupsBreadcrumb = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({
        type: POP_GROUPS_BREADCRUMB,
        payload: {}
    })
};

export type CustomPayloadSliceBreadcrumbs = {
    index: number;
}
type CustomDispatchSliceBreadcrumbs = {
    type: string;
    payload: CustomPayloadSliceBreadcrumbs;
}
export const SLICE_GROUPS_BREADCRUMBS = 'slice_groups_breadcrumbs';
export const sliceGroupsBreadcrumbs = ({dispatch, sliceObject}: { dispatch: Dispatch, sliceObject: { index: number } }) => {
    dispatch({
        type: SLICE_GROUPS_BREADCRUMBS,
        payload: sliceObject
    } as CustomDispatchSliceBreadcrumbs)
};

export const CLEAR_STATE_INFOS = 'clear_state_infos';
export const clearStateInfos = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({
        type: CLEAR_STATE_INFOS,
        payload: {}
    });
};

interface GroupProps {
    dispatch: Dispatch,
    values: GroupFormValuesTypes
}

const postGroups = ({
                        values,
                        dispatch,
                        history,
                    }: {
    values: any;
    dispatch: Dispatch;
    history: History;
}, id: string) => {
    dispatch({
        type: POST_GROUPS,
        payload: {},
    });
    authFetch(`/associations/group/${id}`, {
        method: "POST",
        body: JSON.stringify(values),
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: POST_GROUPS_SUCCESS,
                payload: data,
            });
            history.push(`/associations/${id}`);
        })
        .catch((error) => {
            dispatch({
                type: POST_GROUPS_FAIL,
                payload: error,
            });
        });
};

export const GET_GROUP_INFOS = 'get_group_infos';
export const GET_GROUP_INFOS_SUCCESS = 'get_group_infos_success';
export const GET_GROUP_INFOS_FAIL = 'get_group_infos_fail';
const getGroupInfos = (
    {
        dispatch,
        groupId,
        associationId,
        globalDispatch
    }: {
        dispatch: Dispatch,
        groupId: string,
        associationId: string
        globalDispatch: Dispatch
    }) => {
    dispatch({
        type: GET_GROUP_INFOS,
        payload: {}
    });
    authFetch(`/associations/group/${associationId}/${groupId}`, {
        method: "GET"
    })
        .then(result => result.json())
        .then(data => {
            dispatch({
                type: GET_GROUP_INFOS_SUCCESS,
                payload: data
            });
            let name = data.details.name
            if (data.groupType === "block") {
                name = `Bloc: ${name}`;
            }
            // addGroupToBreadCrumbs({dispatch: globalDispatch, groupInfo: {name, id: data.details.id}})
        })
        .catch(error => {
            dispatch({
                type: GET_GROUP_INFOS_FAIL,
                payload: error
            })
        })
};

export const DELETE_GROUP = 'delete_group';
export const DELETE_GROUP_SUCCESS = 'delete_group_success';
export const DELETE_GROUP_FAIL = 'delete_group_fail';
export const deleteGroup = ({
                                dispatch,
                                groupId,
                                parentId,
                                associationId,
                                globalDispatch
                            }: {
                                dispatch: Dispatch,
                                groupId: string,
                                parentId: string,
                                associationId: string,
                                globalDispatch: Dispatch
                            }
) => {
    dispatch({
        type: DELETE_GROUP,
        payload: {}
    });
    authFetch(`/associations/group/${groupId}`, {
        method: 'DELETE'
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: DELETE_GROUP_SUCCESS,
                payload: data
            })
            getGroups({dispatch, parentId, id: associationId, globalDispatch})
        })
        .catch(error => {
            dispatch({
                type: DELETE_GROUP_FAIL,
                payload: error
            })
        })
};

export const GET_NOT_EXCLUDED_GROUPS = 'get_not_excluded_groups';
export const GET_NOT_EXCLUDED_GROUPS_SUCCESS = 'get_not_excluded_groups_success';
export const GET_NOT_EXCLUDED_GROUPS_FAIL = 'get_not_excluded_groups_fail';
export const getNotExcludedGroups = ({
                                         dispatch,
                                         parentId,
                                         associationId
                                     }:
                                         {
                                             dispatch: Dispatch,
                                             parentId: string,
                                             associationId: string
                                         }) => {
    dispatch({
        type: GET_NOT_EXCLUDED_GROUPS,
        payload: {}
    });
    authFetch(`/associations/group/${associationId}?excludeGroupIdAndChildren=${parentId}`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: GET_NOT_EXCLUDED_GROUPS_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: GET_NOT_EXCLUDED_GROUPS_FAIL,
                payload: error
            })
        });
};

export const UPDATE_GROUP = 'update_group';
export const UPDATE_GROUP_SUCCESS = 'update_group_success';
export const UPDATE_GROUP_FAIL = 'update_group_fail';
export const updateGroup = ({
                                dispatch,
                                groupId,
                                values,
                                associationId,
                                globalDispatch
                            }:
                                {
                                    dispatch: Dispatch;
                                    groupId: string;
                                    values: any;
                                    associationId: string;
                                    globalDispatch: Dispatch;
                                }) => {
    dispatch({
        type: UPDATE_GROUP,
        payload: {}
    });

    authFetch(`/associations/${groupId}/group`, {
        method: 'PUT',
        body: JSON.stringify(values)
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: UPDATE_GROUP_SUCCESS,
                payload: data
            });

        })
        .catch(error => {
            dispatch({
                type: UPDATE_GROUP_FAIL,
                payload: error
            })
        })
};

export const ADD_STAIR = "add_stair";
export const ADD_STAIR_SUCCESS = "add_stair_success";
export const ADD_STAIR_FAIL = "add_stair_fail";
export const addStair = ({
                             dispatch,
                             associationId,
                             parentGroupId,
                             values,
                             history,
                             stairId
                         }
                             :
                             {
                                 dispatch: Dispatch;
                                 associationId: string;
                                 parentGroupId: string;
                                 values: StairFormInitialValues,
                                 history: History,
                                 stairId: string
                             }
) => {
    dispatch({
        type: ADD_STAIR,
        payload: {}
    });

    let data = {
        name: values.name,
        staff: values.staff,
        predefinesGroup: values.predefinesGroup,

        type: stairId,
        parentGroupId: parentGroupId
    };

    authFetch(`/associations/group/${associationId}`, {
        method: "POST",
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: ADD_STAIR_SUCCESS,
                payload: data
            });
            history.push(`/associations/${associationId}/groups/${parentGroupId}`)
        })
        .catch(error => {
            dispatch({
                type: ADD_STAIR_FAIL,
                payload: error
            })
        })
};

export const GET_ALL_GROUPS_FROM_ASSOCIATION = "get_all_groups_from_association";
export const GET_ALL_GROUPS_FROM_ASSOCIATION_SUCCESS = "get_all_groups_from_association_success";
export const GET_ALL_GROUPS_FROM_ASSOCIATION_FAIL = "get_all_groups_from_association_fail";
export const getAllGroupsFromAssociation = ({
                                                dispatch,
                                                associationId
                                            }:
                                                {
                                                    dispatch: Dispatch,
                                                    associationId: string
                                                }
) => {
    dispatch({
        type: GET_ALL_GROUPS_FROM_ASSOCIATION,
        payload: {}
    });
    authFetch(`/associations/groups/${associationId}`, {
        method: "GET"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                response.text().then(error => {
                    dispatch({
                        type: GET_ALL_GROUPS_FROM_ASSOCIATION_FAIL,
                        payload: error
                    })
                })
            }
        })
        .then(data => {
            dispatch({
                type: GET_ALL_GROUPS_FROM_ASSOCIATION_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            console.log(error)
        })
};

export const CLEAR_PROPERTIES = 'clear_properties';
export const clearPropertiesArray = (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_PROPERTIES,
        payload: {}
    })
};

export const GET_GROUP_PARENTS = 'get_group_parents';
export const GET_GROUP_PARENTS_SUCCESS = 'get_group_parents_success';
export const GET_GROUP_PARENTS_FAIL = 'get_group_parents_fail';
export const getGroupParents = ({
                                    dispatch,
                                    associationId,
                                    groupId,
                                }:
                                    {
                                        dispatch: Dispatch,
                                        associationId: string,
                                        groupId: string
                                    }
) => {
    dispatch({
        type: GET_GROUP_PARENTS,
        payload: {}
    });
    authFetch(`/associations/group/${associationId}/${groupId}/parents`, {
        method: "GET"
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.text().then(error => {
                    dispatch({
                        type: GET_GROUP_PARENTS_FAIL,
                        payload: {}
                    })
                })
            }
        })
        .then(data => {
            dispatch({
                type: GET_GROUP_PARENTS_SUCCESS,
                payload: data
            });
        })
        .catch(error => {
            console.log("ERR", error)
        })
};

export const GET_MONTHLY_STAIR_REPORT = 'get_monthly_stair_report';
export const GET_MONTHLY_STAIR_REPORT_SUCCESS = 'get_monthly_stair_report_success';
export const GET_MONTHLY_STAIR_REPORT_FAIL = 'get_monthly_stair_report_fail';
export const getMonthlyStairReport = ({
                                          associationId,
                                          stairId,
                                          dispatch
                                      }: {
                                          associationId: string,
                                          stairId: string,
                                          dispatch: Dispatch
                                      }
) => {
    dispatch({
        type: GET_MONTHLY_STAIR_REPORT,
        payload: {}
    });
    authFetch(`/costs/${associationId}/monthly-reports/preview-month/${stairId}`, {
        method: "GET",
    })
        .then(response => {
            // if (!response.ok) {
            //     return response.text().then(error => {
            //         dispatch({
            //             type: GET_MONTHLY_STAIR_REPORT_FAIL,
            //             payload: JSON.parse(error)
            //         })
            //     })
            // }
            // return response.text();
        })

};

export const GET_GROUP_PROPERTIES_REPORTS_BY_MONTH = "get_group_properties_reports_by_month";
export const GET_GROUP_PROPERTIES_REPORTS_BY_MONTH_SUCCESS = "get_group_properties_reports_by_month_success";
export const GET_GROUP_PROPERTIES_REPORTS_BY_MONTH_FAIL = "get_group_properties_reports_by_month_fail";
export const getGroupPropertiesReportsByMonth = ({
                                                     associationId,
                                                     groupId,
                                                     monthCode,
                                                     dispatch
                                                 }: {
                                                     associationId: string,
                                                     groupId: string,
                                                     monthCode: string,
                                                     dispatch: Dispatch
                                                 }
) => {
    dispatch({
        type: GET_GROUP_PROPERTIES_REPORTS_BY_MONTH,
        payload: {}
    });
    authFetch(`/costs/${associationId}/monthly-reports/by-month/${groupId}/${monthCode}`, {
        method: "GET"
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_GROUP_PROPERTIES_REPORTS_BY_MONTH_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_GROUP_PROPERTIES_REPORTS_BY_MONTH_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
}
export {
    getGroups,
    postGroups,
    getGroupInfos,
    getGroupProperties,
    deleteGroupProperty,
    getGroupTypes,
    getPredefineGroups,
    getGroupPropertiesCosts
}
