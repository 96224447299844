import React, {createContext, useContext, useReducer} from "react";
import {Action, Dispatch, ReactChildrenType,} from "../../constants/globalTypes";
import {
    EDIT_PROPERTY_FUNDS, EDIT_PROPERTY_FUNDS_FAIL, EDIT_PROPERTY_FUNDS_SUCCESS,
    GET_FUND_BY_ID,
    GET_FUND_BY_ID_FAIL,
    GET_FUND_BY_ID_SUCCESS,
    GET_FUND_DISTRIBUTIONS,
    GET_FUND_DISTRIBUTIONS_FAIL,
    GET_FUND_DISTRIBUTIONS_SUCCESS,
    GET_FUNDS_BY_ASSOCIATIONS_ID_FAIL,
    GET_FUNDS_BY_ASSOCIATIONS_ID_SUCCESS,
    GET_FUNDS_BY_PROPERTY_ID, GET_FUNDS_BY_PROPERTY_ID_FAIL, GET_FUNDS_BY_PROPERTY_ID_SUCCESS,
    REQUEST_FUND_MONEY,
    REQUEST_FUND_MONEY_FAIL,
    REQUEST_FUND_MONEY_SUCCESS
} from "./FundActions";
import {act} from "react-dom/test-utils";

type State = {
    loading: boolean;
    error: any;
    funds: any;
    fund: any;
    fundDistributions: [];
    fundsProperty: any[];
};

const FundsStateContext = createContext<State | undefined>(undefined);
const FundsDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    funds: [],
    loading: false,
    fund: {},
    error: {},
    fundDistributions: [],
    fundsProperty: [],
};

const fundsReducer = (state: State, action: Action) => {
    switch (action.type) {
        case EDIT_PROPERTY_FUNDS: {
            return {...state, loading: true}
        }
        case EDIT_PROPERTY_FUNDS_SUCCESS: {
            return {...state, loading: false}
        }
        case EDIT_PROPERTY_FUNDS_FAIL: {
            return {...state, loading: false, error: action.payload}
        }
        case GET_FUNDS_BY_PROPERTY_ID: {
            return {
                ...state,
                fundsProperty: [],
                loading: true
            }
        }
        case GET_FUNDS_BY_PROPERTY_ID_SUCCESS: {
            return {
                ...state,
                fundsProperty: action.payload,
                loading: false
            }
        }
        case GET_FUNDS_BY_PROPERTY_ID_FAIL: {
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        }
        case GET_FUNDS_BY_ASSOCIATIONS_ID_SUCCESS: {
            return {
                ...state,
                funds: action.payload
            }
        }
        case GET_FUNDS_BY_ASSOCIATIONS_ID_FAIL: {
            return {
                ...state,
                error: action.payload
            }
        }
        case GET_FUND_BY_ID: {
            return {
                ...state,
                loading: true,
                error: {}
            }
        }
        case GET_FUND_BY_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                fund: action.payload
            }
        }
        case GET_FUND_BY_ID_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case REQUEST_FUND_MONEY: {
            return {
                ...state,
                loading: true,
                error: {}
            }
        }
        case REQUEST_FUND_MONEY_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case REQUEST_FUND_MONEY_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case GET_FUND_DISTRIBUTIONS: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_FUND_DISTRIBUTIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                fundDistributions: action.payload
            }
        }
        case GET_FUND_DISTRIBUTIONS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

const FundsProvider = ({children}: ReactChildrenType) => {
    const [state, dispatch] = useReducer(fundsReducer, initialState);
    return (
        <FundsStateContext.Provider value={state}>
            <FundsDispatchContext.Provider value={dispatch}>
                {children}
            </FundsDispatchContext.Provider>
        </FundsStateContext.Provider>
    );
};

const useFundsState = () => {
    const context = useContext(FundsStateContext);
    if (context === undefined) {
        throw new Error("useFundsState must be used within a LoginProvider");
    }
    return context;
};

const useFundsDispatch = () => {
    const context = useContext(FundsDispatchContext);
    if (context === undefined) {
        throw new Error("useFundsState must be used within a LoginProvider");
    }
    return context;
};

export {FundsProvider, useFundsDispatch, useFundsState};
