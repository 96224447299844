import React, {createContext, useContext, useReducer} from "react";
import {Action, Dispatch, ReactChildrenType,} from "../../constants/globalTypes";
import {
    ACCEPT_INVITE,
    ACCEPT_INVITE_FAIL,
    ACCEPT_INVITE_SUCCESS,
    GET_INVITES,
    GET_INVITES_FAIL,
    GET_INVITES_SUCCESS
} from "./InvitesActions";

type State = {
    invites: any[],
    loading: boolean,
    error: any
};

const InvitesStateContext = createContext<State | undefined>(undefined);
const InvitesDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState: State = {
    invites: [],
    loading: false,
    error: ""
};

const invitesReducer = (state: State, action: Action) => {
    switch (action.type) {
        case GET_INVITES: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_INVITES_SUCCESS: {

            return {
                ...state,
                loading: false,
                invites: action.payload
            }
        }
        case GET_INVITES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case ACCEPT_INVITE: {
            return {
                ...state,
                loading: true
            }
        }
        case ACCEPT_INVITE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case ACCEPT_INVITE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            return state
        }
    }
};

const InvitesProvider = ({children}: ReactChildrenType) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(invitesReducer, initialState);
    return (
        <InvitesStateContext.Provider value={state}>
            <InvitesDispatchContext.Provider value={dispatch}>
                {children}
            </InvitesDispatchContext.Provider>
        </InvitesStateContext.Provider>
    )
};

const useInvitesState = () => {
    const context = useContext(InvitesStateContext);
    if (context === undefined) {
        throw new Error("useInvitesState must be used within UsersProvider")
    }
    return context
};

const useInvitesDispatch = () => {
    const context = useContext(InvitesDispatchContext);
    if (context === undefined) {
        throw new Error("useInvitesDispatch must be used within InvitesProvider")
    }
    return context
};

export {InvitesProvider, useInvitesState, useInvitesDispatch}

