import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from "../../helpers/createAuthProvider";

interface PostProps {
    dispatch: Dispatch;
}

export const EDIT_PROPERTY_FUNDS = 'edit_property_funds';
export const EDIT_PROPERTY_FUNDS_SUCCESS = 'edit_property_funds_success';
export const EDIT_PROPERTY_FUNDS_FAIL = 'edit_property_funds_fail';

export const editPropertyFunds = ({
                                      values,
                                      dispatch,
                                      propertyId,
                                      associationId,
                                      callback
                                  }: {
                                      values: any,
                                      dispatch: Dispatch,
                                      propertyId: string,
                                      associationId: string,
                                      callback?: () => void
                                  }
) => {
    dispatch({
        type: EDIT_PROPERTY_FUNDS,
        payload: {}
    });

    authFetch(`/funds/${associationId}/updatePropertyFunds/${propertyId}`, {
        method: "PUT",
        body: JSON.stringify({"funds": values})
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: EDIT_PROPERTY_FUNDS_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: EDIT_PROPERTY_FUNDS_SUCCESS,
                    payload: data
                });
                if (callback) {
                    callback()
                }
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};


export const GET_FUNDS_BY_ASSOCIATIONS_ID = "get_funds_by_associations_id"
export const GET_FUNDS_BY_ASSOCIATIONS_ID_SUCCESS = "get_funds_by_associations_id_success"
export const GET_FUNDS_BY_ASSOCIATIONS_ID_FAIL = "get_funds_by_associations_id_fail"
export const getFundsByAssociationId = ({
                                            dispatch,
                                            associationId,
                                            type
                                        }: {
                                            dispatch: Dispatch,
                                            associationId: string,
                                            type?: string
                                        }
) => {
    dispatch({type: GET_FUNDS_BY_ASSOCIATIONS_ID, payload: {}});
    let url: string = `/funds/${associationId}/`;
    if (type) {
        url = `${url}?type=${type}`
    }
    authFetch(url, {
        method: "GET",
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: GET_FUNDS_BY_ASSOCIATIONS_ID_SUCCESS,
                payload: data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_FUNDS_BY_ASSOCIATIONS_ID_FAIL,
                payload: error,
            });
        });
}

export const ADD_FUND = "add_fund";
export const ADD_FUND_SUCCESS = "add_fund_success";
export const ADD_FUND_FAIL = "add_fund_fail"

export const addFund = ({
                            dispatch,
                            values,
                            associationId,
                            history
                        }: { dispatch: Dispatch, values: any, associationId: string, history: any }) => {
    dispatch({type: ADD_FUND, payload: {}})
    authFetch(`/funds/${associationId}/`, {
        method: "POST",
        body: JSON.stringify(values)
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: ADD_FUND_SUCCESS,
                payload: data,
            });
            history.goBack();
        })
        .catch((error) => {
            dispatch({
                type: ADD_FUND_FAIL,
                payload: error,
            });
        });
}

export const GET_FUND_BY_ID = "get_fund_by_id";
export const GET_FUND_BY_ID_SUCCESS = "get_fund_by_id_success";
export const GET_FUND_BY_ID_FAIL = "get_fund_by_id_fail";
export const getFundsById = ({
                                 dispatch,
                                 associationId,
                                 fundId
                             }: { dispatch: Dispatch, associationId: string, fundId: string }) => {
    dispatch({type: GET_FUND_BY_ID, payload: {}})
    authFetch(`/funds/${associationId}/fundId/${fundId}`, {
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_FUND_BY_ID_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then((data) => {
            if (data) {
                dispatch({
                    type: GET_FUND_BY_ID_SUCCESS,
                    payload: data,
                });
            }
        })
        .catch((error) => {
            console.log("error", error)
        });
};

export const GET_FUNDS_BY_PROPERTY_ID = "get_funds_by_property_id";
export const GET_FUNDS_BY_PROPERTY_ID_SUCCESS = "get_funds_by_property_id_success";
export const GET_FUNDS_BY_PROPERTY_ID_FAIL = "get_funds_by_property_id_fail";

export const getFundsByPropertyId = ({
                                         dispatch,
                                         associationId,
                                         propertyId
                                     }: { dispatch: Dispatch, associationId: string, propertyId: string }) => {
    dispatch({type: GET_FUNDS_BY_PROPERTY_ID, payload: {}})
    authFetch(`/funds/${associationId}/property/${propertyId}`, {
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_FUNDS_BY_PROPERTY_ID_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then((data) => {
            if (data) {
                dispatch({
                    type: GET_FUNDS_BY_PROPERTY_ID_SUCCESS,
                    payload: data,
                });
            }
        })
        .catch((error) => {
            console.log("error", error)
        });
};

export const UPDATE_FUND = "update_fund";
export const UPDATE_FUND_SUCCESS = "update_fund_success";
export const UPDATE_FUND_FAIL = "update_fund_fail";

export const updateFund = ({
                               dispatch,
                               associationId,
                               fundId,
                               data,
                               history
                           }: { dispatch: Dispatch, associationId: string, fundId: string, data: any, history: any }) => {
    dispatch({type: UPDATE_FUND, payload: {}})
    authFetch(`/funds/${associationId}/fundId/${fundId}`, {
        method: "PUT",
        body: JSON.stringify(data)
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch({
                type: UPDATE_FUND_SUCCESS,
                payload: data,
            });
            getFundsByAssociationId({dispatch: dispatch, associationId: associationId})
            history.goBack();
        })
        .catch((error) => {
            dispatch({
                type: UPDATE_FUND_FAIL,
                payload: error,
            });
        });
}

export const REQUEST_FUND_MONEY = 'request_fund_money';
export const REQUEST_FUND_MONEY_SUCCESS = 'request_fund_money_success';
export const REQUEST_FUND_MONEY_FAIL = 'request_fund_money_fail';
export const requestFundMoney = ({
                                     dispatch,
                                     fundId,
                                     associationId,
                                     values,
                                     callback
                                 }: {
                                     dispatch: Dispatch,
                                     fundId: string,
                                     associationId: string,
                                     values: {
                                         from: string;
                                         to: string;
                                         amount: number;
                                         distributionType?: string;
                                         requestTo?: { id: string }[];
                                     },
                                     callback?: () => void
                                 }
) => {
    dispatch({
        type: REQUEST_FUND_MONEY,
        payload: {}
    });
    authFetch(`/funds/${associationId}/${fundId}/request-money`, {
        method: "POST",
        body: JSON.stringify(values)
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: REQUEST_FUND_MONEY_FAIL,
                        payload: JSON.parse(error)
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: REQUEST_FUND_MONEY_SUCCESS,
                    payload: data
                });
                if (callback) {
                    callback();
                }
            }
        })
        .catch(error => {
            console.log("error", error)
        })
};

export const GET_FUND_DISTRIBUTIONS = 'get_fund_distributions';
export const GET_FUND_DISTRIBUTIONS_SUCCESS = 'get_fund_distributions_success';
export const GET_FUND_DISTRIBUTIONS_FAIL = 'get_fund_distributions_fail';
export const getFundDistributions = ({
                                         dispatch,
                                         associationId,
                                         fundId
                                     }: {
                                         fundId: string,
                                         associationId: string,
                                         dispatch: Dispatch
                                     }
) => {
    dispatch({
        type: GET_FUND_DISTRIBUTIONS,
        payload: {}
    });
    authFetch(`/funds/${associationId}/distributions/${fundId}`, {
        method: "GET",
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(error => {
                    dispatch({
                        type: GET_FUND_DISTRIBUTIONS_FAIL,
                        payload: error
                    })
                })
            }
            return response.json()
        })
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_FUND_DISTRIBUTIONS_SUCCESS,
                    payload: data
                })
            }
        })
        .catch(error => {
            console.log("error", error)
        })
}
