import {Dispatch} from "../../constants/globalTypes";
import {authFetch} from '../../helpers/createAuthProvider'
import {History} from 'history'

export const GET_INVITES = 'get_invites';
export const GET_INVITES_SUCCESS = 'get_invites_success';
export const GET_INVITES_FAIL = 'get_invites_fail';
export const getInvites = ({dispatch}: { dispatch: Dispatch }) => {
    dispatch({
        type: GET_INVITES,
        payload: {}
    });
    var respStats: number;
    authFetch('/users/invites', {
        method: "GET"
    })
        .then(response => {
            respStats = response.status;
            return response.json()
        })
        .then(data => {
            if (respStats == 200) {
                dispatch({
                    type: GET_INVITES_SUCCESS,
                    payload: data
                })
            } else {
                dispatch({
                    type: GET_INVITES_FAIL,
                    payload: data.message
                })
            }

        })
        .catch(error => {
            dispatch({
                type: GET_INVITES_FAIL,
                payload: error.message
            })
        })
};

export const ACCEPT_INVITE = 'accept_invite';
export const ACCEPT_INVITE_SUCCESS = 'accept_invite_success';
export const ACCEPT_INVITE_FAIL = 'accept_invite_fail';
type AcceptInviteProps = {
    dispatch: Dispatch,
    history: History,
    inviteId: string
};
export const acceptInvite = ({dispatch, history, inviteId}: AcceptInviteProps) => {
    dispatch({
        type: ACCEPT_INVITE,
        payload: {}
    });
    authFetch(`/users/invites/acceptInvite/${inviteId}`, {
        method: "PUT"
    })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: ACCEPT_INVITE_SUCCESS,
                payload: data
            });
            history.push('/');
        })
        .catch(error => {
            dispatch({
                type: ACCEPT_INVITE_FAIL,
                payload: error
            });
        });
};
